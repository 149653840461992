import { useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { EMPLOYEES } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import PdfComponent from "../Pdf/PdfComponent";
export function PrintEmployee() {
	const client = useApolloClient();
	const navigate = useNavigate();
	const [employees, setEmployees] = useState([]);
	const [pages, setPages] = useState([]);
	const [isLoader, setIsLoader] = useState(false);
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadEmployee();
	}, []);
	const loadEmployee = async () => {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: EMPLOYEES,
				variables: {
					pageNumber: -1,
					perPage: -1,
					searchItem: "",
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setIsLoader(false);
				const fetchedEmployees = res.data.getEmployees.employees;
				console.log("fetchedEmployees", fetchedEmployees);
				// setEmployees(fetchedEmployees);
				const modifiedData = fetchedEmployees.map((employee, index) => ({
					col1: index + 1,
					col2: employee?.fullName,
					col3: employee?.job,
					col4: employee?.phoneNumber,
					col5: employee?.contractDate,
					col6: employee?.workTime,
					col7: employee?.salary,
					col8:
						employee?.balance > 0
							? Math.abs(employee?.balance) + "  بستانکار  "
							: employee?.balance < 0
							? Math.abs(employee?.balance) + "  بدهکار  "
							: 0 + "  تسویه  ",
				}));
				// Split data into pages
				const rowsPerPage = 15;
				const splitPages = splitDataIntoPages(modifiedData, rowsPerPage);
				setPages(splitPages);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	};
	const titles = [
		"#",
		"نام",
		"وظیفه",
		"موبایل",
		"قرارداد",
		"زمان",
		"معاش",
		"بیلانس",
	];
	// Helper function to split data into pages
	const splitDataIntoPages = (data, rowsPerPage) => {
		if (!Array.isArray(data)) {
			console.error("Invalid input data for splitDataIntoPages:", data);
			return [];
		}

		const pages = [];
		for (let i = 0; i < data.length; i += rowsPerPage) {
			pages.push(data.slice(i, i + rowsPerPage));
		}

		console.log("Pages generated:", pages); // Debug output
		return pages;
	};
	return (
		<div className="conatiner">
			{isLoader && <Loader />}
			{/* Render PdfComponent only when pages data is available */}
			{!isLoader && pages.length > 0 && (
				<PdfComponent
					data={pages}
					titles={titles}
					pdfTitle="داروی انتخاب شده"
					orientation="landscape"
				/>
			)}
		</div>
	);
}
