import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import "../../Styles/Home/Home.css";
import "../../Styles/Settings/Settings.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, Space, Tag, Modal, Button, notification, Select } from "antd";
import { UPDATEPROFILE, ADDPROFILE } from "../../GraphQL/Mutation";
import { useApolloClient } from "@apollo/client";
import { PROFILE } from "../../GraphQL/Query";
import axios from "axios";
import { FaCloudUploadAlt } from "react-icons/fa";
import { BACKEND, DOMAIN_NAME } from "../../Constant/const";
import Content from "../StaticComponents/Content";
import { Option } from "antd/es/mentions";

export function Settings() {
	const client = useApolloClient();
	const navigate = useNavigate();
	const location = useLocation();
	if (location.pathname === "/settings") {
		location.pathname = "تنظیمات";
	}
	const [referesh, setReferesh] = useState(false);
	const [profile, setProfile] = useState();
	const [newProfile, setNewProfile] = useState(false);
	const [editProfile, setEditProfile] = useState(false);
	const [file, setFile] = useState();
	const [message, setMessage] = useState("");
	const [file2, setFile2] = useState();
	const [imageSrc, setImageSrc] = useState(null);
	const [imageSrc2, setImageSrc2] = useState(null);
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadProfile();
	}, [referesh]);
	// function for fetch profile
	const loadProfile = async () => {
		try {
			const res = await client.query({
				query: PROFILE,
				variables: {},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setProfile(res.data.getProfile);
			}
		} catch (error) {
			console.log(error);
		}
	};
	// function for add new profile
	async function addProfile(e) {
		e.preventDefault();
		try {
			const res = await client.mutate({
				mutation: ADDPROFILE,
				variables: {
					titleOne: e.target.titleOne.value,
					titleTwo: e.target.titleTwo.value,
					owners: [e.target.ownerOne.value, e.target.ownerTwo.value],
					phones: [
						e.target.phoneOne.value,
						e.target.phoneTwo.value,
						e.target.phoneThree.value,
						e.target.phoneFour.value,
						e.target.phoneFive.value,
						e.target.phoneSix.value,
					],
					address: e.target.address.value,
				},
			});
			if (res) {
				setReferesh(!referesh);
				setNewProfile(false);
				notification.success({
					message: "پروفایل با موفقیت اضافه گردید",
					placement: "topRight",
					duration: 3,
				});
				window.localStorage.setItem(
					"profile",
					JSON.stringify(res.data.addProfile)
				);
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در افزودن پروفایل",
				placement: "topRight",
				duration: 3,
			});
		}
	}
	// function for update profile
	async function updateProfile(e) {
		e.preventDefault();
		try {
			const res = await client.mutate({
				mutation: UPDATEPROFILE,
				variables: {
					titleOne: e.target.titleOne.value,
					titleTwo: e.target.titleTwo.value,
					owners: [e.target.ownerOne.value, e.target.ownerTwo.value],
					phones: [
						e.target.phoneOne.value,
						e.target.phoneTwo.value,
						e.target.phoneThree.value,
						e.target.phoneFour.value,
						e.target.phoneFive.value,
						e.target.phoneSix.value,
					],
					address: e.target.address.value,
				},
			});
			if (res) {
				setReferesh(!referesh);
				setEditProfile(false);
				notification.success({
					message: "پروفایل با موفقیت ویرایش گردید",
					placement: "topRight",
					duration: 3,
				});
				window.localStorage.setItem(
					"profile",
					JSON.stringify(res.data.editProfile)
				);
			}
		} catch (error) {
			console.log(error);
			notification.success({
				message: "خطا در ویرایش پروفایل",
				placement: "topRight",
				duration: 3,
			});
		}
	}
	// function for change file logo
	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		setFile(selectedFile);
		if (selectedFile) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setImageSrc(reader.result);
			};
			reader.readAsDataURL(selectedFile);
		}
	};
	// function for handle logo
	const handleSubmitLogo = async (event) => {
		event.preventDefault();
		if (!file) {
			notification.warning({
				message: "لطفا عکس را انتخاب نمایید",
				placement: "topRight",
				duration: 3,
			});
			// return;
		}
		const formData = new FormData();
		formData.append("image", file);
		try {
			const response = await axios.post(
				`https://${DOMAIN_NAME}${BACKEND}/uploadLogo`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
			if (response.data) {
				setMessage(response.data);
				notification.success({
					message: "لوگو موفقانه اضافه گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			// setMessage('File upload failed.');
			console.error("There was an error uploading the file!", error);
			notification.success({
				message: "خطا در افزودن لوگو",
				placement: "topRight",
				duration: 3,
			});
		}
	};
	// function for change file barcode
	const handleFileChange2 = (event) => {
		const selectedFile2 = event.target.files[0];
		setFile2(selectedFile2);
		if (selectedFile2) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setImageSrc2(reader.result);
			};
			reader.readAsDataURL(selectedFile2);
		}
	};
	// function for handle barcode
	const handleSubmitBarcode = async (event) => {
		event.preventDefault();
		if (!file2) {
			notification.warning({
				message: "لطفا عکس را انتخاب نمایید",
				placement: "topRight",
				duration: 3,
			});
			// return;
		}
		const formData = new FormData();
		formData.append("image", file2);
		try {
			const response = await axios.post(
				`https://${DOMAIN_NAME}${BACKEND}/uploadBarcode`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
			if (response.data) {
				setMessage(response.data);
				notification.success({
					message: "بارکد موفقانه اضافه گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			// setMessage('File upload failed.');
			console.error("There was an error uploading the file!", error);
			notification.success({
				message: "خطا در افزودن بارکد",
				placement: "topRight",
				duration: 3,
			});
		}
	};
	return (
		<div>
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				{newProfile && (
					<Modal
						title="افزودن مشخصات سیستم"
						open={newProfile}
						onCancel={() => {
							setNewProfile(!newProfile);
						}}
						footer={null}
						style={{ direction: "rtl" }}
						centered>
						<form onSubmit={addProfile}>
							<Space direction="vertical">
								<Input
									id="titleOne"
									name="titleOne"
									size="large"
									placeholder="سر برگ اول"
									// defaultValue={profile?.titleOne}
								/>
								<Input
									id="titleTwo"
									name="titleTwo"
									size="large"
									placeholder="سر برگ دوم"
									// defaultValue={profile?.titleTwo}
								/>
								<Input
									id="ownerOne"
									name="ownerOne"
									size="large"
									placeholder="ریس شرکت 1"
									// defaultValue={profile?.owners[0]}
								/>
								<Input
									id="ownerTwo"
									name="ownerTwo"
									size="large"
									placeholder="ریس شرکت 2"
									// defaultValue={profile?.owners[1]}
								/>
								<span className="phone-label">شماره های سیستم</span>
								<Space className="phone-container">
									<Input
										id="phoneOne"
										name="phoneOne"
										size="large"
										placeholder="شماره ریس 1"
										className="phone-input"
										// defaultValue={profile?.phones[0]}
									/>
									<Input
										id="phoneTwo"
										name="phoneTwo"
										size="large"
										placeholder="شماره ریس 1"
										className="phone-input"
										// defaultValue={profile?.phones[1]}
									/>
									<Input
										id="phoneThree"
										name="phoneThree"
										size="large"
										placeholder="شماره ریس 1"
										className="phone-input"
										// defaultValue={profile?.phones[2]}
									/>
									<Input
										id="phoneFour"
										name="phoneFour"
										size="large"
										placeholder="شماره ریس 2"
										className="phone-input"
										// defaultValue={profile?.phones[3]}
									/>
									<Input
										id="phoneFive"
										name="phoneFive"
										size="large"
										placeholder="شماره ریس 2"
										className="phone-input"
										// defaultValue={profile?.phones[4]}
									/>
									<Input
										id="phoneSix"
										name="phoneSix"
										size="large"
										placeholder="شماره ریس 2"
										className="phone-input"
										// defaultValue={profile?.phones[5]}
									/>
								</Space>
								<Input
									id="address"
									name="address"
									size="large"
									placeholder="آدرس شرکت"
									// defaultValue={profile?.address}
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										افزودن
									</Button>
								</Space>
							</Space>
						</form>
					</Modal>
				)}
				{editProfile && (
					<Modal
						title="ویرایش مشخصات سیستم"
						open={editProfile}
						onCancel={() => {
							setEditProfile(!editProfile);
						}}
						footer={null}
						style={{ direction: "rtl" }}
						centered>
						<form onSubmit={updateProfile}>
							<Space direction="vertical">
								<Input
									id="titleOne"
									name="titleOne"
									size="large"
									placeholder="سر برگ اول"
									defaultValue={profile?.titleOne}
								/>
								<Input
									id="titleTwo"
									name="titleTwo"
									size="large"
									placeholder="سر برگ دوم"
									defaultValue={profile?.titleTwo}
								/>
								<Input
									id="ownerOne"
									name="ownerOne"
									size="large"
									placeholder="ریس شرکت 1"
									defaultValue={profile?.owners[0]}
								/>
								<Input
									id="ownerTwo"
									name="ownerTwo"
									size="large"
									placeholder="ریس شرکت 2"
									defaultValue={profile?.owners[1]}
								/>
								<span className="phone-label">شماره های سیستم</span>
								<Space className="phone-container">
									<Input
										id="phoneOne"
										name="phoneOne"
										size="large"
										placeholder="شماره ریس 1"
										className="phone-input"
										defaultValue={profile?.phones[0]}
									/>
									<Input
										id="phoneTwo"
										name="phoneTwo"
										size="large"
										placeholder="شماره ریس 1"
										className="phone-input"
										defaultValue={profile?.phones[1]}
									/>
									<Input
										id="phoneThree"
										name="phoneThree"
										size="large"
										placeholder="شماره ریس 1"
										className="phone-input"
										defaultValue={profile?.phones[2]}
									/>
									<Input
										id="phoneFour"
										name="phoneFour"
										size="large"
										placeholder="شماره ریس 2"
										className="phone-input"
										defaultValue={profile?.phones[3]}
									/>
									<Input
										id="phoneFive"
										name="phoneFive"
										size="large"
										placeholder="شماره ریس 2"
										className="phone-input"
										defaultValue={profile?.phones[4]}
									/>
									<Input
										id="phoneSix"
										name="phoneSix"
										size="large"
										placeholder="شماره ریس 2"
										className="phone-input"
										defaultValue={profile?.phones[5]}
									/>
								</Space>
								<Input
									id="address"
									name="address"
									size="large"
									placeholder="آدرس شرکت"
									defaultValue={profile?.address}
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ویرایش
									</Button>
								</Space>
							</Space>
						</form>
					</Modal>
				)}

				<Space
					className="bg-gray-100 w-full h-screen flex flex-col justify-start items-start relative pt-5 pr-2"
					style={{ direction: "rtl" }}>
					<Space>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							سر برگ اول شرکت :
						</Tag>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							{profile?.titleOne}
						</Tag>
					</Space>
					<Space>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							سر برگ دوم شرکت :
						</Tag>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							{profile?.titleTwo}
						</Tag>
					</Space>
					<Space>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							نام ریس شرکت 1
						</Tag>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							{profile?.owners[0]}
						</Tag>
					</Space>
					<Space>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							نام ریس شرکت 2
						</Tag>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							{profile?.owners[1]}
						</Tag>
					</Space>
					<Space>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							شماره های موبایل ریس 1
						</Tag>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							{profile?.phones[0]}
						</Tag>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							{profile?.phones[1]}
						</Tag>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							{profile?.phones[2]}
						</Tag>
					</Space>
					<Space>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							شماره های موبایل ریس 2
						</Tag>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							{profile?.phones[3]}
						</Tag>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							{profile?.phones[4]}
						</Tag>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							{profile?.phones[5]}
						</Tag>
					</Space>
					<Space>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							آدرس شرکت :
						</Tag>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							{profile?.address}
						</Tag>
					</Space>
					<Space>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							فورمت فاکتور :
						</Tag>
						<Select
							showSearch
							optionFilterProp="children"
							placeholder="صلاحیت کاربر"
							name="userRoll"
							onChange={(value) => {
								localStorage.setItem("factorFormat", value);
							}}
							defaultValue={localStorage.getItem("factorFormat")}>
							<Option key="A4" style={{ direction: "rtl" }} value="A4">
								A4
							</Option>
							<Option key="A5" style={{ direction: "rtl" }} value="A5">
								A5
							</Option>
						</Select>
						<Tag
							bordered={false}
							color="success"
							className="text-sm md:text-base">
							{profile?.address}
						</Tag>
					</Space>
					<Space className="profileImage-container">
						<form onSubmit={handleSubmitLogo}>
							<label htmlFor="uploadLogo" className="profileImage-label">
								<span className="profileImage">
									<input
										type="file"
										accept="image/*"
										className="profileImage-input"
										onChange={handleFileChange}
										id="uploadLogo"
									/>
									{imageSrc ? (
										<img src={imageSrc} alt="Selected" />
									) : (
										<img
											src={
												profile?.logo
													? `https://${DOMAIN_NAME}${BACKEND}/images/${profile?.logo}`
													: "upload.png"
											}
											alt="logo"
										/>
									)}
								</span>
							</label>
							<Button
								className="profileImage-btn"
								type="primary"
								htmlType="submit"
								size="large"
								icon={<FaCloudUploadAlt />}>
								آپلود لوگو
							</Button>
						</form>
						<form onSubmit={handleSubmitBarcode}>
							<label htmlFor="uploadBarcode" className="profileImage-label">
								<span className="profileImage">
									<input
										type="file"
										accept="image/*"
										className="profileImage-input"
										onChange={handleFileChange2}
										id="uploadBarcode"
									/>
									{imageSrc2 ? (
										<img src={imageSrc2} alt="Selected" />
									) : (
										<img
											src={
												profile?.barcode
													? `https://${DOMAIN_NAME}${BACKEND}/images/${profile?.barcode}`
													: "upload.png"
											}
											alt="Selected"
										/>
									)}
								</span>
							</label>
							<Button
								className="profileImage-btn"
								type="primary"
								htmlType="submit"
								size="large"
								icon={<FaCloudUploadAlt />}>
								آپلود بارکد
							</Button>
						</form>
					</Space>
					{!profile ? (
						<Button
							className="w-[260px]"
							type="primary"
							htmlType="submit"
							size="large"
							icon={<EditOutlined />}
							onClick={() => setNewProfile(!newProfile)}>
							افزودن
						</Button>
					) : (
						<Button
							className="w-[260px]"
							type="primary"
							htmlType="submit"
							size="large"
							icon={<EditOutlined />}
							onClick={() => setEditProfile(!editProfile)}>
							ویرایش
						</Button>
					)}
				</Space>
			</Content>
		</div>
	);
}
