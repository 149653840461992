import { Table } from "antd";
function TableComponent({ columns, data, loading, pagination }) {
	return (
		<Table
			columns={columns}
			dataSource={data}
			loading={loading}
			pagination={pagination}
			className="rtl-dir w-11/12 mx-auto"
			locale={{
				emptyText: (
					<img
						src="emptyData.png"
						alt="empty"
						width={200}
						style={{ margin: "0 auto" }}
					/>
				),
			}}
			scroll={{
				x: 600,
				y: 55 * 5,
			}}
		/>
	);
}
export default TableComponent;
