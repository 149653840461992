import { useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { CUSTOMERS } from "../../GraphQL/Query";
import { useApolloClient } from "@apollo/client";
import { Loader } from "../StaticComponents/Loader";
import "../../Styles/StaticComponents/Global.css";
import PdfComponent from "../Pdf/PdfComponent";
export function PrintCustomer() {
	const client = useApolloClient();
	const navigate = useNavigate();
	const [customers, setCustomers] = useState([]);
	const [pages, setPages] = useState([]);
	const [isLoader, setIsLoader] = useState(false);
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadCustomers();
	}, []);
	async function loadCustomers() {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: CUSTOMERS,
				variables: {
					pageNumber: -1,
					perPage: -1,
					searchItem: "",
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setIsLoader(false);
				const fetchedDrugs = res.data.getCustomers.customers;
				console.log("fetchedDrugs", fetchedDrugs);
				// setDrugs(fetchedDrugs);
				const modifiedData = fetchedDrugs.map((customer, index) => ({
					col1: index + 1,
					col2: customer?.fullName,
					col3: customer?.company,
					col4: customer?.address,
					col5: customer?.city,
					col6:
						customer?.balance > 0
							? Math.abs(customer?.balance) + "  بستانکار  "
							: customer?.balance < 0
							? Math.abs(customer?.balance) + "  بدهکار  "
							: 0 + "  تسویه  ",
				}));
				// Split data into pages
				const rowsPerPage = 15;
				const splitPages = splitDataIntoPages(modifiedData, rowsPerPage);
				setPages(splitPages);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	const titles = ["#", "نام", "کمپنی", "آدرس", "ولایت", "الباقی"];
	// Helper function to split data into pages
	const splitDataIntoPages = (data, rowsPerPage) => {
		if (!Array.isArray(data)) {
			console.error("Invalid input data for splitDataIntoPages:", data);
			return [];
		}

		const pages = [];
		for (let i = 0; i < data.length; i += rowsPerPage) {
			pages.push(data.slice(i, i + rowsPerPage));
		}

		console.log("Pages generated:", pages); // Debug output
		return pages;
	};
	return (
		<div className="conatiner">
			{isLoader && <Loader />}
			{/* Render PdfComponent only when pages data is available */}
			{!isLoader && pages.length > 0 && (
				<PdfComponent
					data={pages}
					titles={titles}
					pdfTitle="لیست مشتریان"
					orientation="landscape"
				/>
			)}
			{/* <div className="print-container">
        <PrintHeader type="لیست مشتریان" />
        <div className="table-container">
          <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th className="customer-code">ردیف</th>
                <th className="customer-name">نام</th>
                <th className="customer-company">کمپنی</th>
                <th className="customer-address">آدرس</th>
                <th className="customer-province">ولایت</th>
                <th className="customer-accounting">الباقی</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer, index) => {
                return (
                  <tr id={customer.id} className="table-row">
                    <td>{index + 1}</td>
                    <td>{customer.fullName}</td>
                    <td>{customer.company}</td>
                    <td>{customer.address}</td>
                    <td>{customer.city}</td>
                    <td>
                      {Math.abs(customer?.balance)}
                      <span>
                        {customer?.balance > 0
                          ? "  بستانکار  "
                          : customer?.balance < 0
                          ? "  بدهکار  "
                          : "  تسویه  "}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="printFooter">
            سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
          </div>
        </div>
      </div> */}
		</div>
	);
}
