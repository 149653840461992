import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../Styles/Checks/CheckPrint.css";
import { CHECK, LASTCHEK } from "../../GraphQL/Query";
import { useApolloClient } from "@apollo/client";
import { Loader } from "../StaticComponents/Loader";
import PdfCheck from "../Pdf/PdfCheck";
export function PrintCheck() {
	const client = useApolloClient();
	const navigate = useNavigate();
	const location = useLocation();
	const [isLoader, setIsLoader] = useState(false);
	const [referesh, setReferesh] = useState(false);
	const [lastcheck, setLastCheck] = useState({});
	const receivedData =
		location.state?.checkType || location.state?.selectedCheck;
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		if (location.state?.checkType) {
			handleLastCheck();
		}
		if (location.state?.selectedCheck) {
			handlePrintCheck();
		}
	}, [referesh]);
	const handleLastCheck = async () => {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: LASTCHEK,
				variables: {
					checkType: receivedData,
				},
				fetchPolicy: "no-cache",
			});
			setLastCheck(res.data.getLastCheck);
			setIsLoader(false);
			setReferesh(true);
		} catch (error) {
			console.log(error);
			setIsLoader(false);
			setReferesh(false);
		}
	};
	const handlePrintCheck = async () => {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: CHECK,
				variables: {
					id: receivedData,
				},
				fetchPolicy: "no-cache",
			});
			setLastCheck(res.data.getCheck);
			setIsLoader(false);
			setReferesh(true);
		} catch (error) {
			console.log(error);
			setIsLoader(false);
			setReferesh(false);
		}
	};
	return (
		<div className="conatiner">
			{isLoader && <Loader />}
			<PdfCheck checkData={lastcheck} />
		</div>
	);
}
