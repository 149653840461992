import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightLong } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { DRUGDETAILS } from "../../GraphQL/Query";
import { Button, Dropdown, Input, Menu, Tag } from "antd";
import {
	CaretDownOutlined,
	PrinterOutlined,
	SelectOutlined,
} from "@ant-design/icons";
import Content from "../StaticComponents/Content";
import HeaderContent from "../StaticComponents/HeaderContent";
import TableComponent from "../StaticComponents/TableComponent";
export function SelectedDrug() {
	const navigate = useNavigate();
	const location = useLocation();
	const client = useApolloClient();
	const [drugs, setDrugs] = useState([]);
	const [searching, setSearching] = useState("");
	const [paymentType, setPaymentType] = useState("");
	const [factorType, setFactorType] = useState("");
	const [referesh, setReferesh] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [totalCount, setTotalCount] = useState(1);
	const [page, setPage] = useState(1);
	const receivedData = location.state?.drugId || {};
	if (location.pathname === "/selectedDrug") {
		location.pathname = "دارو انتخاب شده";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadDrugDetails();
	}, [referesh, page, searching, paymentType, factorType]);
	// columns
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "نوعیت",
			dataIndex: "drugType",
			key: "drugType",
			align: "center",
		},
		{
			title: "نام",
			dataIndex: "name",
			key: "name",
			align: "center",
		},
		{
			title: "کمپنی",
			dataIndex: "company",
			key: "company",
			align: "center",
		},
		{
			title: "تعداد",
			dataIndex: "quantity",
			key: "quantity",
			align: "center",
		},
		{
			title: "قیمت",
			dataIndex: "price",
			key: "price",
			align: "center",
		},
		{
			title: "کل",
			dataIndex: "total",
			key: "total",
			align: "center",
		},
		{
			title: "مشتری",
			dataIndex: "fullName",
			key: "fullName",
			// render: (fullName, drug) => drug?.customer?.fullName,
			// filteredValue: [searching],
			// onFilter: (value, drug) => {
			// 	return (
			// 		String(drug.customer.fullName)
			// 			.toLocaleLowerCase()
			// 			.includes(value.toLocaleLowerCase()) ||
			// 		String(drug.factorType).includes(value) ||
			// 		String(drug.paymentType).startsWith(value)
			// 	);
			// },
			align: "center",
		},
		{
			title: "فاکتور",
			dataIndex: "factorType",
			key: "factorType",
			align: "center",
		},
		{
			title: "سند",
			dataIndex: "paymentType",
			key: "paymentType",
			align: "center",
		},
		{
			title: "شماره",
			dataIndex: "factorNumber",
			key: "factorNumber",
			align: "center",
		},
		{
			title: "تاریخ",
			dataIndex: "date",
			key: "date",
			align: "center",
		},
	];
	// function for drug details
	async function loadDrugDetails() {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: DRUGDETAILS,
				variables: {
					id: receivedData,
					searchItem: searching,
					perPage: 10,
					pageNumber: page,
					paymentType: paymentType === "" ? null : paymentType,
					factorType: factorType === "" ? null : factorType,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setDrugs([...res.data.getDrugDetails.factors]);
				setTotalCount(res.data.getDrugDetails.totalCount);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	const filterPaymentType = [
		{
			key: "1",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					paddingBlock="0"
					onClick={() => {
						setPaymentType("");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					همه
				</Button>
			),
		},
		{
			key: "2",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					paddingBlock="0"
					onClick={() => {
						setPaymentType("No_Cash");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					نسیه
				</Button>
			),
		},
		{
			key: "3",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					onClick={() => {
						setPaymentType("Cash");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					نقده
				</Button>
			),
		},
	];
	const paymentTypes = (
		<Menu>
			{filterPaymentType.map((filterType) => (
				<Menu.Item key={filterType.key}>{filterType.label}</Menu.Item>
			))}
		</Menu>
	);
	const filterFactorType = [
		{
			key: "1",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					onClick={() => {
						setFactorType("");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					همه
				</Button>
			),
		},
		{
			key: "2",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					paddingBlock="0"
					onClick={() => {
						setFactorType("Buy");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					خرید
				</Button>
			),
		},
		{
			key: "3",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					onClick={() => {
						setFactorType("Sell");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					فروش
				</Button>
			),
		},
	];
	const factorTypes = (
		<Menu>
			{filterFactorType.map((filterPayment) => (
				<Menu.Item key={filterPayment.key}>{filterPayment.label}</Menu.Item>
			))}
		</Menu>
	);
	// drugs data
	const data = drugs.map((drug, index) => ({
		key: index,
		num: index + 1,
		id: drug._id,
		drugType: drug?.items[0]?.drug?.drugType?.title,
		name: drug?.items[0]?.drug?.name,
		company: drug?.items[0]?.drug?.company,
		quantity: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{drug?.items[0]?.quantity}
			</Tag>
		),
		price: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{drug?.items[0]?.price}
			</Tag>
		),
		total: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{drug?.items[0]?.total}
			</Tag>
		),
		fullName: drug?.customer?.fullName,
		factorType: (
			<Tag
				bordered={false}
				color={drug?.factorType === "Sell" ? "success" : "error"}>
				{drug?.factorType === "Buy" ? "خرید" : "فروش"}
			</Tag>
		),
		paymentType: (
			<Tag
				bordered={false}
				color={drug?.paymentType === "Cash" ? "success" : "error"}>
				{drug?.paymentType === "Cash" ? "نقده" : "نسیه"}
			</Tag>
		),
		factorNumber: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{drug?.buyFactorNumber || drug?.sellFactorNumber}
			</Tag>
		),
		date: drug.date,
	}));
	return (
		<div className="container">
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<HeaderContent>
					<FontAwesomeIcon
						icon={faRightLong}
						style={{ fontSize: 17, cursor: "pointer" }}
						onClick={() => navigate(-1)}
					/>
					<Input.Search
						placeholder="جستجوی مشتری"
						allowClear
						size="large"
						onSearch={(value) => {
							setSearching(value);
						}}
						onChange={(e) => {
							setSearching(e.target.value);
						}}
						style={{
							width: 250,
							direction: "ltr",
							textAlign: "right",
						}}
					/>
					<Dropdown
						overlay={paymentTypes}
						placement="bottom"
						arrow={{ pointAtCenter: true }}
						className="btn">
						<Button type="primary" size="large" icon={<CaretDownOutlined />}>
							انتخاب سند
						</Button>
					</Dropdown>
					<Dropdown
						overlay={factorTypes}
						placement="bottom"
						arrow={{ pointAtCenter: true }}
						className="btn">
						<Button type="primary" size="large" icon={<CaretDownOutlined />}>
							انتخاب پرداختی
						</Button>
					</Dropdown>
					<Button
						type="primary"
						size="large"
						icon={<PrinterOutlined />}
						className="btn"
						onClick={() =>
							navigate("/selectedDrugPrint", {
								state: { selectedDrug: receivedData },
							})
						}>
						چاپ
					</Button>
				</HeaderContent>
				<TableComponent
					data={data}
					columns={columns}
					loading={isLoader}
					rowKey="id"
					pagination={{
						pageSize: 10, // Number of records per page
						total: totalCount,
						onChange: (current) => {
							setPage(current); // Update page state
							loadDrugDetails(current); // Fetch the new page data
						},
						current: page, // Track the current page
						showLessItems: true, // Optional: Keep pagination UI minimal
					}}
				/>
			</Content>
		</div>
	);
}
