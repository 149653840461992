import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Loader } from "../StaticComponents/Loader";
import "../../Styles/StaticComponents/Global.css";
import PdfComponent from "../Pdf/PdfComponent";
export function DrugReportPrint() {
	const location = useLocation();
	const navigate = useNavigate();
	const [pages, setPages] = useState([]);
	const receivedData = location.state?.selectedDrugReport || {};
	const [isLoader, setIsLoader] = useState(false);
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadReports();
	}, []);
	const loadReports = () => {
		setIsLoader(true);
		if (receivedData) {
			const modifiedData = receivedData.map((drug, index) => ({
				col1: index + 1,
				col2: drug?.drugType?.title,
				col3: drug?.name,
				col4: drug?.company,
				col5: parseFloat(drug?.amount),
				col6: drug?.expDate,
			}));
			// Split data into pages
			const rowsPerPage = 15;
			const splitPages = splitDataIntoPages(modifiedData, rowsPerPage);
			setPages(splitPages);
			setIsLoader(false);
		}
	};
	const titles = ["#", "نوعیت", "نام", "کمپنی", "تعداد", "تاریخ"];
	// Helper function to split data into pages
	const splitDataIntoPages = (data, rowsPerPage) => {
		if (!Array.isArray(data)) {
			console.error("Invalid input data for splitDataIntoPages:", data);
			return [];
		}

		const pages = [];
		for (let i = 0; i < data.length; i += rowsPerPage) {
			pages.push(data.slice(i, i + rowsPerPage));
		}

		console.log("Pages generated:", pages); // Debug output
		return pages;
	};
	return (
		<div className="w-full">
			{isLoader && <Loader />}
			{/* Render PdfComponent only when pages data is available */}
			{!isLoader && pages.length > 0 && (
				<PdfComponent
					data={pages}
					titles={titles}
					pdfTitle="داروی انتخاب شده"
					orientation="landscape"
				/>
			)}
		</div>
	);
}
