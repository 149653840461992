import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Loader } from "../StaticComponents/Loader";
import "../../Styles/StaticComponents/Global.css";
import PdfComponent from "../Pdf/PdfComponent";
export function CustomerReportPrint() {
	const location = useLocation();
	const navigate = useNavigate();
	const [pages, setPages] = useState([]);
	const [isLoader, setIsLoader] = useState(false);
	const receivedData = location.state?.selectedCustomerReport || {};
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadReports();
	}, []);
	const loadReports = () => {
		setIsLoader(true);
		if (receivedData) {
			const modifiedData = receivedData.map((customer, index) => ({
				col1: index + 1,
				col2: customer?.fullName,
				col3: customer?.company,
				col4: customer?.address,
				col5: customer?.phoneNumber,
				col6: customer?.city,
				col7:
					customer?.balance > 0
						? Math.abs(customer?.balance) + "  بستانکار  "
						: customer?.balance < 0
						? Math.abs(customer?.balance) + "  بدهکار  "
						: 0 + "  تسویه  ",
			}));
			// Split data into pages
			const rowsPerPage = 15;
			const splitPages = splitDataIntoPages(modifiedData, rowsPerPage);
			setPages(splitPages);
			setIsLoader(false);
		}
	};
	const titles = ["#", "نام", "کمپنی", "آدرس", "موبایل", "ولایت", "الباقی"];
	// Helper function to split data into pages
	const splitDataIntoPages = (data, rowsPerPage) => {
		if (!Array.isArray(data)) {
			console.error("Invalid input data for splitDataIntoPages:", data);
			return [];
		}

		const pages = [];
		for (let i = 0; i < data.length; i += rowsPerPage) {
			pages.push(data.slice(i, i + rowsPerPage));
		}

		console.log("Pages generated:", pages); // Debug output
		return pages;
	};
	return (
		<div className="conatiner">
			{isLoader && <Loader />}
			{/* Render PdfComponent only when pages data is available */}
			{!isLoader && pages.length > 0 && (
				<PdfComponent
					data={pages}
					titles={titles}
					pdfTitle="داروی انتخاب شده"
					orientation="landscape"
				/>
			)}
			{/* <div className="file-container">
        <PrintHeader type="لیست مشتریان" />
        <div className="table-container">
        <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th>ردیف</th>
                <th>نام</th>
                <th>کمپنی</th>
                <th>آدرس</th>
                <th>مویابل</th>
                <th>ولایت</th>
                <th>الباقی</th>
              </tr>
            </thead>
            <tbody>
              {customerReportData.map((customer, index) => {
                return (
                  <tr id={customer._id} key={customer._id} className="table-row">
                    <td>{index + 1}</td>
                    <td>{customer.fullName}</td>
                    <td>{customer.company}</td>
                    <td>{customer.address}</td>
                    <td>{customer.phoneNumber}</td>
                    <td>{customer.city}</td>
                    <td>
                      {Math.abs(customer?.balance)}
                      <span>
                        {customer?.balance > 0
                          ? "  بستانکار  "
                          : customer?.balance < 0
                          ? "  بدهکار  "
                          : "  تسویه  "}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="printFooter">
            سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
          </div>
        </div>
      </div> */}
		</div>
	);
}
