import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { BACKEND, DOMAIN_NAME } from "./Constant/const";
const client = new ApolloClient({
	uri: `https://${DOMAIN_NAME}${BACKEND}/api/graphql`,
	// uri: "https://accounting-gfho.onrender.com/",
	cache: new InMemoryCache(),
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<ConfigProvider
		theme={{
			token: {
				colorPrimary: "#4379F2",
				colorSuccess: "#4379F2",
			},
		}}>
		<ApolloProvider client={client}>
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</ApolloProvider>
	</ConfigProvider>
);
reportWebVitals();
