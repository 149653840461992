import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Loader } from "../StaticComponents/Loader";
import "../../Styles/StaticComponents/Global.css";
import PdfComponent from "../Pdf/PdfComponent";
export function FactorReportPrint() {
	const location = useLocation();
	const navigate = useNavigate();
	const [pages, setPages] = useState([]);
	const [isLoader, setIsLoader] = useState(false);
	const receivedData = location.state?.selectedFactorReport || {};
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadReports();
	}, []);
	const loadReports = () => {
		setIsLoader(true);
		if (receivedData) {
			const modifiedData = receivedData.map((factor, index) => ({
				col1: index + 1,
				col2: factor?.customer?.fullName,
				col3: factor?.buyFactorNumber || factor?.sellFactorNumber,
				col4: factor?.factorType === "Buy" ? "خرید" : "فروش",
				col5: factor?.paymentType === "Cash" ? "نقده" : "نسیه",
				col6: factor?.amount,
				col7: factor?.date,
			}));
			// Split data into pages
			const rowsPerPage = 15;
			const splitPages = splitDataIntoPages(modifiedData, rowsPerPage);
			setPages(splitPages);
			setIsLoader(false);
		}
	};
	const titles = ["#", "نام", "شماره", "فاکتور", "پرداختی", "مبلغ", "تاریخ"];
	// Helper function to split data into pages
	const splitDataIntoPages = (data, rowsPerPage) => {
		if (!Array.isArray(data)) {
			console.error("Invalid input data for splitDataIntoPages:", data);
			return [];
		}

		const pages = [];
		for (let i = 0; i < data.length; i += rowsPerPage) {
			pages.push(data.slice(i, i + rowsPerPage));
		}

		console.log("Pages generated:", pages); // Debug output
		return pages;
	};
	return (
		<div className="conatiner">
			{isLoader && <Loader />}
			{/* Render PdfComponent only when pages data is available */}
			{!isLoader && pages.length > 0 && (
				<PdfComponent
					data={pages}
					titles={titles}
					pdfTitle="داروی انتخاب شده"
					orientation="landscape"
				/>
			)}
			{/* <div className="file-container">
        <PrintHeader type="لیست فاکتور ها" />
        <div className="table-container">
        <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th>ردیف</th>
                <th>نام</th>
                <th>شماره</th>
                <th>خرید / فروش</th>
                <th>نقده / نسیه</th>
                <th>مبلغ</th>
                <th>تاریخ</th>
              </tr>
            </thead>
            <tbody>
              {factorReportData.map((factor, index) => {
                return (
                  <tr id={factor._id} key={factor._id} className="table-row">
                    <td>{index + 1}</td>
                    <td>{factor.customer.fullName}</td>
                    <td>{factor.buyFactorNumber || factor.sellFactorNumber}</td>
                    <td>
                      <span >
                        {factor.factorType == "Buy" ? "خرید" : "فروش"}
                      </span>
                    </td>
                    <td>
                      <span>
                        {factor.paymentType == "Cash" ? "نقده" : "نسیه"}
                      </span>
                    </td>
                    <td>{factor.amount}</td>
                    <td>{factor.date}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="printFooter">
            سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
          </div>
        </div>
      </div> */}
		</div>
	);
}
