import { useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { USERS } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import PdfComponent from "../Pdf/PdfComponent";
export function PrintUser() {
	const client = useApolloClient();
	const [users, setUsers] = useState([]);
	const [isLoader, setIsLoader] = useState(false);
	const navigate = useNavigate();
	const [pages, setPages] = useState([]);
	const [userRole, setUserRole] = useState("");
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadUsers();
	}, [userRole]);

	const loadUsers = async () => {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: USERS,
				variables: {
					pageNumber: -1,
					perPage: -1,
					searchItem: "",
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setIsLoader(false);
				const fetchedUsers = res.data.getUsers.users;
				console.log("fetchedUsers", fetchedUsers);
				// setUsers(fetchedUsers);
				const modifiedData = fetchedUsers.map((user, index) => ({
					col1: index + 1,
					col2: user.firstName,
					col3: userRole === "Admin" ? user?.userName : "🔒",
					col4: userRole === "Admin" ? user?.password : "🔒",
					col5: user.role,
				}));
				// Split data into pages
				const rowsPerPage = 15;
				const splitPages = splitDataIntoPages(modifiedData, rowsPerPage);
				setPages(splitPages);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	};
	const titles = ["#", "نام", "نام کاربری", "پسورد", "رول"];
	// Helper function to split data into pages
	const splitDataIntoPages = (data, rowsPerPage) => {
		if (!Array.isArray(data)) {
			console.error("Invalid input data for splitDataIntoPages:", data);
			return [];
		}

		const pages = [];
		for (let i = 0; i < data.length; i += rowsPerPage) {
			pages.push(data.slice(i, i + rowsPerPage));
		}

		console.log("Pages generated:", pages); // Debug output
		return pages;
	};
	return (
		<div className="conatiner">
			{isLoader && <Loader />}
			{/* Render PdfComponent only when pages data is available */}
			{!isLoader && pages.length > 0 && (
				<PdfComponent
					data={pages}
					titles={titles}
					pdfTitle="لیست کاربران"
					orientation="landscape"
				/>
			)}
			{/* <div className="file-container">
				<PrintHeader type="لیست کاربران" />
				<div className="table-container">
					<table className="table">
						<thead className="tableHead-row">
							<tr className="print-header">
								<th>ردیف</th>
								<th>نام</th>
								<th>نام کاربری</th>
								<th>پسورد</th>
								<th>صلاحیت</th>
							</tr>
						</thead>
						<tbody>
							{users.map((user, index) => {
								return (
									<tr key={index} className="table-row">
										<td>{index + 1}</td>
										<td>{user.firstName}</td>
										<td>{userRole == "Admin" ? user.userName : "🔒"}</td>
										<td>{userRole == "Admin" ? user.password : "🔒"}</td>
										<td>{user.role}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<div className="printFooter">
					سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
				</div>
			</div> */}
		</div>
	);
}
