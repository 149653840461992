import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { CUSTOMERDETAILS } from "../../GraphQL/Query";
import { Button, Dropdown, Input, Menu, Tag, Space, Spin } from "antd";
import {
	CaretDownOutlined,
	PrinterOutlined,
	SelectOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightLong } from "@fortawesome/free-solid-svg-icons";
import Content from "../StaticComponents/Content";
import HeaderContent from "../StaticComponents/HeaderContent";
import TableComponent from "../StaticComponents/TableComponent";
export function SelectedCustomer() {
	const navigate = useNavigate();
	const location = useLocation();
	const client = useApolloClient();
	const [customers, setCustomers] = useState([]);
	const [paymentType, setPaymentType] = useState("");
	const [factorType, setFactorType] = useState("");
	const [checkType, setCheckType] = useState("");
	const [referesh, setReferesh] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [balance, setBalance] = useState(0);
	const [page, setPage] = useState(1);
	const receivedData = location.state?.customerId || {};
	if (location.pathname === "/selectedCustomer") {
		location.pathname = "مشتری انتخاب شده";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadCustomerDetails();
	}, [referesh, balance, paymentType, factorType, checkType]);
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "نام",
			dataIndex: "fullName",
			key: "fullName",
			// render: (fullName, customer) => customer?.customer?.fullName,
			// filteredValue: [searching],
			// onFilter: (value, customer) => {
			// 	return (
			// 		String(customer?.customer?.fullName)
			// 			.toLocaleLowerCase()
			// 			.includes(value.toLocaleLowerCase()) ||
			// 		String(customer.factorType).includes(value) ||
			// 		String(customer.checkType).includes(value) ||
			// 		String(customer.paymentType).startsWith(value)
			// 	);
			// },
			align: "center",
		},
		{
			title: "شماره بل",
			dataIndex: "billNumber",
			key: "billNumber",
			align: "center",
		},
		{
			title: "نوعیت بل",
			dataIndex: "billType",
			key: "billType",
			align: "center",
		},
		{
			title: "نوعیت پرداختی",
			dataIndex: "paymentType",
			key: "paymentType",
			align: "center",
		},
		{
			title: "مبلغ",
			dataIndex: "amount",
			key: "amount",
			align: "center",
		},
		{
			title: "بلانس",
			dataIndex: "balance",
			key: "balance",
			align: "center",
		},
		{
			title: "تاریخ",
			dataIndex: "date",
			key: "date",
			align: "center",
		},
	];
	async function loadCustomerDetails() {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: CUSTOMERDETAILS,
				variables: {
					id: receivedData,
					perPage: 10,
					pageNumber: page,
					paymentType: paymentType === "" ? null : paymentType,
					factorType: factorType === "" ? null : factorType,
					checkType: checkType === "" ? null : checkType,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setCustomers([...res.data.getCustomerDetails]);
				setIsLoader(false);
			}
			setBalance(customers[0]?.customer?.balance);
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	const filterPaymentType = [
		{
			key: "1",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					paddingBlock="0"
					onClick={() => {
						setPaymentType("");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					همه
				</Button>
			),
		},
		{
			key: "2",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					paddingBlock="0"
					onClick={() => {
						setPaymentType("No_Cash");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					نسیه
				</Button>
			),
		},
		{
			key: "3",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					onClick={() => {
						setPaymentType("Cash");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					نقده
				</Button>
			),
		},
	];
	const paymentTypes = (
		<Menu>
			{filterPaymentType.map((filterType) => (
				<Menu.Item key={filterType.key}>{filterType.label}</Menu.Item>
			))}
		</Menu>
	);
	const filterFactorType = [
		{
			key: "1",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					onClick={() => {
						setFactorType("");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					همه
				</Button>
			),
		},
		{
			key: "2",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					paddingBlock="0"
					onClick={() => {
						setFactorType("Buy");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					خرید
				</Button>
			),
		},
		{
			key: "3",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					onClick={() => {
						setFactorType("Sell");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					فروش
				</Button>
			),
		},
	];
	const factorTypes = (
		<Menu>
			{filterFactorType.map((filterPayment) => (
				<Menu.Item key={filterPayment.key}>{filterPayment.label}</Menu.Item>
			))}
		</Menu>
	);
	const filterChecks = [
		{
			key: "1",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					paddingBlock="0"
					onClick={() => {
						setCheckType("");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					همه
				</Button>
			),
		},
		{
			key: "2",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					paddingBlock="0"
					onClick={() => {
						setCheckType("Check_In");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					دریافتی
				</Button>
			),
		},
		{
			key: "3",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					onClick={() => {
						setCheckType("Check_Out");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					پرداختی
				</Button>
			),
		},
	];
	const checkTypes = (
		<Menu>
			{filterChecks.map((filterCheck) => (
				<Menu.Item key={filterCheck.key}>{filterCheck.label}</Menu.Item>
			))}
		</Menu>
	);
	// customerSelected data
	const data = customers.map((customer, index) => ({
		key: index,
		num: index + 1,
		id: customer._id,
		fullName: customer?.customer?.fullName,
		billNumber: (
			<Tag bordered={false}>
				{customer?.checkInNumber ||
					customer?.checkOutNumber ||
					customer?.sellFactorNumber ||
					customer?.buyFactorNumber}
			</Tag>
		),
		billType: (
			<Tag
				color={
					customer?.factorType === "Sell" || customer?.checkType === "Check_In"
						? "success"
						: "error"
				}
				bordered={false}>
				{customer?.factorType === "Buy"
					? "خرید"
					: customer?.factorType === "Sell"
					? "فروش"
					: customer?.checkType === "Check_In"
					? "دریافتی"
					: customer?.checkType === "Check_Out"
					? "پرداختی"
					: ""}
			</Tag>
		),
		paymentType: (
			<Tag
				color={customer?.paymentType === "Cash" ? "success" : "error"}
				bordered={false}>
				{customer?.paymentType === "Cash"
					? "نقده"
					: customer?.paymentType === "No_Cash"
					? "نسیه"
					: ""}
			</Tag>
		),
		amount: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{customer?.amount}
			</Tag>
		),
		balance: (
			<Tag
				bordered={false}
				color={
					customer?.customerBalance > 0
						? "error"
						: customer?.customerBalance === 0
						? ""
						: "success"
				}
				style={{
					fontSize: 15,
				}}>
				{
					<Space>
						{Math.abs(customer?.customerBalance)}
						{""}
						{customer?.customerBalance > 0
							? "بستانکار"
							: customer?.customerBalance === 0
							? "تسویه"
							: "بدهکار"}
					</Space>
				}
			</Tag>
		),
		date: customer?.date,
	}));
	return (
		<div className="container">
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<HeaderContent>
					<FontAwesomeIcon
						icon={faRightLong}
						style={{ fontSize: 17, cursor: "pointer" }}
						onClick={() => navigate(-1)}
					/>
					<Dropdown
						overlay={paymentTypes}
						placement="bottom"
						arrow={{ pointAtCenter: true }}
						className="btn">
						<Button type="primary" size="large" icon={<CaretDownOutlined />}>
							پرداختی
						</Button>
					</Dropdown>
					<Dropdown
						overlay={factorTypes}
						placement="bottom"
						arrow={{ pointAtCenter: true }}
						className="btn">
						<Button type="primary" size="large" icon={<CaretDownOutlined />}>
							فاکتور
						</Button>
					</Dropdown>
					<Dropdown
						overlay={checkTypes}
						placement="bottom"
						arrow={{ pointAtCenter: true }}
						className="btn">
						<Button type="primary" size="large" icon={<CaretDownOutlined />}>
							سند
						</Button>
					</Dropdown>
					<Button
						type="primary"
						size="large"
						icon={<PrinterOutlined />}
						onClick={() =>
							navigate("/customerSelectedPrint", {
								state: { customerId: receivedData },
							})
						}
						className="btn">
						چاپ
					</Button>
					{balance == 0 || balance > 0 || balance < 0 ? (
						<Tag
							bordered={false}
							color={balance > 0 ? "error" : balance < 0 ? "success" : ""}
							style={{
								fontSize: 20,
								fontFamily: "B-NAZANIN",
								height: "40px",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								textAlign: "center",
							}}>
							{
								<Space>
									{Math.abs(balance)}
									{""}
									{balance > 0
										? "بستانکار"
										: balance === 0
										? "تسویه"
										: "بدهکار"}
								</Space>
							}
						</Tag>
					) : (
						<Spin />
					)}
				</HeaderContent>
				<TableComponent
					data={data}
					columns={columns}
					loading={isLoader}
					rowKey="id"
					pagination={{
						pageSize: 10, // Number of records per page
						// total: totalCount,
						onChange: (current) => {
							setPage(current); // Update page state
							loadCustomerDetails(current); // Fetch the new page data
						},
						current: page, // Track the current page
						showLessItems: true, // Optional: Keep pagination UI minimal
					}}
				/>
			</Content>
		</div>
	);
}
