import { useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { Loader } from "../StaticComponents/Loader";
import { STACKS } from "../../GraphQL/Query";
import { useApolloClient } from "@apollo/client";
import PdfComponent from "../Pdf/PdfComponent";

export function PrintStack() {
	const client = useApolloClient();
	const navigate = useNavigate();
	const [stacks, setStacks] = useState([]);
	const [pages, setPages] = useState([]);
	const [isLoader, setIsLoader] = useState(false);
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadStacks();
	}, []);
	const loadStacks = async () => {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: STACKS,
				variables: {
					pageNumber: -1,
					perPage: -1,
					searchItem: "",
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setIsLoader(false);
				const fetchedStacks = res.data.getStacks.stacks;
				console.log("fetchedStacks", fetchedStacks);
				// setStacks(fetchedDrugs);
				const modifiedData = fetchedStacks.map((stack, index) => ({
					col1: index + 1,
					col2: stack?.name,
					col3: stack?.type,
					col4: stack?.address,
				}));
				// Split data into pages
				const rowsPerPage = 15;
				const splitPages = splitDataIntoPages(modifiedData, rowsPerPage);
				setPages(splitPages);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	};
	const titles = ["#", "نام", "نوعیت", "آدرس"];
	// Helper function to split data into pages
	const splitDataIntoPages = (data, rowsPerPage) => {
		if (!Array.isArray(data)) {
			console.error("Invalid input data for splitDataIntoPages:", data);
			return [];
		}

		const pages = [];
		for (let i = 0; i < data.length; i += rowsPerPage) {
			pages.push(data.slice(i, i + rowsPerPage));
		}

		console.log("Pages generated:", pages); // Debug output
		return pages;
	};
	return (
		<div className="conatiner">
			{isLoader && <Loader />}
			{/* Render PdfComponent only when pages data is available */}
			{!isLoader && pages.length > 0 && (
				<PdfComponent
					data={pages}
					titles={titles}
					pdfTitle="داروی انتخاب شده"
					orientation="landscape"
				/>
			)}
			{/* <div className="printDrug-container">
        <PrintHeader type="لیست گدام ها" />
        <div className="table-container">
        <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th className="stack-code">ردیف</th>
                <th className="stack-name">نام</th>
                <th className="stack-type">نوعیت</th>
                <th className="stack-address">آدرس</th>
              </tr>
            </thead>
            <tbody>
              {stacks.map((stack, index) => {
                return (
                  <tr id={stack.id} className="table-row">
                    <td>{index + 1}</td>
                    <td>{stack.name}</td>
                    <td>{stack.type}</td>
                    <td>{stack.address}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="printFooter">
          سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
        </div>
      </div> */}
		</div>
	);
}
