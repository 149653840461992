import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { CUSTOMERDETAILS } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import "../../Styles/StaticComponents/Global.css";
import PdfComponent from "../Pdf/PdfComponent";
export function CustomerSelectedPrint() {
	const navigate = useNavigate();
	const location = useLocation();
	const client = useApolloClient();
	const [isLoader, setIsLoader] = useState(false);
	const [pages, setPages] = useState([]);
	const receivedData = location.state?.customerId || {};
	if (location.pathname === "/selectedCustomer") {
		location.pathname = "مشتری انتخاب شده";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadCustomerDetails();
	}, []);
	async function loadCustomerDetails() {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: CUSTOMERDETAILS,
				variables: {
					id: receivedData,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setIsLoader(false);
				const fetchedCustomers = res.data.getCustomerDetails;
				console.log("fetchedCustomers", fetchedCustomers);
				// setCustomers(fetchedCustomers);
				const modifiedData = fetchedCustomers.map((customer, index) => ({
					col1: index + 1,
					col2: customer?.customer?.fullName,
					col3:
						customer?.checkInNumber ||
						customer?.checkOutNumber ||
						customer?.buyFactorNumber ||
						customer?.sellFactorNumber,
					col4:
						customer?.factorType === "Buy"
							? "خرید"
							: customer?.factorType === "Sell"
							? "فروش"
							: customer?.checkType === "Check_In"
							? "دریافتی"
							: customer?.checkType === "Check_Out"
							? "پرداختی"
							: "",
					col5:
						customer?.paymentType === "Cash"
							? "نقده"
							: customer?.paymentType === "No_Cash"
							? "نسیه"
							: "",
					col6: customer?.amount,
					col7:
						customer?.customerBalance > 0
							? Math.abs(customer?.customerBalance) + "  بستانکار  "
							: customer?.customerBalance < 0
							? Math.abs(customer?.customerBalance) + "  بدهکار  "
							: 0 + "  تسویه  ",
					col8: customer?.date,
				}));
				// Split data into pages
				const rowsPerPage = 15;
				const splitPages = splitDataIntoPages(modifiedData, rowsPerPage);
				setPages(splitPages);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	const titles = [
		"#",
		"نام",
		"شماره بل",
		"نوعیت بل",
		"نوعیت پرداختی",
		"مبلغ",
		"بیلانس",
		"تاریخ",
	];
	// Helper function to split data into pages
	const splitDataIntoPages = (data, rowsPerPage) => {
		if (!Array.isArray(data)) {
			console.error("Invalid input data for splitDataIntoPages:", data);
			return [];
		}

		const pages = [];
		for (let i = 0; i < data.length; i += rowsPerPage) {
			pages.push(data.slice(i, i + rowsPerPage));
		}

		console.log("Pages generated:", pages); // Debug output
		return pages;
	};
	return (
		<div className="conatiner">
			{isLoader && <Loader />}
			{/* Render PdfComponent only when pages data is available */}
			{!isLoader && pages.length > 0 && (
				<PdfComponent
					data={pages}
					titles={titles}
					pdfTitle="داروی انتخاب شده"
					orientation="landscape"
				/>
			)}
			{/* <div className="file-container">
        <PrintHeader type="بل های مشتری" />
        <div className="table-container">
          <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th>ردیف</th>
                <th>نام</th>
                <th>شماره بل</th>
                <th>نوعیت بل</th>
                <th>نوعیت پرداختی</th>
                <th>مبلغ</th>
                <th>بلانس</th>
                <th>تاریخ</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer, index) => {
                if (
                  customer?.factorType?.includes(searching) ||
                  customer?.paymentType?.startsWith(searching) ||
                  customer?.checkType?.startsWith(searching)
                ) {
                  return (
                    <tr key={index} className="table-row">
                      <td>{index + 1}</td>
                      <td>{customer?.customer?.fullName}</td>
                      <td>
                        {customer?.checkInNumber ||
                          customer?.checkOutNumber ||
                          customer?.buyFactorNumber ||
                          customer?.sellFactorNumber}
                      </td>
                      <td>
                        <span>
                          {customer?.factorType == "Buy"
                            ? "خرید"
                            : customer?.factorType == "Sell"
                              ? "فروش"
                              : customer?.checkType == "Check_In"
                                ? "دریافتی"
                                : customer?.checkType == "Check_Out"
                                  ? "پرداختی"
                                  : ""}
                        </span>
                      </td>
                      <td>
                        <span>
                          {customer?.paymentType == "Cash"
                            ? "نقده"
                            : customer?.paymentType == "No_Cash"
                              ? "نسیه"
                              : ""}
                        </span>
                      </td>
                      <td>{customer?.amount}</td>
                      <td>
                        {Math.abs(customer?.customerBalance)}
                        {" "}
                        {customer?.customerBalance > 0 ? "بستانکار" : customer?.customerBalance === 0 ? "تسویه" : "بدهکار"}
                      </td>
                      <td>{customer?.date}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
          <div className="printFooter">
            سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
          </div>
        </div>
      </div> */}
		</div>
	);
}
