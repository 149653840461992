import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { REPORTDRUG } from "../../GraphQL/Query";
import { DRUGS, DRUGTYPES, STACKS } from "../../GraphQL/Query";
import {
	Button,
	Input,
	Modal,
	Select,
	Space,
	Tag,
	DatePicker,
	InputNumber,
	Spin,
} from "antd";
import { PlusOutlined, PrinterOutlined } from "@ant-design/icons";
import Content from "../StaticComponents/Content";
import HeaderContent from "../StaticComponents/HeaderContent";
import TableComponent from "../StaticComponents/TableComponent";
const { Option } = Select;
export function DrugReports() {
	const client = useApolloClient();
	const navigate = useNavigate();
	const location = useLocation();
	const [selectedDrug, setSelectedDrug] = useState("");
	const [drugReport, setDrugReport] = useState(false);
	const [searching, setSearching] = useState("");
	const [drugs, setDrugs] = useState([]);
	const [drugReports, setDrugReports] = useState([]);
	const [stacks, setStacks] = useState([]);
	const [drugTypes, setDrugTypes] = useState([]);
	const [isLoader, setIsLoader] = useState(false);
	const [selectedDrugType, setSelectedDrugType] = useState("");
	const [selectedDrugId, setSelectedDrugId] = useState("");
	const [selectedStack, setSelectedStack] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const [page, setPage] = useState(1);
	if (location.pathname === "/drugReports") {
		location.pathname = "گزارش داروها";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadDrugTypes();
		loadStacks();
		loadDrugs();
	}, [searchValue]);
	// columns
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "نوعیت",
			dataIndex: "drugType",
			key: "drugType",
			align: "center",
		},
		{
			title: "نام",
			dataIndex: "name",
			// filteredValue: [searching],
			// onFilter: (value, record) => {
			// 	return String(record.name)
			// 		.toLocaleLowerCase()
			// 		.includes(value.toLocaleLowerCase());
			// },
			key: "name",
			align: "center",
		},
		{
			title: "کمپنی",
			dataIndex: "company",
			key: "company",
			align: "center",
		},
		{
			title: "کشور",
			dataIndex: "country",
			key: "country",
			align: "center",
		},
		{
			title: "تعداد",
			dataIndex: "amount",
			key: "amount",
			align: "center",
		},
		{
			title: "قیمت",
			dataIndex: "price",
			key: "price",
			align: "center",
		},
		{
			title: "تاریخ",
			dataIndex: "expDate",
			key: "expDate",
			align: "center",
		},
	];
	// function for fetch drugs
	async function loadDrugs() {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: DRUGS,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: searchValue,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setDrugs([...res.data.getDrugs.drugs]);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	// function for fetch drugType
	async function loadDrugTypes() {
		setIsLoader(true);
		try {
			const res = await client.query({
				query: DRUGTYPES,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: searchValue,
				},
				fetchPolicy: "no-cache",
			});
			setDrugTypes([...res.data.getDrugTypes.drugTypes]);
			setIsLoader(false);
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	// function for fetch stacks
	const loadStacks = async () => {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: STACKS,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: searchValue,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setStacks([...res.data.getStacks.stacks]);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	};
	// function for take report of drugs
	const handleReportDrug = async (e) => {
		e.preventDefault();
		try {
			const res = await client.query({
				query: REPORTDRUG,
				variables: {
					drugType: selectedDrugType !== "" ? selectedDrugType : undefined,
					drugName: selectedDrugId !== "" ? selectedDrugId : undefined,
					drugCompany:
						e.target.drugCompany.value !== ""
							? e.target.drugCompany.value
							: undefined,
					drugCountry:
						e.target.drugCountry.value !== ""
							? e.target.drugCountry.value
							: undefined,
					drugStack: selectedStack !== "" ? selectedStack : undefined,
					startAmount:
						Number(e.target.startAmount.value) !== 0
							? Number(e.target.startAmount.value)
							: undefined,
					endAmount:
						Number(e.target.endAmount.value) !== 0
							? Number(e.target.endAmount.value)
							: undefined,
					startPrice:
						Number(e.target.startPrice.value) !== 0
							? Number(e.target.startPrice.value)
							: undefined,
					endPrice:
						Number(e.target.endPrice.value) !== 0
							? Number(e.target.endPrice.value)
							: undefined,
					startDate:
						e.target.startDate.value !== ""
							? e.target.startDate.value.replace(
									/[\u0660-\u0669\u06f0-\u06f9]/g,
									function (a) {
										return a.charCodeAt(0) & 0xf;
									}
							  )
							: undefined,
					endDate:
						e.target.endDate.value !== ""
							? e.target.endDate.value.replace(
									/[\u0660-\u0669\u06f0-\u06f9]/g,
									function (a) {
										return a.charCodeAt(0) & 0xf;
									}
							  )
							: undefined,
				},
				fetchPolicy: "no-cache",
			});
			setDrugReports([...res.data.reportDrugs]);
		} catch (error) {
			console.log(error);
		}
		setDrugReport(!drugReport);
		setSelectedDrug("");
		setSelectedDrugType();
		setSelectedDrugId();
		setSelectedStack();
		setSearchValue("");
		setSelectedDrugId();
	};
	// drugsReport data
	const data = drugReports.map((drug, index) => ({
		key: index,
		num: index + 1,
		id: drug._id,
		drugType: drug.drugType.title,
		name: drug.name,
		company: drug.company,
		country: drug.country,
		amount: (
			<Tag
				bordered={false}
				color={drug.amount > 0 ? "success" : drug.amount === 0 ? "" : "error"}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
					direction: "ltr",
				}}>
				{drug.amount}
			</Tag>
		),
		price: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{drug.price}
			</Tag>
		),
		expDate: drug.expDate,
	}));
	return (
		<div className="container">
			{/* drug report modal */}
			{drugReport && (
				<Modal
					title="گزارش دارو"
					open={drugReport}
					onCancel={() => {
						setDrugReport(!drugReport);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<form onSubmit={handleReportDrug}>
						<Space direction="vertical">
							{/* selectbox for search drug type */}
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="نوعیت دارو"
								className="w-full"
								name="checkType"
								onChange={(value) => {
									setSelectedDrugType(value);
								}}
								onSearch={(value) => {
									setSearchValue(value);
									loadDrugTypes(value);
									setSelectedDrugType(value);
								}}
								notFoundContent={
									isLoader ? (
										<Spin size="small" />
									) : (
										<Tag color="error" className="w-full text-center">
											موردی یافت نشد
										</Tag>
									)
								}
								size="large">
								{drugTypes.map((drugType) => {
									return (
										<Option
											value={drugType._id}
											key={drugType._id}
											style={{ direction: "rtl" }}>
											{drugType.title}
										</Option>
									);
								})}
							</Select>
							{/* selectbox for search drug */}
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="نام دارو"
								className="w-full"
								name="drugName"
								onChange={(value) => {
									setSelectedDrugId(value);
								}}
								onSearch={(value) => {
									setSearchValue(value);
									loadDrugs(value);
									setSelectedDrugId(value);
								}}
								notFoundContent={
									isLoader ? (
										<Spin size="small" />
									) : (
										<Tag color="error" className="w-full text-center">
											موردی یافت نشد
										</Tag>
									)
								}
								size="large">
								{drugs.map((drug, index) => (
									<Option
										key={drug._id}
										value={drug.name}
										style={{ direction: "rtl" }}>
										{drug.name}
									</Option>
								))}
							</Select>
							<Input
								placeholder="کمپنی دارو"
								id="drugCompany"
								name="drugCompany"
								size="large"
							/>
							<Input
								placeholder="کشور دارو"
								id="drugCountry"
								name="drugCountry"
								size="large"
							/>
							{/* selectbox for search stack */}
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="گدام دارو"
								className="w-full"
								name="drugStack"
								onChange={(value) => {
									setSelectedStack(value);
								}}
								onSearch={(value) => {
									setSearchValue(value);
									loadStacks(value);
									setSelectedStack(value);
								}}
								notFoundContent={
									isLoader ? (
										<Spin size="small" />
									) : (
										<Tag color="error" className="w-full text-center">
											موردی یافت نشد
										</Tag>
									)
								}
								size="large">
								{stacks.map((stack) => (
									<Option
										key={stack._id}
										value={stack._id}
										style={{ direction: "rtl" }}>
										{stack.name}
									</Option>
								))}
							</Select>
							<Space direction="horizontal">
								<InputNumber
									id="startAmount"
									name="startAmount"
									size="large"
									placeholder="تعداد دارو"
									min={1}
									className="inputRep"
								/>
								<span>تا</span>
								<InputNumber
									id="endAmount"
									name="endAmount"
									size="large"
									placeholder="تعداد دارو"
									min={1}
									className="inputRep"
								/>
							</Space>
							<Space direction="horizontal">
								<InputNumber
									id="startPrice"
									name="startPrice"
									size="large"
									placeholder="قیمت دارو"
									min={1}
									className="inputRep"
								/>
								<span>تا</span>
								<InputNumber
									id="endPrice"
									name="endPrice"
									size="large"
									placeholder="قیمت دارو"
									min={1}
									className="inputRep"
								/>
							</Space>
							<Space direction="horizontal">
								<DatePicker
									placeholder="تاریخ دارو"
									placement="topRight"
									id="startDate"
									name="startDate"
									size="large"
									className="inputRep"
								/>
								<span>تا</span>
								<DatePicker
									placeholder="تاریخ دارو"
									placement="topRight"
									id="endDate"
									name="endDate"
									size="large"
									className="inputRep"
								/>
							</Space>
							<Space
								style={{
									width: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Button
									type="primary"
									htmlType="submit"
									size="large"
									icon={<PlusOutlined />}
									className="btn">
									گزارش دارو
								</Button>
							</Space>
						</Space>
					</form>
				</Modal>
			)}
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<HeaderContent>
					<Input.Search
						placeholder="جستجوی دارو"
						allowClear
						size="large"
						onChange={(e) => {
							setSearching(e.target.value);
						}}
						style={{
							width: 250,
							direction: "ltr",
							textAlign: "right",
						}}
					/>
					<Button
						type="primary"
						size="large"
						icon={<PlusOutlined />}
						onClick={() => {
							setDrugReport(!drugReport);
						}}
						className="btn">
						گزارش دارو
					</Button>
					<Button
						type="primary"
						size="large"
						icon={<PrinterOutlined />}
						onClick={() =>
							navigate("/drugReportPrint", {
								state: { selectedDrugReport: drugReports },
							})
						}
						className="btn">
						چاپ
					</Button>
				</HeaderContent>
				<TableComponent
					data={data}
					columns={columns}
					loading={isLoader}
					rowKey="id"
					pagination={{
						pageSize: 10, // Number of records per page
						// total: totalCount,
						onChange: (current) => {
							setPage(current); // Update page state
							loadDrugs(current); // Fetch the new page data
						},
						current: page, // Track the current page
						showLessItems: true, // Optional: Keep pagination UI minimal
					}}
				/>
			</Content>
		</div>
	);
}
