import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { REPORTCHECK } from "../../GraphQL/Query";
import { CUSTOMERS } from "../../GraphQL/Query";
import {
	Button,
	Input,
	Space,
	Tag,
	Select,
	Modal,
	InputNumber,
	Spin,
} from "antd";
import { PlusOutlined, PrinterOutlined } from "@ant-design/icons";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import { MONTHS } from "../../Constant/const";
import Content from "../StaticComponents/Content";
import HeaderContent from "../StaticComponents/HeaderContent";
import TableComponent from "../StaticComponents/TableComponent";
const { Option } = Select;
export function CheckReport() {
	const date = new DateObject({
		calendar: persian,
		locale: persian_fa,
		format: "YYYY-MM-DD",
	});
	const today = date.format();
	const client = useApolloClient();
	const location = useLocation();
	const navigate = useNavigate();
	const [reportChecks, setReportChecks] = useState([]);
	const [reportCheck, setReportCheck] = useState(false);
	const [searching, setSearching] = useState("");
	const [isLoader, setIsLoader] = useState(false);
	const [customers, setCustomers] = useState([]);
	const [selectedCustomer, setSelectedCustomer] = useState("");
	const [selectedCustomerId, setSelectedCustomerId] = useState("");
	const [selectedType, setSelectedType] = useState("");
	const [userRole, setUserRole] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const [page, setPage] = useState(1);
	if (location.pathname === "/checkReports") {
		location.pathname = "گزارش اسناد";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadCustomers();
	}, [searchValue]);
	// columns
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "نام",
			dataIndex: "fullName",
			// filteredValue: [searching],
			// onFilter: (value, check) => {
			// 	return String(check.customer.fullName)
			// 		.toLocaleLowerCase()
			// 		.includes(value.toLocaleLowerCase());
			// },
			// render: (fullName, check) => check.customer.fullName,
			key: "fullName",
			align: "center",
		},
		{
			title: "شماره سند",
			dataIndex: "checkNumber",
			// dataIndex: ["checkInNumber", "checkOutNumber"],
			key: "checkNumber",
			align: "center",
		},
		{
			title: "نوعیت",
			dataIndex: "checkType",
			key: "checkType",
			align: "center",
		},
		{
			title: "مبلغ",
			dataIndex: "amount",
			key: "amount",
			align: "center",
		},
		{
			title: "تاریخ",
			dataIndex: "date",
			key: "date",
			align: "center",
		},
	];
	// function for fetch customers
	async function loadCustomers() {
		setIsLoader(true);
		try {
			const res = await client.query({
				query: CUSTOMERS,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: searchValue,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setCustomers([...res.data.getCustomers.customers]);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	// function for take report of checks
	const handleReportCheck = async (e) => {
		e.preventDefault();
		try {
			const res = await client.query({
				query: REPORTCHECK,
				variables: {
					checkType: selectedType !== "" ? selectedType : undefined,
					checkNumber:
						Number(e.target.checkNumber.value) !== ""
							? Number(e.target.checkNumber.value)
							: undefined,
					customer: selectedCustomerId !== "" ? selectedCustomerId : undefined,
					startAmount:
						Number(e.target.startAmount.value) !== ""
							? Number(e.target.startAmount.value)
							: undefined,
					endAmount:
						Number(e.target.endAmount.value) !== ""
							? Number(e.target.endAmount.value)
							: undefined,
					startDate:
						e.target.startDate.value !== "undefined-undefined-undefined"
							? e.target.startDate.value.replace(
									/[\u0660-\u0669\u06f0-\u06f9]/g,
									function (a) {
										return a.charCodeAt(0) & 0xf;
									}
							  )
							: undefined,
					endDate:
						e.target.endDate.value !== "undefined-undefined-undefined"
							? e.target.endDate.value.replace(
									/[\u0660-\u0669\u06f0-\u06f9]/g,
									function (a) {
										return a.charCodeAt(0) & 0xf;
									}
							  )
							: undefined,
				},
				fetchPolicy: "no-cache",
			});
			setReportChecks([...res.data.reportChecks]);
		} catch (error) {
			console.log(error);
		}
		setReportCheck(!reportCheck);
		setSelectedType();
		setSearching("");
		setSelectedCustomerId();
	};
	// checksReport data
	const data = reportChecks.map((check, index) => ({
		key: index,
		num: index + 1,
		id: check._id,
		fullName: check.customer.fullName,
		checkNumber: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{check.checkInNumber || check.checkOutNumber}
			</Tag>
		),
		checkType: (
			<Tag
				bordered={false}
				color={check.checkType == "Check_In" ? "success" : "error"}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{check.checkType == "Check_In" ? "دریافتی" : "پرداختی"}
			</Tag>
		),
		amount: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{check.amount}
			</Tag>
		),
		date: check.date,
	}));
	return (
		<div className="container">
			{/* check report modal */}
			{reportCheck && (
				<Modal
					title="گزارش سند"
					open={reportCheck}
					onCancel={() => {
						setReportCheck(!reportCheck);
						setSearching("");
						setSelectedCustomer();
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<form onSubmit={handleReportCheck}>
						<Space direction="vertical">
							{/* selectbox for search check type */}
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="نوعیت سند"
								className="w-full"
								name="checkType"
								onChange={(value) => {
									setSelectedType(value);
								}}
								size="large">
								<Option
									key={"Check_In"}
									value="Check_In"
									style={{ direction: "rtl" }}>
									دریافتی
								</Option>
								<Option
									key={"Check_Out"}
									value="Check_Out"
									style={{ direction: "rtl" }}>
									پرداختی
								</Option>
							</Select>
							<InputNumber
								id="checkNumber"
								name="checkNumber"
								size="large"
								placeholder="شماره چک"
								min={1}
								className="w-full"
							/>
							{/* selectbox for search customers */}
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="جستجوی مشتری"
								className="w-full"
								name="stack"
								onChange={(value) => {
									setSelectedCustomerId(value);
								}}
								onSearch={(value) => {
									setSearchValue(value);
									loadCustomers(value);
									setSelectedCustomerId(value);
								}}
								notFoundContent={
									isLoader ? (
										<Spin size="small" />
									) : (
										<Tag color="error" className="w-full text-center">
											موردی یافت نشد
										</Tag>
									)
								}
								size="large">
								{customers.map((customer) => (
									<Option
										key={customer._id}
										value={customer._id}
										style={{ direction: "rtl" }}>
										{customer.fullName +
											" " +
											customer.company +
											" " +
											customer.city}
									</Option>
								))}
							</Select>
							<Space direction="horizontal">
								<InputNumber
									id="startAmount"
									name="startAmount"
									size="large"
									placeholder="مبلغ سند"
									min={1}
									className="inputRep"
								/>
								<span>تا</span>
								<InputNumber
									id="endAmount"
									name="endAmount"
									size="large"
									placeholder="مبلغ سند"
									min={1}
									className="inputRep"
								/>
							</Space>
							<Space direction="horizontal">
								<DatePicker
									calendar={persian}
									locale={persian_fa}
									months={MONTHS}
									calendarPosition="top-right"
									format="YYYY-MM-DD"
									inputClass="inputDateRep"
									id="startDate"
									name="startDate"
									value={today}
								/>
								<span>تا</span>
								<DatePicker
									calendar={persian}
									locale={persian_fa}
									months={MONTHS}
									calendarPosition="top-right"
									format="YYYY-MM-DD"
									inputClass="inputDateRep"
									id="endDate"
									name="endDate"
									value={today}
								/>
							</Space>
							<Space
								style={{
									width: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Button
									type="primary"
									htmlType="submit"
									size="large"
									icon={<PlusOutlined />}
									className="btn">
									گزارش سند
								</Button>
							</Space>
						</Space>
					</form>
				</Modal>
			)}
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<HeaderContent>
					<Input.Search
						placeholder="جستجوی مشتری"
						allowClear
						size="large"
						onChange={(e) => {
							setSearching(e.target.value);
						}}
						style={{
							width: 250,
							direction: "ltr",
							textAlign: "right",
						}}
					/>
					<Button
						type="primary"
						size="large"
						icon={<PlusOutlined />}
						onClick={() => {
							setReportCheck(!reportCheck);
						}}
						className="btn">
						گزارش سند
					</Button>
					<Button
						type="primary"
						size="large"
						icon={<PrinterOutlined />}
						onClick={() =>
							navigate("/checkReportPrint", {
								state: { selectedCheckReport: reportChecks },
							})
						}
						className="btn">
						چاپ
					</Button>
				</HeaderContent>
				<TableComponent
					data={data}
					columns={columns}
					loading={isLoader}
					rowKey="id"
					pagination={{
						pageSize: 10, // Number of records per page
						// total: totalCount,
						onChange: (current) => {
							setPage(current); // Update page state
							handleReportCheck(current); // Fetch the new page data
						},
						current: page, // Track the current page
						showLessItems: true, // Optional: Keep pagination UI minimal
					}}
				/>
			</Content>
		</div>
	);
}
