import { useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { REMITTANCE } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import PdfComponent from "../Pdf/PdfComponent";
export function PrintRemittance() {
	const client = useApolloClient();
	const navigate = useNavigate();
	const [pages, setPages] = useState([]);
	const [isLoader, setIsLoader] = useState(false);
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		allRemittance();
	}, []);
	const allRemittance = async () => {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: REMITTANCE,
				variables: {
					pageNumber: 1,
					perPage: 10,
					searchItem: "",
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				const fetchedRemittances = res.data.getRemittances.remittances;
				console.log("fetchedRemittances", fetchedRemittances);
				// setRemittances(fetchedRemittances);
				const modifiedData = fetchedRemittances.map((rem, index) => ({
					col1: index + 1,
					col2: rem.number,
					col3: rem.type === "Card_to_card" ? "کارت به کارت" : "صرافی",
					col4: rem.customerName,
					col5: parseFloat(rem?.amount),
					col6: rem?.date,
				}));
				// Split data into pages
				const rowsPerPage = 15;
				const splitPages = splitDataIntoPages(modifiedData, rowsPerPage);
				setPages(splitPages);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	};
	const titles = [
		"#",
		"شماره کارت / حواله",
		"نوعیت حواله",
		"طرف حساب",
		"مبلغ",
		"تاریخ",
	];
	// Helper function to split data into pages
	const splitDataIntoPages = (data, rowsPerPage) => {
		if (!Array.isArray(data)) {
			console.error("Invalid input data for splitDataIntoPages:", data);
			return [];
		}

		const pages = [];
		for (let i = 0; i < data.length; i += rowsPerPage) {
			pages.push(data.slice(i, i + rowsPerPage));
		}

		console.log("Pages generated:", pages); // Debug output
		return pages;
	};
	return (
		<div className="conatiner">
			{isLoader && <Loader />}
			{/* Render PdfComponent only when pages data is available */}
			{!isLoader && pages.length > 0 && (
				<PdfComponent
					data={pages}
					titles={titles}
					pdfTitle="داروی انتخاب شده"
					orientation="landscape"
				/>
			)}
		</div>
	);
}
