import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { REMITTANCE } from "../../GraphQL/Query";
import {
	ADDREMITTANCE,
	DELETEREMITTANCE,
	UPDATEREMITTANCE,
} from "../../GraphQL/Mutation";
import {
	Button,
	Dropdown,
	Input,
	Space,
	Tag,
	InputNumber,
	Modal,
	Select,
	Popconfirm,
	notification,
	Spin,
} from "antd";
import {
	PlusOutlined,
	AlignRightOutlined,
	CaretDownOutlined,
	PrinterOutlined,
	EditOutlined,
	DeleteOutlined,
} from "@ant-design/icons";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import { MONTHS } from "../../Constant/const";
import Content from "../StaticComponents/Content";
import HeaderContent from "../StaticComponents/HeaderContent";
import TableComponent from "../StaticComponents/TableComponent";
const { Option } = Select;
export function Remittance() {
	const date = new DateObject({
		calendar: persian,
		locale: persian_fa,
		format: "YYYY-MM-DD",
	});
	const today = date.format();
	const client = useApolloClient();
	const navigate = useNavigate();
	const location = useLocation();
	const [remittances, setRemittances] = useState([]);
	const [filteredRemittance, setFilteredRemittance] = useState([]);
	const [searching, setSearching] = useState("");
	const [remittanceType, setRemittanceType] = useState("");
	const [newRemittance, setNewRemittance] = useState(false);
	const [editRemittance, setEditRemittance] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [referesh, setReferesh] = useState(false);
	const [userRole, setUserRole] = useState("");
	const [selectedType, setSelectedType] = useState("");
	const [storing, setStoring] = useState(false);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	if (location.pathname === "/remittance") {
		location.pathname = "بانک و صرافی";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		allRemittance();
	}, [referesh, page, searching, remittanceType]);
	// function for fetch remitances
	const allRemittance = async () => {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: REMITTANCE,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: searching,
					type: remittanceType === "" ? null : remittanceType,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setRemittances([...res.data.getRemittances.remittances]);
				setTotalCount(res.data.getRemittances.totalCount);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	};
	// function for add new remitance
	async function addNewRemittance(e) {
		e.preventDefault();
		setStoring(true);
		try {
			const res = await client.mutate({
				mutation: ADDREMITTANCE,
				variables: {
					number: e.target.havalaNumber.value,
					type: selectedType,
					amount: Number(e.target.amount.value),
					customerName: e.target.customerName.value,
					via: e.target.via.value,
					shopAddress: e.target.shopAddress.value,
					date: e.target.inputDate.value,
					description: e.target.description.value,
				},
			});
			if (res) {
				setNewRemittance(!newRemittance);
				setReferesh(!referesh);
				notification.success({
					message: "حواله با موفقیت اضافه گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در افزودن حواله",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	// function for delete remitance
	async function handleDeleteRemittance(remId) {
		try {
			setIsLoader(!isLoader);
			const res = await client.mutate({
				mutation: DELETEREMITTANCE,
				variables: {
					id: remId,
				},
			});
			if (res) {
				setReferesh(!referesh);
				notification.success({
					message: "حواله با موفقیت حذف گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (err) {
			console.log(err);
			notification.error({
				message: "خطا در حذف حواله",
				placement: "topRight",
				duration: 3,
			});
		}
	}
	// function for edit remitance
	async function handleEditRemittance(remId) {
		setEditRemittance(!editRemittance);
		const filterRemittance = remittances.filter((rem) => rem._id === remId);
		setFilteredRemittance(filterRemittance[0]);
		setSelectedType(filterRemittance[0].type);
	}
	// function for update remitance
	async function updateRemittance(e) {
		e.preventDefault();
		setStoring(true);
		const id = filteredRemittance._id;
		try {
			const res = await client.mutate({
				mutation: UPDATEREMITTANCE,
				variables: {
					remittanceId: id,
					number: e.target.havalaNumber.value,
					type: selectedType,
					amount: Number(e.target.amount.value),
					customerName: e.target.customerName.value,
					via: e.target.via.value,
					shopAddress: e.target.shopAddress.value,
					date: e.target.inputDate.value,
					description: e.target.description.value,
				},
			});
			if (res) {
				setEditRemittance(!editRemittance);
				setSelectedType("");
				setReferesh(!referesh);
				notification.success({
					message: "حواله با موفقیت ویرایش گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در ویرایش حواله",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	const items = [
		{
			key: "1",
			label: (
				<Button
					type="link"
					block="true"
					icon={<AlignRightOutlined />}
					onClick={() => {
						setRemittanceType("");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					همه
				</Button>
			),
		},
		{
			key: "2",
			label: (
				<Button
					type="link"
					block="true"
					icon={<AlignRightOutlined />}
					paddingBlock="0"
					onClick={() => {
						setRemittanceType("Card_to_card");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					کارت به کارت
				</Button>
			),
		},
		{
			key: "3",
			label: (
				<Button
					type="link"
					block="true"
					icon={<AlignRightOutlined />}
					onClick={() => {
						setRemittanceType("Exchange");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					صرافی
				</Button>
			),
		},
	];
	// columns
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "شماره کارت / حواله",
			dataIndex: "number",
			key: "number",
			// render: (number, rem) => rem.number,
			// filteredValue: [searching],
			// onFilter: (value, rem) => {
			// 	return (
			// 		String(rem.number)
			// 			.toLocaleLowerCase()
			// 			.includes(value.toLocaleLowerCase()) ||
			// 		String(rem.type).includes(value) ||
			// 		String(rem.customerName).includes(value)
			// 	);
			// },
			align: "center",
		},
		{
			title: "نوعیت حواله",
			dataIndex: "type",
			key: "type",
			align: "center",
		},
		{
			title: "طرف حساب",
			dataIndex: "customerName",
			key: "customerName",
			align: "center",
		},
		{
			title: "مبلغ",
			dataIndex: "amount",
			key: "amount",
			align: "center",
		},
		{
			title: "تاریخ",
			dataIndex: "date",
			key: "date",
			align: "center",
		},
		{
			title: userRole === "Admin" && "عملیات",
			dataIndex: "action",
			key: "action",
			render: (_, record) => {
				return (
					userRole === "Admin" && (
						<Space className="action-space">
							<EditOutlined onClick={() => handleEditRemittance(record?.id)} />
							<Popconfirm
								title="آیا مطمین هستید میخواهید حذف نمایید؟"
								onConfirm={() => handleDeleteRemittance(record?.id)}
								okText="بله"
								cancelText="انصراف"
								placement="topLeft">
								<DeleteOutlined />
							</Popconfirm>
						</Space>
					)
				);
			},
			align: "center",
		},
	];
	// remintances data
	const data = remittances.map((remetance, index) => ({
		key: index,
		num: index + 1,
		id: remetance._id,
		number: remetance.number,
		type: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{remetance.type === "Card_to_card" ? "کارت به کارت" : "صرافی"}
			</Tag>
		),
		customerName: remetance.customerName,
		amount: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{remetance.amount}
			</Tag>
		),
		date: remetance.date,
	}));
	return (
		<div className="container">
			{/* add new remitance */}
			{newRemittance && (
				<Modal
					title="ثبت حواله جدید"
					open={newRemittance}
					onCancel={() => {
						setNewRemittance(!newRemittance);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={addNewRemittance}>
							<Space direction="vertical">
								<Input
									id="havalaNumber"
									name="havalaNumber"
									size="large"
									placeholder="شماره کارت / حواله"
								/>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="نوعیت حواله"
									style={{ width: "100%", direction: "rtl" }}
									name="type"
									onChange={(value) => {
										setSelectedType(value);
									}}
									size="large">
									<Option
										key={"Exchange"}
										style={{ direction: "rtl" }}
										value="Exchange">
										صرافی
									</Option>
									<Option
										key={"Card_to_card"}
										style={{ direction: "rtl" }}
										value="Card_to_card">
										کارت به کارت
									</Option>
								</Select>
								<Input
									id="customerName"
									name="customerName"
									size="large"
									placeholder="طرف حساب"
								/>
								<Input
									id="via"
									name="via"
									size="large"
									placeholder="نام حواله کننده"
								/>
								<InputNumber
									id="amount"
									name="amount"
									size="large"
									placeholder="مبلغ حواله"
									min={0}
									style={{ width: "100%" }}
								/>
								<Input
									id="shopAddress"
									name="shopAddress"
									size="large"
									placeholder="آدرس حواله"
								/>
								<Space className="inputDate-container">
									<DatePicker
										calendar={persian}
										locale={persian_fa}
										months={MONTHS}
										calendarPosition="top-right"
										format="YYYY-MM-DD"
										inputClass="inputDate"
										value={today}
										id="inputDate"
										name="inputDate"
									/>
								</Space>
								<Input
									id="description"
									name="description"
									size="large"
									placeholder="توضیحات حواله"
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ثبت حواله
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			{/* edit exist remitance */}
			{editRemittance && (
				<Modal
					title="ویرایش حواله"
					open={editRemittance}
					onCancel={() => {
						setEditRemittance(!editRemittance);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={updateRemittance}>
							<Space direction="vertical">
								<Input
									id="havalaNumber"
									name="havalaNumber"
									size="large"
									placeholder="شماره کارت / حواله"
									defaultValue={filteredRemittance.number}
								/>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="نوعیت حواله"
									style={{ width: "100%", direction: "rtl" }}
									name="type"
									onChange={(value) => {
										setSelectedType(value);
									}}
									size="large"
									defaultValue={filteredRemittance.type}>
									<Option
										key={"Exchange"}
										style={{ direction: "rtl" }}
										value="Exchange">
										صرافی
									</Option>
									<Option
										key={"Card_to_card"}
										style={{ direction: "rtl" }}
										value="Card_to_card">
										کارت به کارت
									</Option>
								</Select>
								<Input
									id="customerName"
									name="customerName"
									size="large"
									placeholder="طرف حساب"
									defaultValue={filteredRemittance.customerName}
								/>
								<Input
									id="via"
									name="via"
									size="large"
									placeholder="نام حواله کننده"
									defaultValue={filteredRemittance.via}
								/>
								<InputNumber
									id="amount"
									name="amount"
									size="large"
									placeholder="مبلغ حواله"
									min={0}
									style={{ width: "100%" }}
									defaultValue={filteredRemittance.amount}
								/>
								<Input
									id="shopAddress"
									name="shopAddress"
									size="large"
									placeholder="آدرس حواله"
									defaultValue={filteredRemittance.shopAddress}
								/>
								<Space className="inputDate-container">
									<DatePicker
										calendar={persian}
										locale={persian_fa}
										months={MONTHS}
										calendarPosition="top-right"
										format="YYYY-MM-DD"
										inputClass="inputDate"
										value={filteredRemittance.date}
										id="inputDate"
										name="inputDate"
									/>
								</Space>
								<Input
									id="description"
									name="description"
									size="large"
									placeholder="توضیحات حواله"
									defaultValue={filteredRemittance.description}
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ویرایش حواله
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<HeaderContent>
					<Input.Search
						placeholder="جستجوی طرف حساب"
						allowClear
						size="large"
						onSearch={(value) => {
							setSearching(value);
						}}
						onChange={(e) => {
							setSearching(e.target.value);
						}}
						style={{
							width: 250,
							direction: "ltr",
							textAlign: "right",
						}}
					/>
					<Dropdown
						menu={{ items }}
						placement="bottom"
						arrow={{ pointAtCenter: true }}
						className="btn">
						<Button type="primary" size="large" icon={<CaretDownOutlined />}>
							انتخاب سند
						</Button>
					</Dropdown>
					{userRole === "Admin" && (
						<Button
							type="primary"
							size="large"
							icon={<PlusOutlined />}
							onClick={() => {
								setNewRemittance(!newRemittance);
							}}
							className="btn">
							افزودن حواله
						</Button>
					)}
					<Button
						type="primary"
						size="large"
						icon={<PrinterOutlined />}
						onClick={() => navigate("/printRemittance")}
						className="btn">
						چاپ
					</Button>
				</HeaderContent>
				<TableComponent
					data={data}
					columns={columns}
					loading={isLoader}
					rowKey="id"
					pagination={{
						pageSize: 10, // Number of records per page
						total: totalCount,
						onChange: (current) => {
							setPage(current); // Update page state
							allRemittance(current); // Fetch the new page data
						},
						current: page, // Track the current page
						showLessItems: true, // Optional: Keep pagination UI minimal
					}}
				/>
			</Content>
		</div>
	);
}
