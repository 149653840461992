import { Link } from "react-router-dom";
// import "../../Styles/StaticComponents/Card.css";
import { Loading } from "./Loading";
export function Card(props) {
	const cardTitle = props.cardTitle;
	const cardCount = props.cardCount;
	const cardIcon = props.cardIcon;
	const cardLoader = props.cardLoader;
	return (
		<div className="w-52 h-52 md:w-52 md:h-52 mt-2 mb-2 lg:w-60 lg:h-60 xl:w-64 xl:h64 rounded-xl shadow-lg flex flex-col justify-center gap-5 items-center cursor-pointer hover:scale-95 bg-white">
			<span className="text-lg font-bnazanin">{cardTitle}</span>
			<Link>{cardIcon}</Link>
			{cardLoader ? (
				<Loading />
			) : (
				<span className="text-md font-bold font-bnazanin">{cardCount}</span>
			)}
		</div>
	);
}
