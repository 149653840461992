import { gql } from "@apollo/client";

// users
export const USERS = gql`
	query getUsers($pageNumber: Int, $perPage: Int, $searchItem: String) {
		getUsers(
			pageNumber: $pageNumber
			perPage: $perPage
			searchItem: $searchItem
		) {
			users {
				_id
				firstName
				lastName
				userName
				password
				role
				createdAt
			}
			totalCount
		}
	}
`;
// stacks
export const STACKS = gql`
	query getStacks($pageNumber: Int, $perPage: Int, $searchItem: String) {
		getStacks(
			pageNumber: $pageNumber
			perPage: $perPage
			searchItem: $searchItem
		) {
			stacks {
				_id
				name
				type
				address
				createdAt
			}
			totalCount
		}
	}
`;
// remittance
export const REMITTANCE = gql`
	query getRemittances(
		$pageNumber: Int
		$perPage: Int
		$searchItem: String
		$type: ReittanceEnum
	) {
		getRemittances(
			pageNumber: $pageNumber
			perPage: $perPage
			searchItem: $searchItem
			type: $type
		) {
			remittances {
				_id
				number
				type
				amount
				customerName
				shopAddress
				via
				description
				date
				createdAt
			}
			totalCount
		}
	}
`;
// employees
export const EMPLOYEES = gql`
	query getEmployees($pageNumber: Int, $perPage: Int, $searchItem: String) {
		getEmployees(
			pageNumber: $pageNumber
			perPage: $perPage
			searchItem: $searchItem
		) {
			employees {
				_id
				fullName
				phoneNumber
				job
				contractDate
				workTime
				salary
				balance
				createdAt
			}
			totalCount
		}
	}
`;
// customers
export const CUSTOMERS = gql`
	query getCustomers($pageNumber: Int, $perPage: Int, $searchItem: String) {
		getCustomers(
			pageNumber: $pageNumber
			perPage: $perPage
			searchItem: $searchItem
		) {
			customers {
				_id
				fullName
				phoneNumber
				category
				city
				address
				company
				balance
				createdAt
			}
			totalCount
		}
	}
`;
// drugType
export const DRUGTYPES = gql`
	query getDrugTypes($pageNumber: Int, $perPage: Int, $searchItem: String) {
		getDrugTypes(
			pageNumber: $pageNumber
			perPage: $perPage
			searchItem: $searchItem
		) {
			drugTypes {
				_id
				title
				createdAt
			}
			totalCount
		}
	}
`;
// drugs
export const DRUGS = gql`
	query getDrugs($pageNumber: Int, $perPage: Int, $searchItem: String) {
		getDrugs(
			pageNumber: $pageNumber
			perPage: $perPage
			searchItem: $searchItem
		) {
			drugs {
				_id
				name
				drugType {
					_id
					title
				}
				company
				country
				amount
				price
				stack {
					_id
					name
					type
					address
				}
				expDate
				createdAt
			}
			totalCount
		}
	}
`;
// checks
export const CHECKS = gql`
	query getChecks(
		$pageNumber: Int
		$perPage: Int
		$searchItem: Int
		$checkType: CheckTypeEnum
	) {
		getChecks(
			pageNumber: $pageNumber
			perPage: $perPage
			searchItem: $searchItem
			checkType: $checkType
		) {
			checks {
				_id
				checkInNumber
				checkOutNumber
				checkType
				date
				amount
				description
				customer {
					_id
					fullName
				}
				createdAt
			}
			totalCount
		}
	}
`;
// check
export const CHECK = gql`
	query getCheck($id: ID!) {
		getCheck(id: $id) {
			_id
			checkInNumber
			checkOutNumber
			checkType
			date
			amount
			description
			customer {
				_id
				fullName
			}
		}
	}
`;
// factors
export const FACTORS = gql`
	query getFactors(
		$pageNumber: Int
		$perPage: Int
		$searchItem: Int
		$factorType: FactorTypeEnum
		$paymentType: PaymentTypeEnum
	) {
		getFactors(
			pageNumber: $pageNumber
			perPage: $perPage
			searchItem: $searchItem
			factorType: $factorType
			paymentType: $paymentType
		) {
			factors {
				_id
				buyFactorNumber
				sellFactorNumber
				factorType
				paymentType
				date
				amount
				discount
				description
				customer {
					_id
					fullName
				}
				createdAt
			}
			totalCount
		}
	}
`;
// factor
export const FACTOR = gql`
	query getFactor($id: ID!) {
		getFactor(id: $id) {
			_id
			buyFactorNumber
			sellFactorNumber
			factorType
			paymentType
			date
			amount
			description
			customer {
				_id
				fullName
			}
			items {
				buyFactorNumber
				sellFactorNumber
				drug {
					_id
					name
					drugType {
						_id
						title
					}
					company
				}
				quantity
				price
				total
				description
			}
		}
	}
`;
// lastFactor
export const LASTFACTOR = gql`
	query getLastFactor($factorType: FactorTypeEnum!) {
		getLastFactor(factorType: $factorType) {
			_id
			buyFactorNumber
			sellFactorNumber
			factorType
			paymentType
			date
			amount
			description
			customer {
				_id
				fullName
			}
			items {
				drug {
					_id
					name
					drugType {
						_id
						title
					}
					company
					country
				}
				quantity
				price
				total
				description
			}
		}
	}
`;
// roznamcha
export const ROZNAMCHA = gql`
	query getRoznamcha(
		$date: DateTime
		$pageNumber: Int
		$perPage: Int
		$searchItem: Int
		$bellType: BellType
	) {
		getRoznamcha(
			date: $date
			pageNumber: $pageNumber
			perPage: $perPage
			searchItem: $searchItem
			bellType: $bellType
		) {
			roznamchas {
				_id
				bellNumber
				bellType
				date
				amount
				customer {
					_id
					fullName
				}
				employee {
					_id
					fullName
				}
				user {
					_id
					firstName
					lastName
				}
			}
			totalCount
		}
	}
`;
// reports
// drugReport
export const REPORTDRUG = gql`
	query reportDrugs(
		$drugType: ID
		$drugName: String
		$drugCompany: String
		$drugCountry: String
		$drugStack: String
		$startAmount: Float
		$endAmount: Float
		$startPrice: Float
		$endPrice: Float
		$startDate: DateTime
		$endDate: DateTime
	) {
		reportDrugs(
			drugType: $drugType
			drugName: $drugName
			drugCompany: $drugCompany
			drugCountry: $drugCountry
			drugStack: $drugStack
			startAmount: $startAmount
			endAmount: $endAmount
			startPrice: $startPrice
			endPrice: $endPrice
			startDate: $startDate
			endDate: $endDate
		) {
			_id
			name
			drugType {
				_id
				title
			}
			company
			country
			amount
			price
			stack {
				_id
				name
				type
			}
			expDate
			createdAt
		}
	}
`;
export const REPORTCUSTOMER = gql`
	query reportCustomers(
		$fullName: String
		$balanceStatus: BalanceStatusEnum
		$city: String
		$address: String
		$startBalance: Float
		$endBalance: Float
		$category: String
	) {
		reportCustomers(
			fullName: $fullName
			balanceStatus: $balanceStatus
			city: $city
			address: $address
			startBalance: $startBalance
			endBalance: $endBalance
			category: $category
		) {
			_id
			fullName
			phoneNumber
			city
			address
			company
			balance
			category
			createdAt
		}
	}
`;
export const REPORTCHECK = gql`
	query reportChecks(
		$checkType: CheckTypeEnum
		$checkNumber: Int
		$startDate: DateTime
		$endDate: DateTime
		$startAmount: Float
		$endAmount: Float
		$customer: ID
	) {
		reportChecks(
			checkType: $checkType
			checkNumber: $checkNumber
			startDate: $startDate
			endDate: $endDate
			startAmount: $startAmount
			endAmount: $endAmount
			customer: $customer
		) {
			_id
			checkInNumber
			checkOutNumber
			checkType
			date
			amount
			description
			customer {
				_id
				fullName
			}
		}
	}
`;
export const REPORTFACTOR = gql`
	query reportFactors(
		$factorType: FactorTypeEnum
		$paymentType: PaymentTypeEnum
		$factorNumber: Int
		$customer: ID
		$drug: ID
		$startDate: DateTime
		$endDate: DateTime
		$startAmount: Float
		$endAmount: Float
	) {
		reportFactors(
			factorType: $factorType
			paymentType: $paymentType
			factorNumber: $factorNumber
			customer: $customer
			drug: $drug
			startDate: $startDate
			endDate: $endDate
			startAmount: $startAmount
			endAmount: $endAmount
		) {
			_id
			buyFactorNumber
			sellFactorNumber
			factorType
			paymentType
			date
			amount
			description
			customer {
				_id
				fullName
			}
		}
	}
`;
export const REPOSITORY = gql`
	query getRepository {
		getRepository
	}
`;
export const LASTCHEK = gql`
	query getLastCheck($checkType: CheckTypeEnum!) {
		getLastCheck(checkType: $checkType) {
			_id
			checkInNumber
			checkOutNumber
			checkType
			date
			amount
			description
			customer {
				_id
				fullName
			}
		}
	}
`;
export const STATISTIC = gql`
	query getStatistic {
		getStatistic {
			userCount
			stackCount
			drugCount
			customerCount
			checkCount
			factorCount
		}
	}
`;
export const DRUGDETAILS = gql`
	query getDrugDetails(
		$id: ID
		$searchItem: String
		$perPage: Int
		$pageNumber: Int
		$paymentType: PaymentTypeEnum
		$factorType: FactorTypeEnum
	) {
		getDrugDetails(
			id: $id
			searchItem: $searchItem
			perPage: $perPage
			pageNumber: $pageNumber
			paymentType: $paymentType
			factorType: $factorType
		) {
			factors {
				_id
				buyFactorNumber
				sellFactorNumber
				factorType
				paymentType
				date
				amount
				description
				customer {
					_id
					fullName
				}
				items {
					buyFactorNumber
					sellFactorNumber
					drug {
						_id
						name
						drugType {
							_id
							title
							createdAt
						}
						company
						country
						amount
						price
						stack {
							_id
							name
							type
							address
							createdAt
						}
						expDate
						createdAt
					}
					quantity
					price
					total
					description
				}
			}
			totalCount
		}
	}
`;
export const SALARIES = gql`
	query getSalaries(
		$employeeId: ID!
		$pageNumber: Int
		$perPage: Int
		$searchItem: Int
	) {
		getSalaries(
			employeeId: $employeeId
			pageNumber: $pageNumber
			perPage: $perPage
			searchItem: $searchItem
		) {
			salaries {
				_id
				employeeId {
					_id
					fullName
				}
				salaryNumber
				amount
				date
				description
				salaryType
			}
			totalCount
		}
	}
`;
export const EXPENSES = gql`
	query getConsumes($pageNumber: Int, $perPage: Int, $searchItem: String) {
		getConsumes(
			pageNumber: $pageNumber
			perPage: $perPage
			searchItem: $searchItem
		) {
			consumes {
				_id
				userId {
					_id
					firstName
					lastName
				}
				name
				consumeNumber
				amount
				date
				description
			}
			totalCount
		}
	}
`;
export const CUSTOMERDETAILS = gql`
	query getCustomerDetails(
		$id: ID!
		$paymentType: PaymentTypeEnum
		$factorType: FactorTypeEnum
		$checkType: CheckTypeEnum
	) {
		getCustomerDetails(
			id: $id
			paymentType: $paymentType
			factorType: $factorType
			checkType: $checkType
		) {
			_id
			checkInNumber
			checkOutNumber
			checkType
			buyFactorNumber
			sellFactorNumber
			factorType
			paymentType
			date
			amount
			description
			customer {
				_id
				fullName
				balance
			}
			customerBalance
		}
	}
`;
export const PROFILE = gql`
	query getProfile {
		getProfile {
			titleOne
			titleTwo
			owners
			phones
			address
			logo
			barcode
		}
	}
`;
