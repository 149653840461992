import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { EXPENSES } from "../../GraphQL/Query";
import { ADDEXPENSE, DELETEEXPENSE } from "../../GraphQL/Mutation";
import {
	Button,
	Input,
	Space,
	Tag,
	InputNumber,
	Modal,
	Popconfirm,
	notification,
	Spin,
} from "antd";
import {
	PlusOutlined,
	PrinterOutlined,
	DeleteOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarOfLife, faWallet } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import { MONTHS } from "../../Constant/const";
import Content from "../StaticComponents/Content";
import HeaderContent from "../StaticComponents/HeaderContent";
import TableComponent from "../StaticComponents/TableComponent";
export function Expenses() {
	const date = new DateObject({
		calendar: persian,
		locale: persian_fa,
		format: "YYYY-MM-DD",
	});
	const today = date.format();
	const navigate = useNavigate();
	const location = useLocation();
	const client = useApolloClient();
	const [expenses, setExpenses] = useState([]);
	const [searching, setSearching] = useState("");
	const [newExpense, setNewExpense] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [referesh, setReferesh] = useState(false);
	const [userRole, setUserRole] = useState("");
	const [userId, setUserId] = useState("");
	const [total, setTotal] = useState(false);
	const [storing, setStoring] = useState(false);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	if (location.pathname === "/expenses") {
		location.pathname = "مصارف";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		setUserId(JSON.parse(activeUser)?.login?._id);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadExpenses();
	}, [referesh, page, searching]);
	// Function to calculate the total amount
	const calculateTotalAmount = (expenses) => {
		return expenses.reduce((total, expense) => total + expense.amount, 0);
	};
	// totalAmount of Expenses
	const totalAmount = calculateTotalAmount(expenses);
	// columns
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "شماره",
			dataIndex: "consumeNumber",
			key: "consumeNumber",
			align: "center",
		},
		{
			title: "نام",
			dataIndex: "name",
			// filteredValue: [searching],
			// onFilter: (value, record) => {
			// 	return String(record.name)
			// 		.toLocaleLowerCase()
			// 		.includes(value.toLocaleLowerCase());
			// },
			// render: (name, expense) => expense.name,
			key: "name",
			align: "center",
		},
		{
			title: "مبلغ",
			dataIndex: "amount",
			key: "amount",
			align: "center",
		},
		{
			title: "توضیحات",
			dataIndex: "description",
			key: "description",
			align: "center",
		},
		{
			title: "تاریخ",
			dataIndex: "date",
			key: "date",
			align: "center",
		},
		{
			title: userRole === "Admin" && "عملیات",
			dataIndex: "action",
			key: "action",
			render: (_, record) => {
				return (
					userRole === "Admin" && (
						<Space>
							<Popconfirm
								title="آیا مطمین هستید میخواهید حذف نمایید؟"
								onConfirm={() => handleDeleteExpense(record.id)}
								okText="بله"
								cancelText="انصراف"
								placement="topLeft">
								<DeleteOutlined />
							</Popconfirm>
						</Space>
					)
				);
			},
			align: "center",
		},
	];
	// function for fetch expenses
	const loadExpenses = async () => {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: EXPENSES,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: searching,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setExpenses([...res.data.getConsumes.consumes]);
				setTotalCount(res.data.getConsumes.totalCount);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	};
	// function for add new expense
	async function addNewExpense(e) {
		e.preventDefault();
		setStoring(true);
		try {
			const res = await client.mutate({
				mutation: ADDEXPENSE,
				variables: {
					name: e.target.name.value,
					amount: Number(e.target.amount.value),
					date: e.target.inputDate.value.replace(
						/[\u0660-\u0669\u06f0-\u06f9]/g,
						function (a) {
							return a.charCodeAt(0) & 0xf;
						}
					),
					description: e.target.description.value,
					userId: userId,
				},
			});
			if (res) {
				setNewExpense(!newExpense);
				setReferesh(!referesh);
				notification.success({
					message: "مصرف با موفقیت اضافه گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در افزودن مصرف",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	// function for delete expense
	async function handleDeleteExpense(expenseId) {
		try {
			const res = await client.mutate({
				mutation: DELETEEXPENSE,
				variables: {
					id: expenseId,
				},
			});
			if (res) {
				setReferesh(!referesh);
				notification.success({
					message: "مصرف با موفقیت حذف گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (err) {
			console.log(err);
			notification.error({
				message: "خطا در حذف مصرف",
				placement: "topRight",
				duration: 3,
			});
		}
	}
	// expenses data
	const data = expenses.map((expense, index) => ({
		key: index,
		num: index + 1,
		id: expense._id,
		consumeNumber: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{expense.consumeNumber}
			</Tag>
		),
		name: expense.name,
		amount: (
			<Tag
				color="error"
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{expense.amount}
			</Tag>
		),
		description: expense.description,
		date: expense.date,
	}));
	return (
		<div className="container">
			{/* add new expense */}
			{newExpense && (
				<Modal
					title="ثبت مصرف"
					open={newExpense}
					onCancel={() => {
						setNewExpense(!newExpense);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={addNewExpense}>
							<Space direction="vertical">
								<Input
									id="name"
									name="name"
									size="large"
									placeholder="نام مصرف"
								/>
								<InputNumber
									placeholder="مبلغ مصرف"
									id="amount"
									name="amount"
									size="large"
									min={0}
									style={{
										width: "100%",
									}}
								/>
								<Space className="inputDate-container">
									<DatePicker
										calendar={persian}
										locale={persian_fa}
										months={MONTHS}
										calendarPosition="top-right"
										format="YYYY-MM-DD"
										inputClass="inputDate"
										value={today}
										id="inputDate"
										name="inputDate"
									/>
								</Space>
								<Input
									placeholder="توضیحات"
									id="description"
									name="description"
									size="large"
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ثبت مصرف
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<HeaderContent>
					<Input.Search
						placeholder="جستجوی مصرف"
						allowClear
						size="large"
						onChange={(e) => {
							setSearching(e.target.value);
						}}
						style={{
							width: 250,
							direction: "ltr",
							textAlign: "right",
						}}
					/>
					{userRole === "Admin" && (
						<Button
							type="primary"
							size="large"
							icon={<PlusOutlined />}
							onClick={() => {
								setNewExpense(!newExpense);
							}}
							className="btn">
							ثبت مصرف
						</Button>
					)}
					<Button
						type="primary"
						size="large"
						icon={<PrinterOutlined />}
						onClick={() => navigate("/printExpenses")}
						className="btn">
						چاپ
					</Button>
					{userRole === "Admin" && (
						<FontAwesomeIcon
							icon={faWallet}
							style={{ fontSize: 40, color: " #1677ff", cursor: "pointer" }}
							onClick={() => {
								setTotal(!total);
							}}
						/>
					)}
					{userRole === "Admin" &&
						(total ? (
							<Tag
								bordered={false}
								color="success"
								style={{
									fontSize: 20,
									fontFamily: "B-NAZANIN",
									width: "200px",
									height: "40px",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									textAlign: "center",
									color: "#777",
								}}>
								{
									<Space>
										{totalAmount === undefined
											? "در حال بررسی ..."
											: totalAmount + " " + "افغانی"}
									</Space>
								}
							</Tag>
						) : (
							<Tag
								bordered={false}
								color="success"
								style={{
									fontSize: 20,
									fontFamily: "B-NAZANIN",
									width: "200px",
									height: "40px",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									textAlign: "center",
								}}>
								{
									<Space>
										<FontAwesomeIcon
											icon={faStarOfLife}
											style={{ fontSize: 15, color: "#777" }}
										/>
										<FontAwesomeIcon
											icon={faStarOfLife}
											style={{ fontSize: 15, color: "#777" }}
										/>
										<FontAwesomeIcon
											icon={faStarOfLife}
											style={{ fontSize: 15, color: "#777" }}
										/>
										<FontAwesomeIcon
											icon={faStarOfLife}
											style={{ fontSize: 15, color: "#777" }}
										/>
										<FontAwesomeIcon
											icon={faStarOfLife}
											style={{ fontSize: 15, color: "#777" }}
										/>
									</Space>
								}
							</Tag>
						))}
				</HeaderContent>
				<TableComponent
					data={data}
					columns={columns}
					loading={isLoader}
					rowKey="id"
					pagination={{
						pageSize: 10, // Number of records per page
						total: totalCount,
						onChange: (current) => {
							setPage(current); // Update page state
							loadExpenses(current); // Fetch the new page data
						},
						current: page, // Track the current page
						showLessItems: true, // Optional: Keep pagination UI minimal
					}}
				/>
			</Content>
		</div>
	);
}
