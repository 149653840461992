import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { DRUGS } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import "../../Styles/StaticComponents/Global.css";
import PdfComponent from "../Pdf/PdfComponent";
export function PrintDrug() {
	const client = useApolloClient();
	const navigate = useNavigate();
	const location = useLocation();
	const receivedData = location.state?.printDrugType || {};
	console.log("receivedData", receivedData);
	const [pages, setPages] = useState([]);
	const [isLoader, setIsLoader] = useState(false);
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadDrugs();
	}, []);
	async function loadDrugs() {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: DRUGS,
				variables: {
					pageNumber: -1,
					perPage: -1,
					searchItem: "",
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				const fetchedDrugs = res.data.getDrugs.drugs;
				const filtredFetchedDrugs = fetchedDrugs
					.filter((item) =>
						receivedData === "existDrug"
							? item.amount > 0
							: receivedData === "allDrugs"
							? item.amount >= 0 || item.amount <= 0
							: receivedData === "prestListDrug"
							? true
							: null
					)
					.map(
						(item) =>
							receivedData === "prestListDrug"
								? { ...item, amount: " ", price: " " } // Set amount to space for prestListDrug
								: item // Keep the item unchanged for other conditions
					);
				const modifiedData = filtredFetchedDrugs.map((drug, index) => ({
					col1: index + 1,
					col2: drug?.drugType?.title,
					col3: drug?.name,
					col4: drug?.company,
					col5: drug?.amount,
					col6: drug?.price,
					col7:
						receivedData === "prestListDrug" ? " " : drug?.amount * drug?.price,
					col8: drug?.expDate,
				}));
				// Split data into pages
				const rowsPerPage = 15;
				const splitPages = splitDataIntoPages(modifiedData, rowsPerPage);
				setPages(splitPages);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	const titles = ["#", "نوعیت", "نام", "کمپنی", "تعداد", "قیمت", "کل", "تاریخ"];
	// Helper function to split data into pages
	const splitDataIntoPages = (data, rowsPerPage) => {
		if (!Array.isArray(data)) {
			console.error("Invalid input data for splitDataIntoPages:", data);
			return [];
		}

		const pages = [];
		for (let i = 0; i < data.length; i += rowsPerPage) {
			pages.push(data.slice(i, i + rowsPerPage));
		}

		console.log("Pages generated:", pages); // Debug output
		return pages;
	};
	return (
		<div className="conatiner">
			{isLoader && <Loader />}
			{!isLoader && pages.length > 0 && (
				<PdfComponent
					data={pages}
					titles={titles}
					pdfTitle="لیست داروها"
					orientation="landscape"
				/>
			)}
			{!isLoader && pages.length === 0 && (
				<div style={{ textAlign: "center", padding: "20px" }}>
					No data available for PDF generation.
				</div>
			)}
		</div>
	);
}
