import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../StaticComponents/Loader";
import { useApolloClient } from "@apollo/client";
import { FACTORS } from "../../GraphQL/Query";
import { DELETEFACTOR, UPDATEFACTOR } from "../../GraphQL/Mutation";
import {
	Button,
	Dropdown,
	Menu,
	Input,
	Table,
	Space,
	Tag,
	Popconfirm,
	Modal,
	notification,
} from "antd";
import {
	PlusOutlined,
	AlignRightOutlined,
	CaretDownOutlined,
	PrinterOutlined,
	EditOutlined,
	DeleteOutlined,
	EllipsisOutlined,
	SelectOutlined,
} from "@ant-design/icons";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import { MONTHS } from "../../Constant/const";
import Content from "../StaticComponents/Content";
import HeaderContent from "../StaticComponents/HeaderContent";
import TableComponent from "../StaticComponents/TableComponent";
export function FactorList() {
	const date = new DateObject({
		calendar: persian,
		locale: persian_fa,
		format: "YYYY-MM-DD",
	});
	const today = date.format();
	const client = useApolloClient();
	const navigate = useNavigate();
	const [factors, setFactors] = useState([]);
	const [selectedFactor, setSelectedFacor] = useState();
	const navigat = useNavigate();
	const location = useLocation();
	const [searching, setSearching] = useState(0);
	const [paymentType, setPaymentType] = useState("");
	const [factorType, setFactorType] = useState("");
	const [isLoader, setIsLoader] = useState(false);
	const [referesh, setReferesh] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [userRole, setUserRole] = useState("");
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	if (location.pathname === "/factorList") {
		location.pathname = "لیست فاکتورها";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadFactors();
	}, [referesh, searching, page, paymentType, factorType]);
	// function for fetch factors
	async function loadFactors() {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: FACTORS,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: Number(searching),
					paymentType: paymentType === "" ? null : paymentType,
					factorType: factorType === "" ? null : factorType,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setFactors([...res.data.getFactors.factors]);
				setTotalCount(res.data.getFactors.totalCount);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	// function for delete factor
	async function handleDeleteFactor(factorId) {
		try {
			const res = await client.mutate({
				mutation: DELETEFACTOR,
				variables: {
					id: factorId,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setReferesh(!referesh);
				notification.success({
					message: "فاکتور با موفقیت حذف گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (err) {
			console.log(err);
			notification.error({
				message: "خطا در حذف فاکتور",
				placement: "topRight",
				duration: 3,
			});
		}
	}
	// function for edit factor
	function handleEditFactor(factorId) {
		const filteredFactor = factors.filter((item) => item._id === factorId);
		setSelectedFacor(filteredFactor[0]);
		setIsEditing(!isEditing);
		console.log(filteredFactor[0]);
	}
	// function for update factor
	async function handleUpdateFactor(e) {
		e.preventDefault();
		try {
			const res = await client.mutate({
				mutation: UPDATEFACTOR,
				variables: {
					factorId: selectedFactor._id,
					date: e.target.inputDate.value.replace(
						/[\u0660-\u0669\u06f0-\u06f9]/g,
						function (a) {
							return a.charCodeAt(0) & 0xf;
						}
					),
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setIsEditing(!isEditing);
				setReferesh(!referesh);
				notification.success({
					message: "فاکتور با موفقیت ویرایش گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (err) {
			console.log(err);
			notification.error({
				message: "خطا در ویرایش فاکتور",
				placement: "topRight",
				duration: 3,
			});
		}
	}
	const filterPaymentType = [
		{
			key: "1",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					paddingBlock="0"
					onClick={() => {
						setPaymentType("");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					همه
				</Button>
			),
		},
		{
			key: "2",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					paddingBlock="0"
					onClick={() => {
						setPaymentType("No_Cash");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					نسیه
				</Button>
			),
		},
		{
			key: "3",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					onClick={() => {
						setPaymentType("Cash");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					نقده
				</Button>
			),
		},
	];
	const paymentTypes = (
		<Menu>
			{filterPaymentType.map((filterType) => (
				<Menu.Item key={filterType.key}>{filterType.label}</Menu.Item>
			))}
		</Menu>
	);
	const filterFactorType = [
		{
			key: "1",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					onClick={() => {
						setFactorType("");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					همه
				</Button>
			),
		},
		{
			key: "2",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					paddingBlock="0"
					onClick={() => {
						setFactorType("Buy");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					خرید
				</Button>
			),
		},
		{
			key: "3",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					onClick={() => {
						setFactorType("Sell");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					فروش
				</Button>
			),
		},
	];
	const factorTypes = (
		<Menu>
			{filterFactorType.map((filterPayment) => (
				<Menu.Item key={filterPayment.key}>{filterPayment.label}</Menu.Item>
			))}
		</Menu>
	);
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "نام",
			dataIndex: "customer",
			key: "customer",
			// filteredValue: [searching],
			// onFilter: (value, factor) => {
			// 	return (
			// 		String(factor.customer?.fullName)
			// 			.toLocaleLowerCase()
			// 			.includes(value.toLocaleLowerCase()) ||
			// 		String(factor.factorType).includes(value) ||
			// 		String(factor.paymentType).startsWith(value)
			// 	);
			// },
		},
		{
			title: "شماره",
			dataIndex: "factorNumber",
			key: "factorNumber",
			align: "center",
		},
		{
			title: "نوعیت بل",
			dataIndex: "factorType",
			key: "factorType",
			align: "center",
		},
		{
			title: "نوعیت پرداخت",
			dataIndex: "paymentType",
			key: "paymentType",
			align: "center",
		},
		{
			title: "مبلغ",
			dataIndex: "amount",
			key: "amount",
			align: "center",
		},
		{
			title: "تخفیف",
			dataIndex: "discount",
			key: "discount",
			align: "center",
		},
		{
			title: "تاریخ",
			dataIndex: "date",
			key: "date",
		},
		{
			title: userRole === "Admin" && "عملیات",
			dataIndex: "action",
			key: "action",
			render: (_, record) => {
				return (
					userRole === "Admin" && (
						<Space className="action-space">
							<PrinterOutlined
								onClick={() =>
									navigate("/printFactor", {
										state: { selectedFactor: record.id },
									})
								}
							/>
							{/* <EditOutlined onClick={() => handleEditFactor(record.id)} /> */}
							<Popconfirm
								title="آیا مطمین هستید میخواهید حذف نمایید؟"
								onConfirm={() => handleDeleteFactor(record.id)}
								okText="بله"
								cancelText="انصراف"
								placement="topLeft">
								<DeleteOutlined />
							</Popconfirm>
						</Space>
					)
				);
			},
			align: "center",
		},
	];
	// facotors data
	const data = factors.map((factor, index) => ({
		key: index,
		num: index + 1,
		id: factor._id,
		customer: factor.customer?.fullName,
		factorNumber: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{factor.buyFactorNumber || factor.sellFactorNumber}
			</Tag>
		),
		factorType: (
			<Tag
				bordered={false}
				color={factor.factorType === "Sell" ? "success" : "error"}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{factor.factorType === "Sell" ? "فروش" : "خرید"}
			</Tag>
		),
		paymentType: (
			<Tag
				bordered={false}
				color={factor.paymentType === "Cash" ? "success" : "error"}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{factor.paymentType === "Cash" ? "نقده" : "نسیه"}
			</Tag>
		),
		amount: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{factor.amount}
			</Tag>
		),
		discount: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{factor.discount}
			</Tag>
		),
		date: factor.date,
	}));
	return (
		<div className="container">
			<Modal
				title="ویرایش فاکتور"
				open={isEditing}
				onCancel={() => {
					setIsEditing(!isEditing);
				}}
				footer={null}
				style={{ direction: "rtl" }}
				centered>
				<form onSubmit={handleUpdateFactor}>
					<Space direction="vertical">
						<Space className="inputDate-container">
							<DatePicker
								calendar={persian}
								locale={persian_fa}
								months={MONTHS}
								calendarPosition="top-right"
								format="YYYY-MM-DD"
								inputClass="inputDate"
								value={selectedFactor?.date}
								id="inputDate"
								name="inputDate"
							/>
						</Space>
						<Space
							style={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Button
								type="primary"
								htmlType="submit"
								size="large"
								icon={<PlusOutlined />}
								className="btn">
								ویرایش فاکتور
							</Button>
						</Space>
					</Space>
				</form>
			</Modal>
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<HeaderContent>
					<Input.Search
						placeholder="جستجوی مشتری"
						allowClear
						size="large"
						onSearch={(value) => {
							setSearching(value);
						}}
						onChange={(e) => {
							setSearching(e.target.value);
						}}
						style={{
							width: 250,
							direction: "ltr",
							textAlign: "right",
						}}
					/>
					<Dropdown
						overlay={paymentTypes}
						placement="bottom"
						arrow={{ pointAtCenter: true }}
						className="btn">
						<Button type="primary" size="large" icon={<CaretDownOutlined />}>
							نوعیت پرداخت
						</Button>
					</Dropdown>
					<Dropdown
						overlay={factorTypes}
						placement="bottom"
						arrow={{ pointAtCenter: true }}
						className="btn">
						<Button type="primary" size="large" icon={<CaretDownOutlined />}>
							نوعیت فاکتور
						</Button>
					</Dropdown>
				</HeaderContent>
				<TableComponent
					data={data}
					columns={columns}
					loading={isLoader}
					rowKey="id"
					pagination={{
						pageSize: 10, // Number of records per page
						total: totalCount,
						onChange: (current) => {
							setPage(current); // Update page state
							loadFactors(current); // Fetch the new page data
						},
						current: page, // Track the current page
						showLessItems: true, // Optional: Keep pagination UI minimal
					}}
				/>
			</Content>
		</div>
	);
}
