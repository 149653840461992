import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PROVINCES } from "../../Constant/const";
import { useApolloClient } from "@apollo/client";
import { CUSTOMERS } from "../../GraphQL/Query";
import {
	ADDCUSTOMERS,
	DELETECUSTOMER,
	UPDATECUSTOMER,
} from "../../GraphQL/Mutation";
import {
	Button,
	Input,
	Space,
	Tag,
	Select,
	Popconfirm,
	notification,
	Spin,
} from "antd";
import {
	PlusOutlined,
	PrinterOutlined,
	EditOutlined,
	DeleteOutlined,
	EllipsisOutlined,
} from "@ant-design/icons";
import Modal from "antd/es/modal/Modal";
import Content from "../StaticComponents/Content";
import HeaderContent from "../StaticComponents/HeaderContent";
import TableComponent from "../StaticComponents/TableComponent";
const { Option } = Select;
export function Customers() {
	const navigate = useNavigate();
	const client = useApolloClient();
	const location = useLocation();
	const [filteredCustomer, setFilteredCustomer] = useState();
	const [searching, setSearching] = useState("");
	const [customers, setCustomers] = useState([]);
	const [newCustomer, setNewCustomer] = useState(false);
	const [editCustomer, setEditCustomer] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [referesh, setReferesh] = useState(false);
	const [selectedProvince, setSelectedProvince] = useState("");
	const [userRole, setUserRole] = useState("");
	const [storing, setStoring] = useState(false);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);

	if (location.pathname === "/customers") {
		location.pathname = "مشتریان";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadCustomers();
	}, [referesh, searching, page]);
	// columns
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "نام",
			dataIndex: "fullName",
			// filteredValue: [searching],
			// onFilter: (value, record) => {
			// 	return String(record.fullName)
			// 		.toLocaleLowerCase()
			// 		.includes(value.toLocaleLowerCase());
			// },
			key: "name",
			// align: "center",
		},
		{
			title: "کمپنی",
			dataIndex: "company",
			key: "company",
			align: "center",
		},
		{
			title: "آدرس",
			dataIndex: "address",
			key: "address",
			align: "center",
		},
		{
			title: "موبایل",
			dataIndex: "phoneNumber",
			key: "phoneNumber",
			align: "center",
		},
		{
			title: "کتگوری",
			dataIndex: "category",
			key: "category",
			align: "center",
		},
		{
			title: "ولایت",
			dataIndex: "city",
			key: "city",
			align: "center",
		},
		{
			title: "الباقی",
			dataIndex: "balance",
			key: "balance",
			align: "center",
		},
		{
			title: userRole === "Admin" && "عملیات",
			dataIndex: "action",
			key: "action",
			render: (_, record) => {
				return (
					userRole === "Admin" && (
						<Space className="action-space">
							<EllipsisOutlined
								onClick={() =>
									navigate("/selectedCustomer", {
										state: { customerId: record.id },
									})
								}
							/>
							<EditOutlined onClick={() => handleEditCustomer(record.id)} />
							<Popconfirm
								title="آیا مطمین هستید میخواهید حذف نمایید؟"
								onConfirm={() => handleDeleteCustomer(record.id)}
								okText="بله"
								cancelText="انصراف"
								placement="topLeft">
								<DeleteOutlined />
							</Popconfirm>
						</Space>
					)
				);
			},
			align: "center",
		},
	];
	// function for fetch customers
	async function loadCustomers() {
		setIsLoader(true);
		try {
			const res = await client.query({
				query: CUSTOMERS,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: searching,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setCustomers([...res.data.getCustomers.customers]);
				setTotalCount(res.data.getCustomers.totalCount);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	// function for add new customer
	async function addNewCustomer(e) {
		e.preventDefault();
		setStoring(true);
		try {
			const res = await client.mutate({
				mutation: ADDCUSTOMERS,
				variables: {
					fullName: e.target.customerName.value,
					company:
						e.target.customerCampany.value != ""
							? e.target.customerCampany.value
							: undefined,
					category:
						e.target.customerCatagory.value != ""
							? e.target.customerCatagory.value
							: undefined,
					address:
						e.target.customerAddress.value != ""
							? e.target.customerAddress.value
							: undefined,
					phoneNumber:
						e.target.customerPhone.value != ""
							? e.target.customerPhone.value
							: undefined,
					city: selectedProvince,
					balance: 0,
				},
			});
			if (res) {
				setNewCustomer(!newCustomer);
				setReferesh(!referesh);
				notification.success({
					message: "مشتری با موفقیت اضافه گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در افزودن مشتری",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	// function for delete customer
	async function handleDeleteCustomer(customerId) {
		try {
			const res = await client.mutate({
				mutation: DELETECUSTOMER,
				variables: {
					id: customerId,
				},
			});
			if (
				res.data.deleteCustomer.message ===
				"customer has been deleted successfully"
			) {
				setReferesh(!referesh);
				notification.success({
					message: "مشتری با موفقیت حذف گردید",
					placement: "topRight",
					duration: 3,
				});
			}
			if (
				res.data.deleteCustomer.message ===
				"please delete related factore befor delete customer "
			) {
				setReferesh(!referesh);
				notification.warning({
					message: "ابتدا فاکتور ها و اسناد مرتبط به مشتری را حذف نمایید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (err) {
			console.log(err);
			setIsLoader(false);
			notification.error({
				message: "خطا در حذف مشتری",
				placement: "topRight",
				duration: 3,
			});
		}
	}
	// function for edit customer
	function handleEditCustomer(customerId) {
		const filteredRows = customers.filter((item) => item._id === customerId);
		setFilteredCustomer(filteredRows[0]);
		setSelectedProvince(filteredRows[0].city);
		setEditCustomer(true);
	}
	// function for update customer
	async function handleUpdateCustomer(e) {
		e.preventDefault();
		setStoring(true);
		const id = filteredCustomer._id;
		try {
			const res = await client.mutate({
				mutation: UPDATECUSTOMER,
				variables: {
					customerId: id,
					fullName: e.target.customerName.value,
					company:
						e.target.customerCampany.value != ""
							? e.target.customerCampany.value
							: undefined,
					category:
						e.target.customerCatagory.value != ""
							? e.target.customerCatagory.value
							: undefined,
					address:
						e.target.customerAddress.value != ""
							? e.target.customerAddress.value
							: undefined,
					phoneNumber:
						e.target.customerPhone.value != ""
							? e.target.customerPhone.value
							: undefined,
					city: selectedProvince,
					balance: filteredCustomer.balance,
				},
			});
			if (res) {
				setEditCustomer(!editCustomer);
				setReferesh(!referesh);
				notification.success({
					message: "مشتری با موفقیت ویرایش گردید",
					placement: "topRight",
					duration: 3,
				});
				setSelectedProvince("");
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در ویرایش مشتری",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	// customers data
	const data = customers.map((customer, index) => ({
		key: index,
		num: index + 1,
		id: customer._id,
		fullName: customer.fullName,
		company: customer.company,
		address: customer.address,
		phoneNumber: customer.phoneNumber,
		category: customer.category,
		city: customer.city,
		balance: (
			<Tag
				bordered={false}
				color={
					customer.balance > 0
						? "error"
						: customer.balance === 0
						? ""
						: "success"
				}
				style={{
					fontSize: 15,
				}}>
				{
					<Space>
						{Math.abs(customer.balance)}
						{""}
						{customer.balance > 0
							? "بستانکار"
							: customer.balance === 0
							? "تسویه"
							: "بدهکار"}
					</Space>
				}
			</Tag>
		),
	}));
	return (
		<div className="container">
			{/* add new customer */}
			{newCustomer && (
				<Modal
					title="ثبت مشتری"
					open={newCustomer}
					onCancel={() => {
						setNewCustomer(!newCustomer);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={addNewCustomer}>
							<Space direction="vertical">
								<Input
									placeholder="نام مشتری"
									id="customerName"
									name="customerName"
									size="large"
								/>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="ولایت مشتری"
									style={{ width: "100%", direction: "rtl" }}
									name="customerProvince"
									onChange={(value) => {
										setSelectedProvince(value);
									}}
									size="large">
									{PROVINCES.map((province) => (
										<Option
											key={province}
											value={province}
											style={{ direction: "rtl" }}>
											{province}
										</Option>
									))}
								</Select>
								<Input
									placeholder="کتگوری مشتری"
									id="customerCatagory"
									name="customerCatagory"
									size="large"
								/>
								<Input
									placeholder="کمپنی مشتری"
									id="customerCampany"
									name="customerCampany"
									size="large"
								/>
								<Input
									placeholder="آدرس مشتری"
									id="customerAddress"
									name="customerAddress"
									size="large"
								/>
								<Input
									placeholder="موبایل مشتری"
									id="customerPhone"
									name="customerPhone"
									size="large"
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ثبت مشتری
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			{/* edit exist customer */}
			{editCustomer && (
				<Modal
					title="ویرایش مشتری"
					open={editCustomer}
					onCancel={() => {
						setEditCustomer(!editCustomer);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={handleUpdateCustomer}>
							<Space direction="vertical">
								<Input
									placeholder="نام مشتری"
									id="customerName"
									name="customerName"
									size="large"
									defaultValue={filteredCustomer?.fullName}
								/>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="ولایت مشتری"
									style={{ width: "100%", direction: "rtl" }}
									name="customerProvince"
									onChange={(value) => {
										setSelectedProvince(value);
									}}
									size="large"
									defaultValue={filteredCustomer?.city}>
									{PROVINCES.map((province) => (
										<Option
											key={province}
											value={province}
											style={{ direction: "rtl" }}>
											{province}
										</Option>
									))}
								</Select>
								<Input
									placeholder="کتگوری مشتری"
									id="customerCatagory"
									name="customerCatagory"
									size="large"
									defaultValue={filteredCustomer?.category}
								/>
								<Input
									placeholder="کمپنی مشتری"
									id="customerCampany"
									name="customerCampany"
									size="large"
									defaultValue={filteredCustomer?.company}
								/>
								<Input
									placeholder="آدرس مشتری"
									id="customerAddress"
									name="customerAddress"
									size="large"
									defaultValue={filteredCustomer?.address}
								/>
								<Input
									placeholder="موبایل مشتری"
									id="customerPhone"
									name="customerPhone"
									size="large"
									defaultValue={filteredCustomer?.phoneNumber}
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ویرایش مشتری
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<HeaderContent>
					<Input.Search
						placeholder="جستجوی مشتری"
						allowClear
						size="large"
						onChange={(e) => {
							setSearching(e.target.value);
						}}
						style={{
							width: 250,
							direction: "ltr",
							textAlign: "right",
						}}
					/>
					{userRole === "Admin" && (
						<Button
							type="primary"
							size="large"
							icon={<PlusOutlined />}
							onClick={() => {
								setNewCustomer(!newCustomer);
							}}
							className="btn">
							ثبت مشتری
						</Button>
					)}
					<Button
						type="primary"
						size="large"
						icon={<PrinterOutlined />}
						onClick={() => navigate("/printCustomer")}
						className="btn">
						چاپ مشتریان
					</Button>
				</HeaderContent>
				<TableComponent
					data={data}
					columns={columns}
					loading={isLoader}
					rowKey="id"
					pagination={{
						pageSize: 10, // Number of records per page
						total: totalCount,
						onChange: (current) => {
							setPage(current); // Update page state
							loadCustomers(current); // Fetch the new page data
						},
						current: page, // Track the current page
						showLessItems: true, // Optional: Keep pagination UI minimal
					}}
				/>
			</Content>
		</div>
	);
}
