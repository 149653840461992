import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Content from "./Content";
import techLand from "../../assets/techLand.png";
import { FaTelegram } from "react-icons/fa";
import { FaWhatsappSquare } from "react-icons/fa";
import { TfiWorld } from "react-icons/tfi";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
export function AboutUs() {
	const location = useLocation();
	const navigate = useNavigate();
	if (location.pathname === "/aboutUs") {
		location.pathname = "درباره ما";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
	});
	return (
		<div className="container">
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<div
					className="w-full h-full md:w-3/5 md:mx-auto flex flex-col gap-2 mt-7"
					dir="rtl">
					<div className="w-full flex flex-col justify-start items-center gap-1 bg-gray-200 rounded-md py-2">
						<span className="text-slate-900 font-bold text-lg">
							شرکت تکنالوژی تیک لند
						</span>
						<img src={techLand} alt="logo" className="w-32 h-32" />
					</div>
					<div className="w-full flex flex-col justify-start items-center gap-1 bg-gray-200 rounded-md py-2">
						{/* <img src={about} alt="logo" className="w-11/12 h-11/12" /> */}
						<span className="text-slate-900 font-bold text-lg">
							معرفی تیک لند
						</span>
						<p className="w-11/12 text-justify text-sm md:text-base">
							دنیا سراسر شگفتی هست و ما با تفاوتی که ایجاد میکنیم میتوانیم به
							این شگفتی ها بیفزاییم ! تیک لند (TechLand) اینک با تیمی فوق العاده
							خبره با انبوهی از تجربه در طراحی و مدیریت ویبسایت، اپلکشن موبایل
							(اندروید و آیفون)، دیتابس و سیستم های مدیریتی قدم به خلق سیستم های
							متفاوت گذاشته و میتواند مطلوب ترین گزینه برای تعریف متفاوت شما در
							بازار کار و مدیریت باشد. شما با ما میتوانید نوعی دیگر معرفی شوید و
							همیشه بروز باقی بمانید و ما از این لحاظ اطمنیان داریم که میتوانیم
							اطلاعات شما را به نوعی خاص متناسب با تکنولوژی های جدید مدیریت
							نماییم.
						</p>
					</div>
					<div className="w-full flex flex-col justify-start items-center gap-1 bg-gray-200 rounded-md py-2 mb-5">
						<span className="text-slate-900 font-bold text-lg">
							ارتباط با ما
						</span>
						<div className="flex flex-col justify-start gap-2">
							<div className="flex justify-start items-center gap-2 text-sm">
								<FaPhoneAlt />
								<span>0790883362</span>
								<span>-</span>
								<span>0796146514</span>
							</div>
							<div className="flex justify-start items-center gap-2 text-sm">
								<MdEmail />
								<span>technologyland01@gmail.com</span>
							</div>
							<div className="flex justify-center items-center gap-2 text-base mt-2 ">
								<a
									href="https://t.me/Mh_Yousuf"
									target="_blank"
									className="text-blue-500">
									<FaTelegram />
								</a>
								<a
									href="https://wa.me/93790883362"
									target="_blank"
									className="text-green-500">
									<FaWhatsappSquare />
								</a>
								<a
									href="https://www.techlands.online"
									target="_blank"
									className="text-red-500">
									<TfiWorld />
								</a>
							</div>
						</div>
					</div>
				</div>
			</Content>
		</div>
	);
}
