import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { DRUGS, DRUGTYPES, STACKS } from "../../GraphQL/Query";
import {
	Button,
	DatePicker,
	Dropdown,
	Input,
	Modal,
	Select,
	Space,
	Tag,
	Popconfirm,
	notification,
	Spin,
} from "antd";
import {
	PlusOutlined,
	CaretDownOutlined,
	PrinterOutlined,
	EditOutlined,
	DeleteOutlined,
	EllipsisOutlined,
} from "@ant-design/icons";
import { ADDDRUG, DELETEDRUG, UPDATEDRUG } from "../../GraphQL/Mutation";
import moment from "moment";
import Content from "../StaticComponents/Content";
import HeaderContent from "../StaticComponents/HeaderContent";
import TableComponent from "../StaticComponents/TableComponent";
const { Option } = Select;
export function Drugs() {
	const navigate = useNavigate();
	const location = useLocation();
	const client = useApolloClient();
	const [filteredDrugs, setFilteredDrugs] = useState([]);
	const [drugs, setDrugs] = useState([]);
	const [stacks, setStacks] = useState([]);
	const [drugTypes, setDrugTypes] = useState([]);
	const [searching, setSearching] = useState("");
	const [addDrugs, setAddDrug] = useState(false);
	const [editDrug, setEditDrug] = useState(false);
	const [referesh, setReferesh] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [userRole, setUserRole] = useState("");
	const [selectedStack, setSelectedStack] = useState("");
	const [selectedDrugType, setSelectedDrugType] = useState("");
	const [storing, setStoring] = useState(false);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [searchValue, setSearchValue] = useState("");
	if (location.pathname === "/drugs") {
		location.pathname = "داروها";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadDrugs();
		loadDrugTypes();
		loadStacks();
	}, [referesh, page, searching, searchValue]);
	// columns
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "نوعیت",
			dataIndex: "drugType",
			key: "drugType",
		},
		{
			title: "نام",
			dataIndex: "name",
			// filteredValue: [searching],
			// onFilter: (value, record) => {
			// 	return String(record.name)
			// 		.toLocaleLowerCase()
			// 		.includes(value.toLocaleLowerCase());
			// },
			key: "name",
		},
		{
			title: "کمپنی",
			dataIndex: "company",
			key: "company",
		},
		{
			title: "کشور",
			dataIndex: "country",
			key: "country",
		},
		{
			title: "تعداد",
			dataIndex: "amount",
			key: "amount",
			align: "center",
		},
		{
			title: "قیمت",
			dataIndex: "price",
			key: "price",
			align: "center",
		},
		{
			title: "تاریخ",
			dataIndex: "expDate",
			key: "expDate",
		},
		{
			title: userRole === "Admin" && "عملیات",
			dataIndex: "action",
			key: "action",
			render: (_, record) => {
				return (
					userRole === "Admin" && (
						<Space className="action-space">
							<EllipsisOutlined
								onClick={() =>
									navigate("/selectedDrug", {
										state: { drugId: record.id },
									})
								}
							/>
							<EditOutlined onClick={() => handleEditDrug(record.id)} />
							<Popconfirm
								title="آیا مطمین هستید میخواهید حذف نمایید؟"
								onConfirm={() => handleDelete(record.id)}
								okText="بله"
								cancelText="انصراف"
								placement="topLeft">
								<DeleteOutlined />
							</Popconfirm>
						</Space>
					)
				);
			},
			align: "center",
		},
	];

	// function for fetch drugs
	async function loadDrugs() {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: DRUGS,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: searching,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setDrugs([...res.data.getDrugs.drugs]);
				setTotalCount(res.data.getDrugs.totalCount);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	// function for fetch drugTypes
	async function loadDrugTypes() {
		try {
			const res = await client.query({
				query: DRUGTYPES,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: searchValue,
				},
				fetchPolicy: "no-cache",
			});
			setDrugTypes([...res.data.getDrugTypes.drugTypes]);
		} catch (error) {
			console.log(error);
		}
	}
	// function for fetch stacks
	async function loadStacks() {
		try {
			const res = await client.query({
				query: STACKS,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: searchValue,
				},
				fetchPolicy: "no-cache",
			});
			setStacks([...res.data.getStacks.stacks]);
		} catch (error) {
			console.log(error);
		}
	}
	// function for add new drug
	async function addNewDrug(e) {
		e.preventDefault();
		setStoring(true);
		try {
			const res = await client.mutate({
				mutation: ADDDRUG,
				variables: {
					name: e.target.drugName.value,
					drugType: selectedDrugType,
					stack: selectedStack,
					company: e.target.drugCompany.value,
					country: e.target.drugCountry.value,
					expDate: e.target.drugExpDate.value,
					amount: 0,
					price: 0,
				},
			});
			if (res) {
				setAddDrug(false);
				setReferesh(!referesh);
				notification.success({
					message: "دارو با موفقیت اضافه گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در افزودن دارو",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	// function for delete drug
	async function handleDelete(drugId) {
		try {
			const res = await client.mutate({
				mutation: DELETEDRUG,
				variables: {
					id: drugId,
				},
			});
			if (
				res.data.deleteDrug.message === "Drug has been deleted successfully"
			) {
				notification.success({
					message: "دارو با موفقیت حذف گردید",
					placement: "topRight",
					duration: 3,
				});
				setReferesh(!referesh);
			}
			if (
				res.data.deleteDrug.message ===
				"please delete related factor before delete drug "
			) {
				setReferesh(!referesh);
				notification.warning({
					message: "ابتدا فاکتور های ثبت شده این دارو رو حذف نمایید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (err) {
			console.log(err);
			notification.error({
				message: "خطا در حذف دارو",
				placement: "topRight",
				duration: 3,
			});
		}
	}
	// function for edit drug
	function handleEditDrug(drugId) {
		const filteredRows = drugs.filter((item) => item._id === drugId);
		setFilteredDrugs(filteredRows[0]);
		setSelectedDrugType(filteredRows[0]?.drugType?._id);
		setSelectedStack(filteredRows[0]?.stack?._id);
		setEditDrug(true);
		console.log(filteredRows[0]);
	}
	// function for update drug
	async function handleUpdateDrug(e) {
		e.preventDefault();
		setStoring(true);
		const id = filteredDrugs._id;
		try {
			const res = await client.mutate({
				mutation: UPDATEDRUG,
				variables: {
					drugId: id,
					name: e.target.drugName.value,
					drugType: selectedDrugType,
					stack: selectedStack,
					company: e.target.drugCompany.value,
					country: e.target.drugCountry.value,
					expDate: e.target.drugExpDate.value,
					amount: filteredDrugs.amount,
					price: filteredDrugs.price,
				},
			});
			if (res) {
				setEditDrug(!editDrug);
				setSelectedDrugType("");
				setSelectedStack("");
				setReferesh(!referesh);
				notification.success({
					message: "دارو با موفقیت ویرایش گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در ویرایش دارو",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	// items
	const items = [
		{
			key: "1",
			label: (
				<Button
					type="link"
					block="true"
					icon={<PrinterOutlined />}
					paddingBlock="0"
					onClick={() => {
						navigate("/printDrug", {
							state: { printDrugType: "existDrug" },
						});
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					لیست داروهای موجود
				</Button>
			),
		},
		{
			key: "2",
			label: (
				<Button
					type="link"
					block="true"
					icon={<PrinterOutlined />}
					onClick={() => {
						navigate("/printDrug", {
							state: { printDrugType: "allDrugs" },
						});
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					لیست تمام داروها
				</Button>
			),
		},
		{
			key: "3",
			label: (
				<Button
					type="link"
					block="true"
					icon={<PrinterOutlined />}
					onClick={() => {
						navigate("/printDrug", {
							state: { printDrugType: "prestListDrug" },
						});
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					پرست لیست
				</Button>
			),
		},
	];
	// drugs data
	const data = drugs.map((drug, index) => ({
		key: index,
		num: index + 1,
		id: drug._id,
		drugType: drug.drugType.title,
		name: drug.name,
		company: drug.company,
		country: drug.country,
		amount: (
			<Tag
				bordered={false}
				color={drug.amount > 0 ? "success" : drug.amount === 0 ? "" : "error"}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
					direction: "ltr",
				}}>
				{drug.amount}
			</Tag>
		),
		price: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{drug.price}
			</Tag>
		),
		expDate: drug.expDate,
	}));
	return (
		<div className="container">
			{/* add new drug */}
			{addDrugs && (
				<Modal
					title="ثبت دارو"
					open={addDrugs}
					onCancel={() => {
						setAddDrug(!addDrugs);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={addNewDrug}>
							<Space direction="vertical">
								<Input
									placeholder="نام دارو"
									id="drugName"
									name="drugName"
									size="large"
								/>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="نوعیت دارو"
									className="w-full"
									name="stack"
									onChange={(value) => {
										setSelectedDrugType(value);
									}}
									onSearch={(value) => {
										setSearchValue(value);
										loadDrugTypes(value);
										setSelectedDrugType(value);
									}}
									notFoundContent={
										isLoader ? (
											<Spin size="small" />
										) : (
											<Tag color="error" className="w-full text-center">
												موردی یافت نشد
											</Tag>
										)
									}
									size="large">
									{drugTypes.map((drugType) => (
										<Option
											key={drugType._id}
											value={drugType._id}
											style={{ direction: "rtl" }}>
											{drugType.title}
										</Option>
									))}
								</Select>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="گدام داروها"
									className="w-full"
									name="stack"
									onChange={(value) => {
										setSelectedStack(value);
									}}
									onSearch={(value) => {
										setSearchValue(value);
										loadStacks(value);
										setSelectedStack(value);
									}}
									notFoundContent={
										isLoader ? (
											<Spin size="small" />
										) : (
											<Tag color="error" className="w-full text-center">
												موردی یافت نشد
											</Tag>
										)
									}
									size="large">
									{stacks.map((stack) => (
										<Option
											key={stack._id}
											value={stack._id}
											style={{ direction: "rtl" }}>
											{stack.name}
										</Option>
									))}
								</Select>
								<Input
									placeholder="کمپنی دارو"
									id="drugCompany"
									name="drugCompany"
									size="large"
								/>
								<Input
									placeholder="کشور دارو"
									id="drugCountry"
									name="drugCountry"
									size="large"
								/>
								<DatePicker
									placeholder="تاریخ دارو"
									placement="topRight"
									id="drugExpDate"
									name="drugExpDate"
									size="large"
									style={{ width: "100%" }}
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ثبت دارو
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			{/* edit exist drug */}
			{editDrug && (
				<Modal
					title="ویرایش دارو"
					open={editDrug}
					onCancel={() => {
						setEditDrug(!editDrug);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={handleUpdateDrug}>
							<Space direction="vertical">
								<Input
									placeholder="نام دارو"
									id="drugName"
									name="drugName"
									size="large"
									defaultValue={filteredDrugs?.name}
								/>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="نوعیت دارو"
									className="w-full"
									name="stack"
									onChange={(value) => {
										setSelectedDrugType(value);
									}}
									onSearch={(value) => {
										setSearchValue(value);
										loadDrugTypes(value);
										setSelectedDrugType(value);
									}}
									notFoundContent={
										isLoader ? (
											<Spin size="small" />
										) : (
											<Tag color="error" className="w-full text-center">
												موردی یافت نشد
											</Tag>
										)
									}
									size="large"
									defaultValue={filteredDrugs?.drugType?.title}>
									{drugTypes.map((drugType) => (
										<Option
											key={drugType._id}
											value={drugType._id}
											style={{ direction: "rtl" }}>
											{drugType.title}
										</Option>
									))}
								</Select>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="گدام داروها"
									className="w-full"
									name="stack"
									onChange={(value) => {
										setSelectedStack(value);
									}}
									onSearch={(value) => {
										setSearchValue(value);
										loadStacks(value);
										setSelectedStack(value);
									}}
									notFoundContent={
										isLoader ? (
											<Spin size="small" />
										) : (
											<Tag color="error" className="w-full text-center">
												موردی یافت نشد
											</Tag>
										)
									}
									size="large"
									defaultValue={filteredDrugs?.stack?.name}>
									{stacks.map((stack) => (
										<Option
											key={stack._id}
											value={stack._id}
											style={{ direction: "rtl" }}>
											{stack.name}
										</Option>
									))}
								</Select>
								<Input
									placeholder="کمپنی دارو"
									id="drugCompany"
									name="drugCompany"
									size="large"
									defaultValue={filteredDrugs?.company}
								/>
								<Input
									placeholder="کشور دارو"
									id="drugCountry"
									name="drugCountry"
									size="large"
									defaultValue={filteredDrugs?.country}
								/>
								<DatePicker
									placeholder="تاریخ دارو"
									placement="topRight"
									id="drugExpDate"
									name="drugExpDate"
									size="large"
									style={{ width: "100%" }}
									defaultValue={moment(filteredDrugs?.expDate, "YYYY-MM-DD")}
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ویرایش دارو
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<HeaderContent>
					<Input.Search
						placeholder="جستجوی دارو"
						allowClear
						size="large"
						onChange={(e) => {
							setSearching(e.target.value);
						}}
						style={{
							width: 250,
							direction: "ltr",
							textAlign: "right",
						}}
					/>
					{userRole === "Admin" && (
						<Button
							type="primary"
							size="large"
							icon={<PlusOutlined />}
							onClick={() => {
								setAddDrug(!addDrugs);
							}}
							className="btn">
							ثبت دارو
						</Button>
					)}
					<Dropdown
						menu={{ items }}
						placement="bottom"
						arrow={{ pointAtCenter: true }}
						className="btn">
						<Button type="primary" size="large" icon={<CaretDownOutlined />}>
							چاپ داروها
						</Button>
					</Dropdown>
				</HeaderContent>
				<TableComponent
					data={data}
					columns={columns}
					loading={isLoader}
					rowKey="id"
					pagination={{
						pageSize: 10, // Number of records per page
						total: totalCount,
						onChange: (current) => {
							setPage(current); // Update page state
							loadDrugs(current); // Fetch the new page data
						},
						current: page, // Track the current page
						showLessItems: true, // Optional: Keep pagination UI minimal
					}}
				/>
			</Content>
		</div>
	);
}
