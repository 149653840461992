import { Link, useLocation, useNavigate } from "react-router-dom";
import { BACKUP } from "../../GraphQL/Mutation";
import screenfull from "screenfull";
import { useApolloClient } from "@apollo/client";
import axios from "axios";
import {
	PoweroffOutlined,
	ExpandOutlined,
	CloudDownloadOutlined,
} from "@ant-design/icons";
import { RxHamburgerMenu } from "react-icons/rx";
import {
	HomeFilled,
	FileTextFilled,
	AreaChartOutlined,
	BarChartOutlined,
	LineChartOutlined,
	StockOutlined,
	DotChartOutlined,
	SettingFilled,
	ExclamationCircleFilled,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBookOpen,
	faBuildingColumns,
	faClipboardList,
	faFileInvoiceDollar,
	faHandHoldingDollar,
	faLayerGroup,
	faMoneyCheckDollar,
	faPeopleGroup,
	faPills,
	faTablets,
	faUserTie,
	faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { DOMAIN_NAME } from "../../Constant/const";
import { Avatar, Menu, notification } from "antd";
export function Header(props) {
	const location = useLocation();
	const [newBackup, setNewBackUp] = useState();
	const address = props.address;
	const navigate = useNavigate();
	const client = useApolloClient();
	// function for fullscreen
	const toggleFullscreen = () => {
		if (screenfull.isEnabled) {
			screenfull.toggle();
		}
	};
	// function for backup
	async function handleBackup(e) {
		e.preventDefault();
		try {
			const res = await client.mutate({
				mutation: BACKUP,
				variables: {
					dbName: "oxygen",
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				notification.success({
					message: "بکاپ با موفقیت بارگیری شد",
					placement: "topRight",
					duration: 3,
				});
				axios
					.get(
						`https://${DOMAIN_NAME}-backend.techlands.online/download?path=${res.data.getBackup.message}`
					)
					.then((response) => {
						setNewBackUp(response.data);
						window.open(
							`https://${DOMAIN_NAME}-backend.techlands.online/download?path=${res.data.getBackup.message}`,
							"_blank"
						);
					})
					.catch((error) => console.error("Error fetching data:", error));
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در بارگیری بکاپ",
				placement: "topRight",
				duration: 3,
			});
		}
	}
	const items = [
		{
			label: "خانه",
			key: ["/home"],
			icon: <HomeFilled style={{ fontSize: 17 }} />,
		},
		{
			label: "نوعیت ها",
			key: "/drugsType",
			icon: <FontAwesomeIcon icon={faPills} style={{ fontSize: 17 }} />,
		},
		{
			label: "داروها",
			key: "/drugs",
			icon: <FontAwesomeIcon icon={faTablets} style={{ fontSize: 17 }} />,
		},
		{
			label: "فاکتورها",
			key: "/factors",
			icon: (
				<FontAwesomeIcon icon={faFileInvoiceDollar} style={{ fontSize: 17 }} />
			),
			className: "w-full",
			children: [
				{
					label: "فاکتور خرید",
					key: "/buyFactor",
					icon: <FileTextFilled style={{ fontSize: 17 }} />,
				},
				{
					label: "فاکتور فروش",
					key: "/saleFactor",
					icon: <FileTextFilled style={{ fontSize: 17 }} />,
				},
				{
					label: "لیست فاکتورها",
					key: "/factorList",
					icon: (
						<FontAwesomeIcon icon={faClipboardList} style={{ fontSize: 17 }} />
					),
				},
			],
		},
		{
			label: "مشتریان",
			key: "/customers",
			icon: <FontAwesomeIcon icon={faPeopleGroup} style={{ fontSize: 17 }} />,
		},
		{
			label: "گدام ها",
			key: "/stacks",
			icon: <FontAwesomeIcon icon={faLayerGroup} style={{ fontSize: 17 }} />,
		},
		{
			label: "اسناد",
			key: "/checks",
			icon: (
				<FontAwesomeIcon icon={faMoneyCheckDollar} style={{ fontSize: 17 }} />
			),
		},
		{
			label: "مصارف",
			key: "/expenses",
			icon: (
				<FontAwesomeIcon icon={faHandHoldingDollar} style={{ fontSize: 17 }} />
			),
		},
		{
			label: "روزنامچه",
			key: "/roznamcha",
			icon: <FontAwesomeIcon icon={faBookOpen} style={{ fontSize: 17 }} />,
		},
		{
			label: "گزارشات",
			key: "/reports",
			icon: <AreaChartOutlined style={{ fontSize: 17 }} />,
			className: "w-full",
			children: [
				{
					label: "گزارش داروها",
					key: "/drugReports",
					icon: <BarChartOutlined style={{ fontSize: 17 }} />,
				},
				{
					label: "گزارش فاکتورها",
					key: "/factorReports",
					icon: <DotChartOutlined style={{ fontSize: 17 }} />,
				},
				{
					label: "گزارش اسناد",
					key: "/checkReports",
					icon: <LineChartOutlined style={{ fontSize: 17 }} />,
				},
				{
					label: "گزارش مشتریان",
					key: "/customerReports",
					icon: <StockOutlined style={{ fontSize: 17 }} />,
				},
			],
		},
		{
			label: "کاربران",
			key: "/users",
			icon: <FontAwesomeIcon icon={faUsers} style={{ fontSize: 17 }} />,
		},
		{
			label: "کارمندان",
			key: "/employee",
			icon: <FontAwesomeIcon icon={faUserTie} style={{ fontSize: 17 }} />,
		},
		{
			label: "بانک ها",
			key: "/remittance",
			icon: (
				<FontAwesomeIcon icon={faBuildingColumns} style={{ fontSize: 17 }} />
			),
		},
		{
			label: "تنظیمات",
			key: "/settings",
			icon: <SettingFilled style={{ fontSize: 17 }} />,
		},
		{
			label: "درباره",
			key: "/aboutUs",
			icon: <ExclamationCircleFilled style={{ fontSize: 17 }} />,
		},
		{
			type: "divider",
		},
		{
			label: "خروج",
			key: "/login",
			icon: <PoweroffOutlined className="logout" title="خروج" size={17} />,
			className: "bg-red-100 lastItem focus:bg-red-200",
		},
	];
	// function for open | close Menu
	function handleMenu() {
		var x = document.getElementById("header");
		if (x.style.display === "none" || x.style.display === "") {
			x.style.display = "flex";
		} else {
			x.style.display = "none";
		}
	}
	return (
		<div className="w-full h-12 bg-white border-b flex justify-between items-center gap-3 px-4 relative md:fixed z-10">
			<Avatar className="bg-green-300 text-green-700 text-xl flex flex-col justify-center items-center w-8 h-8">
				U
			</Avatar>
			<h1 className="text-sm md:text-xl font-bold flex justify-center items-center gap-2">
				<span className="text-green-500 mt-1">{address}</span>
				سیستم مدیریتی دارویی اکسیجن
			</h1>
			<div className="hidden md:flex justify-start items-center gap-4">
				<ExpandOutlined
					onClick={toggleFullscreen}
					id="fullScreen"
					title="فول اسکرین"
					className="text-slate-900 text-xl hover:text-blue-400 w-8 h-8"
				/>
				<CloudDownloadOutlined
					title="بکاپ"
					onClick={handleBackup}
					className="text-slate-900 text-xl hover:text-blue-400 w-8 h-8"
				/>
				<Link to={"/home"}>
					<img src="pharmacy.png" alt="لوگو" className="w-10 h-10" />
				</Link>
			</div>
			<RxHamburgerMenu
				className="text-black text-2xl cursor-pointer md:hidden"
				onClick={handleMenu}
			/>
			<Menu
				id="header"
				className={
					location.pathname === "خانه"
						? "z-20 md:hidden hidden h-[280vh] text-end flex-col justify-start items-center gap-1 bg-white absolute left-0 top-12 w-full "
						: "z-20 md:hidden hidden h-fit text-end flex-col justify-start items-center gap-1 bg-white absolute left-0 top-12 w-full "
				}
				defaultSelectedKeys={["خانه"]}
				selectedKeys={[location.pathname]}
				// defaultOpenKeys={["sub1"]}
				mode="inline"
				items={items}
				onClick={(item) => {
					navigate(item.key);
					if (item.key === "/login") {
						window.localStorage.removeItem("activeUser");
						window.localStorage.removeItem("profile");
						navigate(item.key);
					}
				}}
			/>
		</div>
	);
}
