import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { USERROLL } from "../../Constant/const";
import { USERS } from "../../GraphQL/Query";
import { ADDUSER, DELETEUSER, UPDATEUSER } from "../../GraphQL/Mutation";
import {
	Button,
	Input,
	Space,
	Modal,
	Select,
	Popconfirm,
	notification,
	Spin,
} from "antd";
import {
	PlusOutlined,
	PrinterOutlined,
	EditOutlined,
	DeleteOutlined,
} from "@ant-design/icons";
import Content from "../StaticComponents/Content";
import HeaderContent from "../StaticComponents/HeaderContent";
import TableComponent from "../StaticComponents/TableComponent";
const { Option } = Select;
export function Users() {
	const navigate = useNavigate();
	const client = useApolloClient();
	const location = useLocation();
	const [filteredUser, setFilteredUser] = useState();
	const [users, setUsers] = useState([]);
	const [searching, setSearching] = useState("");
	const [newUser, setNewUser] = useState(false);
	const [editUser, setEditUser] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [referesh, setReferesh] = useState(false);
	const [userRole, setUserRole] = useState("");
	const [selectedUserRole, setSelectedUserRole] = useState("");
	const [storing, setStoring] = useState(false);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	if (location.pathname === "/users") {
		location.pathname = "کاربران";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadUsers();
	}, [referesh, page, searching]);
	// columns
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "نام",
			dataIndex: "firstName",
			// filteredValue: [searching],
			// onFilter: (value, record) => {
			// 	return String(record.firstName)
			// 		.toLocaleLowerCase()
			// 		.includes(value.toLocaleLowerCase());
			// },
			// render: (firstName, user) => user.firstName,
			key: "firstName",
			align: "center",
		},
		{
			title: "نام کاربری",
			dataIndex: "userName",
			key: "userName",
			align: "center",
		},
		{
			title: "پسورد",
			dataIndex: ["password", "role"],
			key: "password",
			render: (password, user, role) => {
				if (userRole === "Admin") {
					return user.password;
				} else {
					return "🔒🔏🔐🔓";
				}
			},
			align: "center",
		},
		{
			title: "صلاحیت",
			dataIndex: "role",
			key: "role",
			align: "center",
		},
		{
			title: userRole === "Admin" && "عملیات",
			dataIndex: "action",
			key: "action",
			render: (_, record) => {
				return (
					userRole === "Admin" && (
						<Space className="action-space">
							<EditOutlined onClick={() => handleEditUser(record?.id)} />
							<Popconfirm
								title="آیا مطمین هستید میخواهید حذف نمایید؟"
								onConfirm={() => handleDeleteUser(record?.id)}
								okText="بله"
								cancelText="انصراف"
								placement="topLeft">
								<DeleteOutlined />
							</Popconfirm>
						</Space>
					)
				);
			},
			align: "center",
		},
	];
	// function for fetch users
	const loadUsers = async () => {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: USERS,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: searching,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setUsers([...res.data.getUsers.users]);
				setTotalCount(res.data.getUsers.totalCount);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	};
	// function for add new user
	async function addNewUser(e) {
		e.preventDefault();
		setStoring(true);
		try {
			const res = await client.mutate({
				mutation: ADDUSER,
				variables: {
					firstName: e.target.firstName.value,
					lastName: e.target.lastName.value,
					password: e.target.userPassword.value,
					userName: e.target.userName.value,
					role: selectedUserRole,
				},
			});
			if (res) {
				setNewUser(!newUser);
				setReferesh(!referesh);
				notification.success({
					message: "کاربر با موفقیت اضافه گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در افزودن کاربر",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	// function for delete user
	async function handleDeleteUser(userId) {
		try {
			const res = await client.mutate({
				mutation: DELETEUSER,
				variables: {
					id: userId,
				},
			});
			if (res) {
				setReferesh(!referesh);
				notification.success({
					message: "کاربر با موفقیت حذف گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (err) {
			console.log(err);
			setIsLoader(false);
			notification.error({
				message: "خطا در حذف کاربر",
				placement: "topRight",
				duration: 3,
			});
		}
	}
	// function for edit user
	function handleEditUser(userId) {
		const filteredRows = users.filter((item) => item._id === userId);
		setFilteredUser(filteredRows[0]);
		setEditUser(true);
	}
	// function for update user
	async function handleUpdateUser(e) {
		e.preventDefault();
		setStoring(true);
		const id = filteredUser._id;
		try {
			const res = await client.mutate({
				mutation: UPDATEUSER,
				variables: {
					userId: id,
					firstName: e.target.firstName.value,
					lastName: e.target.lastName.value,
					password: e.target.userPassword.value,
					userName: e.target.userName.value,
					role: selectedUserRole,
				},
			});
			if (res) {
				setEditUser(!editUser);
				setReferesh(!referesh);
				notification.success({
					message: "کاربر با موفقیت ویرایش گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (err) {
			console.log(err);
			notification.error({
				message: "خطا در ویرایش کاربر",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	// users data
	const data = users.map((user, index) => ({
		key: index,
		num: index + 1,
		id: user._id,
		firstName: user.firstName + " " + user.lastName,
		userName: user.userName,
		password: user.password,
		role: user.role === "Admin" ? "ادمین" : "عادی",
	}));
	return (
		<div className="container">
			{/* add new user */}
			{newUser && (
				<Modal
					title="ثبت کاربر جدید"
					open={newUser}
					onCancel={() => {
						setNewUser(!newUser);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={addNewUser}>
							<Space direction="vertical">
								<Input
									id="firstName"
									name="firstName"
									size="large"
									placeholder="نام کاربر"
								/>
								<Input
									id="lastName"
									name="lastName"
									size="large"
									placeholder="فامیل کاربر"
								/>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="صلاحیت کاربر"
									style={{ width: "100%", direction: "rtl" }}
									name="userRoll"
									onChange={(value) => {
										setSelectedUserRole(value);
									}}
									size="large">
									<Option
										key={USERROLL.ADMIN}
										style={{ direction: "rtl" }}
										value={USERROLL.ADMIN}>
										ادمین
									</Option>
									<Option
										key={USERROLL.STANDARD}
										style={{ direction: "rtl" }}
										value={USERROLL.STANDARD}>
										استاندارد
									</Option>
								</Select>
								<Input
									placeholder="نام کاربری "
									id="userName"
									name="userName"
									size="large"
								/>
								<Input.Password
									placeholder="پسورد کاربر"
									id="userPassword"
									name="userPassword"
									size="large"
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ثبت کاربر
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			{/* edit exist user */}
			{editUser && (
				<Modal
					title="ویرایش کاربر"
					open={editUser}
					onCancel={() => {
						setEditUser(!editUser);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={handleUpdateUser}>
							<Space direction="vertical">
								<Input
									id="firstName"
									name="firstName"
									size="large"
									placeholder="نام کاربر"
									defaultValue={filteredUser?.firstName}
								/>
								<Input
									id="lastName"
									name="lastName"
									size="large"
									placeholder="فامیل کاربر"
									defaultValue={filteredUser?.lastName}
								/>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="صلاحیت کاربر"
									style={{ width: "100%", direction: "rtl" }}
									name="userRoll"
									onChange={(value) => {
										setSelectedUserRole(value);
									}}
									size="large"
									defaultValue={filteredUser?.role}>
									<Option
										key={USERROLL.ADMIN}
										style={{ direction: "rtl" }}
										value={USERROLL.ADMIN}>
										ادمین
									</Option>
									<Option
										key={USERROLL.STANDARD}
										style={{ direction: "rtl" }}
										value={USERROLL.STANDARD}>
										استاندارد
									</Option>
								</Select>
								<Input
									placeholder="نام کاربری "
									id="userName"
									name="userName"
									size="large"
									defaultValue={filteredUser?.userName}
								/>
								<Input.Password
									placeholder="پسورد کاربر"
									id="userPassword"
									name="userPassword"
									size="large"
									defaultValue={filteredUser?.password}
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ویرایش کاربر
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<HeaderContent>
					<Input.Search
						placeholder="جستجوی کاربر"
						allowClear
						size="large"
						onChange={(e) => {
							setSearching(e.target.value);
						}}
						style={{
							width: 250,
							direction: "ltr",
							textAlign: "right",
						}}
					/>
					{userRole == "Admin" && (
						<Button
							type="primary"
							size="large"
							icon={<PlusOutlined />}
							onClick={() => {
								setNewUser(!newUser);
							}}
							className="btn">
							ثبت کاربر
						</Button>
					)}

					<Button
						type="primary"
						size="large"
						icon={<PrinterOutlined />}
						onClick={() => navigate("/printUser")}
						className="btn">
						چاپ
					</Button>
				</HeaderContent>
				<TableComponent
					data={data}
					columns={columns}
					loading={isLoader}
					rowKey="id"
					pagination={{
						pageSize: 10, // Number of records per page
						total: totalCount,
						onChange: (current) => {
							setPage(current); // Update page state
							loadUsers(current); // Fetch the new page data
						},
						current: page, // Track the current page
						showLessItems: true, // Optional: Keep pagination UI minimal
					}}
				/>
			</Content>
		</div>
	);
}
