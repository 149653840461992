import { useLocation, useNavigate } from "react-router-dom";
import "../../Styles/Factors/PrintFactor.css";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { FACTOR, LASTFACTOR } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import {
	DOMAIN_NAME,
	BACKEND,
	SYSTEM_PHONE,
	SYSTEM_TITLE,
} from "../../Constant/const";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import PdfFactor from "../Pdf/PdfFactor";
export function PrintFactor() {
	const client = useApolloClient();
	const navigate = useNavigate();
	const location = useLocation();
	const receivedData =
		location.state?.factorType || location.state?.selectedFactor;
	const [factorData, setFactorData] = useState([]);
	const [factorHeaderData, setFactorHeaderData] = useState({});
	const [pages, setPages] = useState([]);
	const [referesh, setReferesh] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [profile, setProfile] = useState();
	const [pdfTitle, setPdfTitle] = useState("");
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		const activeProfile = window.localStorage.getItem("profile");
		setProfile(JSON.parse(activeProfile));
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		if (location.state?.factorType) {
			handleLastFactor();
		}
		if (location.state?.selectedFactor) {
			handlePrintFactor();
		}
	}, [referesh]);
	const handleLastFactor = async () => {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: LASTFACTOR,
				variables: {
					factorType: receivedData,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setIsLoader(false);
				const fetchedFactor = res?.data?.getLastFactor;
				const fetchedItems = res?.data?.getLastFactor?.items;
				const factorAmount = res?.data?.getLastFactor?.amount;
				const customer = res?.data?.getLastFactor?.customer?.fullName;
				const factorDate = res?.data?.getLastFactor?.date;
				const factorDescription = res?.data?.getLastFactor?.description;
				const factorType = res?.data?.getLastFactor?.factorType;
				const paymentType = res?.data?.getLastFactor?.paymentType;
				const factorNumber =
					res?.data?.getLastFactor?.sellFactorNumber ||
					res?.data?.getLastFactor?.buyFactorNumber;
				setFactorHeaderData({
					factorAmount,
					customer,
					factorDate,
					factorDescription,
					factorType,
					paymentType,
					factorNumber,
				});
				console.log("fetchedFactor", fetchedFactor);
				console.log("fetchedItems", fetchedItems);
				// setFactor(fetchedFactor);
				const modifiedData = fetchedItems.map((factor, index) => ({
					col1: index + 1,
					col2:
						factor?.drug?.drugType?.title +
						" " +
						factor?.drug?.name +
						" " +
						factor?.drug?.company,
					col3: parseFloat(factor?.quantity),
					col4: parseFloat(factor?.price),
					col5: parseFloat(factor?.total),
					col6: factor?.description,
				}));
				// Split data into pages
				const rowsPerPage = 15;
				const splitPages = splitDataIntoPages(modifiedData, rowsPerPage);
				setPages(splitPages);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	};
	const titles = ["#", "دارو", "تعداد", "فی", "جمع کل", "توضیحات"];
	// Helper function to split data into pages
	const splitDataIntoPages = (data, rowsPerPage) => {
		if (!Array.isArray(data)) {
			console.error("Invalid input data for splitDataIntoPages:", data);
			return [];
		}

		const pages = [];
		for (let i = 0; i < data.length; i += rowsPerPage) {
			pages.push(data.slice(i, i + rowsPerPage));
		}

		console.log("Pages generated:", pages); // Debug output
		return pages;
	};
	const handlePrintFactor = async () => {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: FACTOR,
				variables: {
					id: receivedData,
				},
			});
			if (res) {
				setIsLoader(false);
				const fetchedFactor = res?.data?.getFactor;
				const fetchedItems = res?.data?.getFactor?.items;
				const factorAmount = res?.data?.getFactor?.amount;
				const customer = res?.data?.getFactor?.customer?.fullName;
				const factorDate = res?.data?.getFactor?.date;
				const factorDescription = res?.data?.getFactor?.description;
				const factorType = res?.data?.getFactor?.factorType;
				const paymentType = res?.data?.getFactor?.paymentType;
				const factorNumber =
					res?.data?.getFactor?.sellFactorNumber ||
					res?.data?.getFactor?.buyFactorNumber;
				setFactorHeaderData({
					factorAmount,
					customer,
					factorDate,
					factorDescription,
					factorType,
					paymentType,
					factorNumber,
				});
				console.log("fetchedFactor", fetchedFactor);
				console.log("fetchedItems", fetchedItems);
				// setFactor(fetchedFactor);
				const modifiedData = fetchedItems.map((factor, index) => ({
					col1: index + 1,
					col2:
						factor?.drug?.drugType?.title +
						" " +
						factor?.drug?.name +
						" " +
						factor?.drug?.company,
					col3: parseFloat(factor?.quantity),
					col4: parseFloat(factor?.price),
					col5: parseFloat(factor?.total),
					col6: factor?.description,
				}));
				// Split data into pages
				const rowsPerPage = 15;
				const splitPages = splitDataIntoPages(modifiedData, rowsPerPage);
				setPages(splitPages);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	};
	return (
		<div className="w-full">
			{isLoader && <Loader />}
			{/* Render PdfComponent only when pages data is available */}
			{!isLoader && pages.length > 0 && (
				<PdfFactor
					data={pages}
					titles={titles}
					factorHeaderData={factorHeaderData}
				/>
			)}
			{/* <div className="printFactor-container">
				<div className="printFactor-header">
					<img
						src={
							profile?.logo
								? `https://${DOMAIN_NAME}${BACKEND}/images/${profile?.logo}`
								: "logo.png"
						}
						alt="logo"
						className="logo"
					/>
					<div className="header-title__container">
						<span className="header-title1">{profile?.titleOne}</span>
						<span className="header-title2">{profile?.titleTwo}</span>
					</div>
					<span
						className={
							factorData?.factorType == "Buy"
								? "factorType-buy"
								: "factorType-sell"
						}>
						{factorData?.factorType == "Buy" ? "خرید" : "فروش"}
					</span>
					<FontAwesomeIcon
						icon={faLeftLong}
						className="printHeader-icon"
						style={{
							fontSize: 17,
							cursor: "pointer",
							position: "absolute",
							left: 45,
						}}
						onClick={() => navigate(-1)}
					/>
				</div>
				<div className="printFactor-items">
					<div className="items-container">
						<span className="item-name item-name__first">محترم:</span>
						<span className="item-content item-content__first">
							{factorData?.customer?.fullName}{" "}
						</span>
					</div>
					<div className="items-container">
						<span className="item-name">تاریخ:</span>
						<span className="item-content">{factorData?.date}</span>
					</div>
					<div className="items-container">
						<span className="item-name">شماره:</span>
						<span className="item-content">
							{factorData?.buyFactorNumber || factorData?.sellFactorNumber}
						</span>
					</div>
				</div>
				<div className="printFactor-table">
					<div className="table-heading">
						<span className="table-heading-head">شماره</span>
						<span className="table-heading-head">نوع جنس</span>
						<span className="table-heading-head">تعداد</span>
						<span className="table-heading-head">قیمت فی</span>
						<span className="table-heading-head">قیمت کل</span>
					</div>
					{factorData?.items?.map((data, index) => {
						return (
							<div className="table-items" key={index}>
								<span className="table-item">{index + 1}</span>
								<span className="table-item">
									{data.drug.drugType.title +
										" " +
										data.drug.name +
										" " +
										data.drug.company}
								</span>
								<span className="table-item">{data.quantity}</span>
								<span className="table-item">{data.price}</span>
								<span className="table-item">{data.total}</span>
							</div>
						);
					})}
				</div>
				<div className="printFactor-footer">
					<div className="owner-description">
						<span className="factor-description">
							توضیحات: {factorData?.description}
						</span>
						<span className="owner-name">
							{profile?.owners[0] !== "" && (
								<span className="owner-name">
									{profile?.owners[0]} {profile?.phones[0] !== "" && ":"}{" "}
									{profile?.phones[0]}
									{profile?.phones[1] !== "" && "،"} {profile?.phones[1]}
									{profile?.phones[2] !== "" && "،"} {profile?.phones[2]}
								</span>
							)}
						</span>
						{profile?.owners[1] !== "" && (
							<span className="owner-name">
								{profile?.owners[1]} {profile?.phones[3] !== "" && ":"}{" "}
								{profile?.phones[3]}
								{profile?.phones[4] !== "" && "،"} {profile?.phones[4]}
								{profile?.phones[5] !== "" && "،"} {profile?.phones[5]}
							</span>
						)}
						<span className="owner-address">{profile?.address}</span>

						<img
							src={
								profile?.barcode
									? `https://${DOMAIN_NAME}${BACKEND}/images/${profile?.barcode}`
									: "customerBarcod.jpeg"
							}
							alt="barcode"
							className="barcode"
						/>
					</div>
					<div className="table-total__container">
						<div className="total-group">
							<span className="total-group__title">جمع کل</span>
							<span className="total-group__value">{factorData?.amount}</span>
						</div>
						<div className="total-group">
							<span className="total-group__title">رسید</span>
							<span className="total-group__value">
								{factorData?.paymentType == "No_Cash"
									? "0"
									: factorData?.amount}
							</span>
						</div>
						<div className="total-group">
							<span className="total-group__title">الباقی</span>
							<span className="total-group__value">
								{factorData?.paymentType == "No_Cash"
									? factorData?.amount
									: "0"}
							</span>
						</div>
					</div>
				</div>
				<div className="print-footer">
					<span>{SYSTEM_TITLE}</span>
					<span>تماس با ما: {SYSTEM_PHONE}</span>
				</div>
			</div> */}
		</div>
	);
}
