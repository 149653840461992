import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { STACKS } from "../../GraphQL/Query";
import { ADDSTACK, DELETESTACK, UPDATESTACK } from "../../GraphQL/Mutation";
import {
	Button,
	Input,
	Space,
	Modal,
	Popconfirm,
	notification,
	Spin,
} from "antd";
import {
	PlusOutlined,
	PrinterOutlined,
	EditOutlined,
	DeleteOutlined,
} from "@ant-design/icons";
import Content from "../StaticComponents/Content";
import HeaderContent from "../StaticComponents/HeaderContent";
import TableComponent from "../StaticComponents/TableComponent";
export function Stacks() {
	const navigate = useNavigate();
	const client = useApolloClient();
	const location = useLocation();
	const [referesh, setReferesh] = useState();
	const [filteredStack, setFilteredStack] = useState();
	const [searching, setSearching] = useState("");
	const [stacks, setStacks] = useState([]);
	const [newStack, setNewStack] = useState(false);
	const [editStack, setEditStack] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [userRole, setUserRole] = useState("");
	const [storing, setStoring] = useState(false);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	if (location.pathname === "/stacks") {
		location.pathname = "گدام ها";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadStacks();
	}, [referesh, page, searching]);
	// columns
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "نام",
			dataIndex: "name",
			// filteredValue: [searching],
			// onFilter: (value, record) => {
			// 	return String(record.name)
			// 		.toLocaleLowerCase()
			// 		.includes(value.toLocaleLowerCase());
			// },
			key: "name",
			align: "center",
		},
		{
			title: "نوعیت",
			dataIndex: "type",
			key: "type",
			align: "center",
		},
		{
			title: "آدرس",
			dataIndex: "address",
			key: "address",
			align: "center",
		},
		{
			title: userRole === "Admin" && "عملیات",
			dataIndex: "action",
			key: "action",
			render: (_, record) => {
				return (
					userRole === "Admin" && (
						<Space className="action-space">
							<EditOutlined onClick={() => handleEditStack(record?.id)} />
							<Popconfirm
								title="آیا مطمین هستید میخواهید حذف نمایید؟"
								onConfirm={() => handleDeletStack(record?.id)}
								okText="بله"
								cancelText="انصراف"
								placement="topLeft">
								<DeleteOutlined />
							</Popconfirm>
						</Space>
					)
				);
			},
			align: "center",
		},
	];
	// function for fetch stacks
	const loadStacks = async () => {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: STACKS,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: searching,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setStacks([...res.data.getStacks.stacks]);
				setTotalCount(res.data.getStacks.totalCount);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	};
	// function for add new stack
	async function addNewStack(e) {
		e.preventDefault();
		setStoring(true);
		try {
			const res = await client.mutate({
				mutation: ADDSTACK,
				variables: {
					name: e.target.stackName.value,
					type: e.target.stackType.value,
					address: e.target.stackAddress.value,
				},
			});
			if (res) {
				setNewStack(!newStack);
				setReferesh(!referesh);
				notification.success({
					message: "گدام با موفقیت اضافه گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در افزودن گدام",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	// function for delete stack
	async function handleDeletStack(stackId) {
		try {
			const res = await client.mutate({
				mutation: DELETESTACK,
				variables: {
					id: stackId,
				},
			});
			if (
				res.data.deleteStack.message === "Stack has been deleted successfully"
			) {
				setReferesh(!referesh);
				notification.success({
					message: "گدام با موفقیت حذف گردید",
					placement: "topRight",
					duration: 3,
				});
			}
			if (
				res.data.deleteStack.message ===
				"please delete related drugs before delete Stack"
			) {
				setReferesh(!referesh);
				notification.warning({
					message: "ابتدا دارو مرتبط به این گدام را حذف نمایید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (err) {
			console.log(err);
			notification.error({
				message: "خطا در حذف گدام",
				placement: "topRight",
				duration: 3,
			});
		}
	}
	// function for edit stack
	function handleEditStack(stackId) {
		const filteredRows = stacks.filter((item) => item._id === stackId);
		setFilteredStack(filteredRows[0]);
		setEditStack(true);
	}
	// function for update stack
	async function handleUpdateStack(e) {
		e.preventDefault();
		setStoring(true);
		try {
			const res = await client.mutate({
				mutation: UPDATESTACK,
				variables: {
					stackId: filteredStack._id,
					name: e.target.stackName.value,
					type: e.target.stackType.value,
					address: e.target.stackAddress.value,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setEditStack(!editStack);
				setReferesh(!referesh);
				notification.success({
					message: "گدام با موفقیت ویرایش گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در ویرایش گدام",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	// stacks data
	const data = stacks.map((stack, index) => ({
		key: index,
		num: index + 1,
		id: stack._id,
		name: stack.name,
		type: stack.type,
		address: stack.address,
	}));
	return (
		<div className="container">
			{/* add new stack */}
			{newStack && (
				<Modal
					title="ثبت گدام"
					open={newStack}
					onCancel={() => {
						setNewStack(!newStack);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={addNewStack}>
							<Space direction="vertical">
								<Input
									placeholder="نام گدام"
									id="stackName"
									name="stackName"
									size="large"
								/>
								<Input
									placeholder="نوعیت گدام"
									id="stackType"
									name="stackType"
									size="large"
								/>
								<Input
									placeholder="آدرس گدام"
									id="stackAddress"
									name="stackAddress"
									size="large"
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ثبت گدام
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			{/* edit exist stack */}
			{editStack && (
				<Modal
					title="ویرایش گدام"
					open={editStack}
					onCancel={() => {
						setEditStack(!editStack);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={handleUpdateStack}>
							<Space direction="vertical">
								<Input
									placeholder="نام گدام"
									id="stackName"
									name="stackName"
									size="large"
									defaultValue={filteredStack?.name}
								/>
								<Input
									placeholder="نوعیت گدام"
									id="stackType"
									name="stackType"
									size="large"
									defaultValue={filteredStack?.type}
								/>
								<Input
									placeholder="آدرس گدام"
									id="stackAddress"
									name="stackAddress"
									size="large"
									defaultValue={filteredStack?.address}
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ویرایش گدام
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<HeaderContent>
					<Input.Search
						placeholder="جستجوی گدام"
						allowClear
						size="large"
						onChange={(e) => {
							setSearching(e.target.value);
						}}
						style={{
							width: 250,
							direction: "ltr",
							textAlign: "right",
						}}
					/>
					{userRole === "Admin" && (
						<Button
							type="primary"
							size="large"
							icon={<PlusOutlined />}
							onClick={() => {
								setNewStack(!newStack);
							}}
							className="btn">
							ثبت گدام
						</Button>
					)}
					<Button
						type="primary"
						size="large"
						icon={<PrinterOutlined />}
						onClick={() => navigate("/printStack")}
						className="btn">
						چاپ
					</Button>
				</HeaderContent>
				<TableComponent
					data={data}
					columns={columns}
					loading={isLoader}
					rowKey="id"
					pagination={{
						pageSize: 10, // Number of records per page
						total: totalCount,
						onChange: (current) => {
							setPage(current); // Update page state
							loadStacks(current); // Fetch the new page data
						},
						current: page, // Track the current page
						showLessItems: true, // Optional: Keep pagination UI minimal
					}}
				/>
			</Content>
		</div>
	);
}
