import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { CUSTOMERS, REPORTCUSTOMER } from "../../GraphQL/Query";
import { PROVINCES } from "../../Constant/const";
import {
	Button,
	Input,
	Space,
	Tag,
	Select,
	Modal,
	InputNumber,
	Spin,
} from "antd";
import { PlusOutlined, PrinterOutlined } from "@ant-design/icons";
import Content from "../StaticComponents/Content";
import HeaderContent from "../StaticComponents/HeaderContent";
import TableComponent from "../StaticComponents/TableComponent";
const { Option } = Select;
export function CustomerReports() {
	const client = useApolloClient();
	const navigate = useNavigate();
	const location = useLocation();
	const [customers, setCustomers] = useState([]);
	const [reportCustomer, setReportCustomer] = useState(false);
	const [reportCustomers, setReportCustomers] = useState([]);
	const [searching, setSearching] = useState("");
	const [isLoader, setIsLoader] = useState(false);
	const [selectedCustomerId, setSelectedCustomerId] = useState("");
	const [selectedBalanceStatus, setSelectedBalanceStatus] = useState("");
	const [selectedProvince, setSelectedProvince] = useState("");
	const [page, setPage] = useState(1);
	const [searchValue, setSearchValue] = useState("");
	if (location.pathname === "/customerReports") {
		location.pathname = "گزارش مشتریان";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadCustomers();
	}, [searchValue]);
	// columns
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "نام",
			dataIndex: "fullName",
			// filteredValue: [searching],
			// onFilter: (value, record) => {
			// 	return String(record.fullName)
			// 		.toLocaleLowerCase()
			// 		.includes(value.toLocaleLowerCase());
			// },
			key: "fullName",
			align: "center",
		},
		{
			title: "کمپنی",
			dataIndex: "company",
			key: "company",
			align: "center",
		},
		{
			title: "آدرس",
			dataIndex: "address",
			key: "address",
			align: "center",
		},
		{
			title: "موبایل",
			dataIndex: "phoneNumber",
			key: "phoneNumber",
			align: "center",
		},
		{
			title: "کتگوری",
			dataIndex: "category",
			key: "category",
			align: "center",
		},
		{
			title: "ولایت",
			dataIndex: "city",
			key: "city",
			align: "center",
		},
		{
			title: "الباقی",
			dataIndex: "balance",
			key: "balance",
			align: "center",
		},
	];
	// function for fetch customers
	async function loadCustomers() {
		setIsLoader(true);
		try {
			const res = await client.query({
				query: CUSTOMERS,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: searchValue,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setCustomers([...res.data.getCustomers.customers]);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	// function for take report of cusomters
	const handleReportCustomer = async (e) => {
		e.preventDefault();
		setReportCustomer(!reportCustomer);
		try {
			const res = await client.query({
				query: REPORTCUSTOMER,
				variables: {
					fullName: selectedCustomerId !== "" ? selectedCustomerId : undefined,
					balanceStatus:
						selectedBalanceStatus !== "" ? selectedBalanceStatus : undefined,
					city: selectedProvince !== "" ? selectedProvince : undefined,
					category:
						e.target.category.value !== ""
							? e.target.category.value
							: undefined,
					address:
						e.target.address.value !== "" ? e.target.address.value : undefined,
					startBalance:
						Number(e.target.startBalance.value) !== ""
							? Number(e.target.startBalance.value)
							: undefined,
					endBalance:
						Number(e.target.endBalance.value) !== ""
							? Number(e.target.endBalance.value)
							: undefined,
				},
				fetchPolicy: "no-cache",
			});
			setReportCustomers([...res.data.reportCustomers]);
		} catch (error) {
			console.log(error);
		}
		setSelectedCustomerId();
		setSelectedBalanceStatus();
		setSelectedProvince();
	};
	// customersReport data
	const data = reportCustomers.map((customer, index) => ({
		key: index,
		num: index + 1,
		id: customer._id,
		fullName: customer.fullName,
		company: customer.company,
		address: customer.address,
		phoneNumber: customer.phoneNumber,
		category: customer.category,
		city: customer.city,
		balance: (
			<Tag
				bordered={false}
				color={
					customer.balance > 0
						? "error"
						: customer.balance === 0
						? ""
						: "success"
				}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{
					<Space>
						{Math.abs(customer.balance)}
						{""}
						{customer.balance > 0
							? "بستانکار"
							: customer.balance === 0
							? "تسویه"
							: "بدهکار"}
					</Space>
				}
			</Tag>
		),
	}));
	return (
		<div className="container">
			{/* customer report modal */}
			{reportCustomer && (
				<Modal
					title="گزارش مشتری"
					open={reportCustomer}
					onCancel={() => {
						setReportCustomer(!reportCustomer);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<form onSubmit={handleReportCustomer}>
						<Space direction="vertical">
							{/* selectbox for search customers */}
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="جستجوی مشتری"
								className="w-full"
								name="selectedCustomerId"
								onChange={(value) => {
									setSelectedCustomerId(value);
								}}
								onSearch={(value) => {
									setSearchValue(value);
									loadCustomers(value);
									setSelectedCustomerId(value);
								}}
								notFoundContent={
									isLoader ? (
										<Spin size="small" />
									) : (
										<Tag color="error" className="w-full text-center">
											موردی یافت نشد
										</Tag>
									)
								}
								size="large">
								{customers.map((customer) => (
									<Option
										key={customer._id}
										value={customer.fullName}
										style={{ direction: "rtl" }}>
										{customer.fullName +
											" " +
											customer.company +
											" " +
											customer.city}
									</Option>
								))}
							</Select>
							{/* selectbox for search balance status */}
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="وضعیت مشتری"
								style={{ width: "100%", direction: "rtl" }}
								name="balanceStatus"
								onChange={(value) => {
									setSelectedBalanceStatus(value);
								}}
								size="large">
								<Option
									key={"Negative"}
									value={"Negative"}
									style={{ direction: "rtl" }}>
									بدهکار
								</Option>
								<Option
									key={"Positive"}
									value={"Positive"}
									style={{ direction: "rtl" }}>
									بستانکار
								</Option>
								<Option
									key={"Zero"}
									value={"Zero"}
									style={{ direction: "rtl" }}>
									تسویه
								</Option>
							</Select>
							{/* selectbox for search city	 */}
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="انتخاب ولایت"
								className="w-full"
								name="city"
								onChange={(value) => {
									setSelectedProvince(value);
								}}
								size="large">
								{PROVINCES.map((province, index) => (
									<Option
										key={index}
										value={province}
										style={{ direction: "rtl" }}>
										{province}
									</Option>
								))}
							</Select>
							<Input
								id="category"
								name="category"
								size="large"
								placeholder="کتگوری مشتری"
							/>
							<Space direction="horizontal">
								<InputNumber
									id="startBalance"
									name="startBalance"
									size="large"
									placeholder="مبلغ مشتری"
									min={1}
									className="inputRep"
								/>
								<span>تا</span>
								<InputNumber
									id="endBalance"
									name="endBalance"
									size="large"
									placeholder="مبلغ مشتری"
									min={1}
									className="inputRep"
								/>
							</Space>
							<Input
								placeholder="آدرس مشتری"
								id="address"
								name="address"
								size="large"
							/>
							<Space
								style={{
									width: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Button
									type="primary"
									htmlType="submit"
									size="large"
									icon={<PlusOutlined />}
									className="btn">
									گزارش مشتری
								</Button>
							</Space>
						</Space>
					</form>
				</Modal>
			)}
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<HeaderContent>
					<Input.Search
						placeholder="جستجوی مشتری"
						allowClear
						size="large"
						onChange={(e) => {
							setSearching(e.target.value);
						}}
						style={{
							width: 250,
							direction: "ltr",
							textAlign: "right",
						}}
					/>
					<Button
						type="primary"
						size="large"
						icon={<PlusOutlined />}
						onClick={() => {
							setReportCustomer(!reportCustomer);
						}}
						className="btn">
						گزارش مشتری
					</Button>
					<Button
						type="primary"
						size="large"
						icon={<PrinterOutlined />}
						onClick={() =>
							navigate("/customerReportPrint", {
								state: { selectedCustomerReport: reportCustomers },
							})
						}
						className="btn">
						چاپ
					</Button>
				</HeaderContent>
				<TableComponent
					data={data}
					columns={columns}
					loading={isLoader}
					rowKey="id"
					pagination={{
						pageSize: 10, // Number of records per page
						// total: totalCount,
						onChange: (current) => {
							setPage(current); // Update page state
							loadCustomers(current); // Fetch the new page data
						},
						current: page, // Track the current page
						showLessItems: true, // Optional: Keep pagination UI minimal
					}}
				/>
			</Content>
		</div>
	);
}
