import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { DRUGTYPES } from "../../GraphQL/Query";
import {
	Button,
	Input,
	Modal,
	notification,
	Popconfirm,
	Space,
	Spin,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
	ADDDRUGTYPE,
	DELETEDRUGTYPE,
	UPDATEDRUGTYPE,
} from "../../GraphQL/Mutation";
import Content from "../StaticComponents/Content";
import HeaderContent from "../StaticComponents/HeaderContent";
import TableComponent from "../StaticComponents/TableComponent";
export function DrugsType() {
	const navigate = useNavigate();
	const location = useLocation();
	const client = useApolloClient();
	const [filteredDrugsTypes, setFilteredDrugsTypes] = useState([]);
	const [drugTypes, setDrugTypes] = useState([]);
	const [searching, setSearching] = useState("");
	const [editDrugType, setEditDrugType] = useState(false);
	const [addType, setAddType] = useState(false);
	const [referesh, setReferesh] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [userRole, setUserRole] = useState("");
	const [storing, setStoring] = useState(false);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	if (location.pathname === "/drugsType") {
		location.pathname = "نوعیت ها";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadDrugTypes();
	}, [referesh, page, searching]);
	// columns
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "نام",
			dataIndex: "title",
			key: "title",
			// filteredValue: [searching],
			// onFilter: (value, record) => {
			// 	return String(record.title)
			// 		.toLocaleLowerCase()
			// 		.includes(value.toLocaleLowerCase());
			// },
			// align: "center",
		},
		{
			title: userRole === "Admin" && "عملیات",
			dataIndex: "action",
			key: "action",
			render: (_, record) => {
				return (
					userRole === "Admin" && (
						<Space size="middle">
							<EditOutlined onClick={() => handleEditType(record.id)} />
							<Popconfirm
								title="آیا مطمین هستید میخواهید حذف نمایید؟"
								onConfirm={() => handleDeleteType(record.id)}
								okText="بله"
								cancelText="انصراف"
								placement="topLeft">
								<DeleteOutlined />
							</Popconfirm>
						</Space>
					)
				);
			},
			align: "center",
		},
	];
	// function for fetch drugType
	async function loadDrugTypes() {
		setIsLoader(true);
		try {
			const res = await client.query({
				query: DRUGTYPES,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: searching,
				},
				fetchPolicy: "no-cache",
			});
			setDrugTypes([...res.data.getDrugTypes.drugTypes]);
			setTotalCount(res.data.getDrugTypes.totalCount);
			setIsLoader(false);
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	// function for add new drugType
	async function addNewDrugType(e) {
		e.preventDefault();
		setStoring(true);
		try {
			const res = await client.mutate({
				mutation: ADDDRUGTYPE,
				variables: {
					title: e.target.drugTypeName.value,
				},
			});
			if (res) {
				setAddType(false);
				setReferesh(!referesh);
				notification.success({
					message: "نوعیت با موفقیت اضافه گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در افزودن نوعیت جدید",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	// function for delete drugType
	async function handleDeleteType(drugTypeId) {
		try {
			const res = await client.mutate({
				mutation: DELETEDRUGTYPE,
				variables: {
					id: drugTypeId,
				},
			});
			if (
				res.data.deleteDrugType.message ===
				"drugType has been deleted successfully"
			) {
				notification.success({
					message: "نوعیت با موفقیت حذف گردید",
					placement: "topRight",
					duration: 3,
				});
				setReferesh(!referesh);
			}
			if (
				res.data.deleteDrugType.message ===
				"please delete related drugs before delete drugType"
			) {
				setReferesh(!referesh);
				notification.warning({
					message: "ابتدا داروهای مرتبط به این نوعیت را پاک کنید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (err) {
			console.log(err);
			notification.error({
				message: "خطا در حذف نوعیت",
				placement: "topRight",
				duration: 3,
			});
		}
	}
	// function for edit drugType
	function handleEditType(drugTypeId) {
		const filteredRows = drugTypes.filter((item) => item._id === drugTypeId);
		setFilteredDrugsTypes(filteredRows[0]);
		setEditDrugType(true);
	}
	// function for update drugType
	async function handleUpdateDrugType(e) {
		e.preventDefault();
		setStoring(true);
		const id = filteredDrugsTypes._id;
		try {
			const res = await client.mutate({
				mutation: UPDATEDRUGTYPE,
				variables: {
					drugTypeId: id,
					title: e.target.drugTypeName.value,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setEditDrugType(!editDrugType);
				setReferesh(!referesh);
				notification.success({
					message: "نوعیت با موفقیت ویرایش گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در ویرایش نوعیت",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	// drugTypes data
	const data = drugTypes.map((drugType, index) => ({
		key: index,
		num: index + 1,
		id: drugType._id,
		title: drugType.title,
	}));
	return (
		<div>
			{/* add new drugTpye */}
			{addType && (
				<Modal
					title="نوعیت دارو"
					open={addType}
					onCancel={() => {
						setAddType(!addType);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={addNewDrugType}>
							<Space direction="vertical">
								<Input
									placeholder="نوعیت دارو"
									id="drugTypeName"
									name="drugTypeName"
									size="large"
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ثبت نوعیت
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			{/* edit exist drugType */}
			{editDrugType && (
				<Modal
					title="نوعیت نوعیت"
					open={editDrugType}
					onCancel={() => {
						setEditDrugType(!editDrugType);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={handleUpdateDrugType}>
							<Space direction="vertical">
								<Input
									placeholder="ویرایش نوعیت"
									id="drugTypeName"
									name="drugTypeName"
									size="large"
									defaultValue={filteredDrugsTypes.title}
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ویرایش نوعیت
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<HeaderContent>
					<Input.Search
						placeholder="جستجوی نوعیت"
						allowClear
						size="large"
						onChange={(e) => {
							setSearching(e.target.value);
						}}
						style={{
							width: 250,
							direction: "ltr",
							textAlign: "right",
						}}
					/>
					{userRole === "Admin" && (
						<Button
							type="primary"
							size="large"
							icon={<PlusOutlined />}
							onClick={() => {
								setAddType(!addType);
							}}
							className="btn">
							ثبت نوعیت
						</Button>
					)}
				</HeaderContent>
				<TableComponent
					data={data}
					columns={columns}
					loading={isLoader}
					rowKey="id"
					pagination={{
						pageSize: 10, // Number of records per page
						total: totalCount,
						onChange: (current) => {
							setPage(current); // Update page state
							loadDrugTypes(current); // Fetch the new page data
						},
						current: page, // Track the current page
						showLessItems: true, // Optional: Keep pagination UI minimal
					}}
				/>
			</Content>
		</div>
	);
}
