import React, { useEffect, useState } from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	PDFViewer,
	Font,
	Image,
} from "@react-pdf/renderer";

import font from "../../assets/IranSans.ttf";
import { Spin } from "antd";
// Create styles
const persianText = "متن فارسی";
const encodedText = encodeURIComponent(persianText);
<Text>{decodeURIComponent(encodedText)}</Text>;
Font.register({
	family: "IRANSans",
	format: "truetype",
	src: font,
});
// Step 3: Define Styles
const styles = StyleSheet.create({
	rtlText: {
		direction: "rtl", // Set text direction to Right-to-Left
	},
	page: {
		padding: 20,
		fontSize: 8,
		fontFamily: "IRANSans",
	},
	header: {
		width: "100%",
		// backgroundColor: "red",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 10,
		border: "1px solid #bdbdbd",
		borderRadius: 8,
		padding: 5,
	},
	headerTitle: {
		fontSize: 14,
		textAlign: "center",
		fontWeight: "bold",
		marginBottom: 2,
	},
	headerTitle2: {
		fontSize: 8,
		textAlign: "center",
		fontWeight: "bold",
	},
	table: {
		display: "table",
		width: "auto",
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#bdbdbd",
	},
	tableRow: {
		flexDirection: "row-reverse",
	},
	tableCol: {
		width: "100%",
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#bdbdbd",
		padding: 5,
		textAlign: "center",
		flexDirection: "row", // Ensure content flows in a single row
		overflow: "hidden", // Hide overflow
	},
	tableHeader: {
		fontWeight: "bold",
		backgroundColor: "#f3f3f3",
	},
	image: {
		width: 80, // Width of the image
		height: 80, // Height of the image
		alignSelf: "center", // Center the image
	},
	headerContent: {
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		gap: 2,
	},
	owner: {
		fontSize: 8,
		flexDirection: "row-reverse",
		gap: 2,
	},
	address: {
		fontSize: 8,
	},
	footer: {
		position: "absolute",
		bottom: 10, // Adjust to your preference
		left: 0,
		right: 0,
		textAlign: "center",
		fontSize: 10,
		color: "gray",
	},
});

const Header = ({ profile }) => (
	<View style={styles.header}>
		<Image style={styles.image} src="logo.png" />
		<View style={styles.headerContent}>
			<Text style={styles.headerTitle}>{profile?.titleOne}</Text>
			<Text style={styles.headerTitle2}>{profile?.titleTwo}</Text>
			<View style={styles.owner}>
				<Text>{profile?.owners ? profile?.owners[0] : ""}</Text>
				<Text>{profile?.phones ? profile?.phones[0] : ""}</Text>
				<Text>{profile?.phones ? profile?.phones[1] : ""}</Text>
				<Text>{profile?.phones ? profile?.phones[2] : ""}</Text>
			</View>
			<View style={styles.owner}>
				<Text>{profile?.owners ? profile?.owners[1] : ""}</Text>
				<Text>{profile?.phones ? profile?.phones[3] : ""}</Text>
				<Text>{profile?.phones ? profile?.phones[4] : ""}</Text>
				<Text>{profile?.phones ? profile?.phones[5] : ""}</Text>
			</View>
			<Text style={styles.address}>{profile?.address}</Text>
		</View>
		<Image style={styles.image} src="pharmacy.png" />
	</View>
);

// Step 4: Create a Table Component
const Table = ({ data, titles }) => {
	if (!Array.isArray(data)) {
		console.error("Invalid data passed to Table:", data);
		return (
			<View>
				<Text>Error: Data is not available or invalid.</Text>
			</View>
		);
	}

	return (
		<View style={[styles.table]}>
			{/* Render table headers */}
			<View style={styles.tableRow}>
				{titles.map((title, index) => (
					<Text style={[styles.tableCol, styles.tableHeader]} key={index}>
						{title}
					</Text>
				))}
			</View>
			{/* Render rows for the current page */}
			{data.map((row, rowIndex) => (
				<View style={styles.tableRow} key={rowIndex}>
					{Object.values(row).map((col, colIndex) => (
						<Text style={[styles.tableCol, styles.rtlText]} key={colIndex}>
							{/* {col} */}
							{col.length > 20 ? `${col.slice(0, 17)}...` : col}
						</Text>
					))}
				</View>
			))}
		</View>
	);
};

// Step 5: Create the PDF Document Component
const PdfComponent = ({ data, titles, pdfTitle, orientation }) => {
	const [profile, setProfile] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		const activeProfile = window.localStorage.getItem("profile");
		setProfile(JSON.parse(activeProfile));
		setIsLoading(false); // Set loading to false after fetching the profile
	}, []);

	// Show a loader until the profile is loaded and data is available
	if (isLoading || !data || !Array.isArray(data) || data.length === 0) {
		return (
			<div style={{ textAlign: "center", padding: "20px" }}>Loading PDF...</div>
		);
	}
	return (
		<PDFViewer width="100%" height="600px">
			<Document title={pdfTitle} author="TechLand">
				{data.map((pageData, pageIndex) => {
					if (!Array.isArray(pageData)) {
						console.error(`Invalid pageData at page ${pageIndex}:`, pageData);
						return null;
					}

					return (
						<Page
							key={pageIndex}
							size="A4"
							style={styles.page}
							orientation={orientation}>
							<Header profile={profile} />
							<Table data={pageData} titles={titles} />
							<Text style={styles.footer}>{pageIndex + 1}</Text>
						</Page>
					);
				})}
			</Document>
		</PDFViewer>
	);
};

export default PdfComponent;
