import React from "react";

function HeaderContent({ children }) {
	return (
		<section className="w-11/12 h-fit rounded-md bg-white shadow-md mx-auto mt-6 flex flex-col justify-start items-center gap-3 py-2 px-2 md:flex-row-reverse md:h-14 mb-2">
			{children}
		</section>
	);
}

export default HeaderContent;
