import React, { useEffect, useState } from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	PDFViewer,
	Font,
	Image,
} from "@react-pdf/renderer";

import font from "../../assets/IranSans.ttf";
import { Spin } from "antd";
// Create styles
const persianText = "متن فارسی";
const encodedText = encodeURIComponent(persianText);
<Text>{decodeURIComponent(encodedText)}</Text>;
Font.register({
	family: "IRANSans",
	format: "truetype",
	src: font,
});
// Step 3: Define Styles
const styles = StyleSheet.create({
	page: {
		padding: 20,
		fontSize: 8,
		fontFamily: "IRANSans",
	},
	header: {
		width: "100%",
		// backgroundColor: "red",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 10,
		border: "1px solid #bdbdbd",
		borderRadius: 8,
		padding: 5,
	},
	tableFullRow: {
		width: "100%",
		// backgroundColor: "red",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		marginTop: 10,
		border: "1px solid #bdbdbd",
		padding: 2,
		gap: 20,
	},
	headerTitle: {
		fontSize: 14,
		textAlign: "center",
		fontWeight: "bold",
		marginBottom: 2,
		direction: "rtl",
	},
	headerTitle2: {
		fontSize: 8,
		textAlign: "center",
		fontWeight: "bold",
	},
	headerTitleFactor: {
		fontSize: 10,
		textAlign: "center",
		fontWeight: "bold",
		direction: "rtl",
	},
	table: {
		display: "table",
		width: "auto",
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#bdbdbd",
	},
	tableRow: {
		flexDirection: "row-reverse",
	},
	tableCol: {
		width: "25%",
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#bdbdbd",
		padding: 5,
		textAlign: "center",
	},
	tableFooter: {
		display: "table",
		width: "33.3%",
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#bdbdbd",
		marginTop: 5,
	},
	fullWidthCell: {
		width: "66.6%",
		height: "auto",
		textAlign: "right",
		padding: 5,
		borderStyle: "solid",
		borderColor: "none",
		borderTop: 2,
		borderTopColor: "#bdbdbd",
		fontSize: 10,
		position: "absolute",
		right: 0,
		top: 0,
		marginTop: 5,
	},
	tableColFooter: {
		width: "50%",
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#bdbdbd",
		padding: 5,
		textAlign: "center",
	},
	tableHeader: {
		fontWeight: "bold",
		backgroundColor: "#f3f3f3",
	},
	image: {
		width: 80, // Width of the image
		height: 80, // Height of the image
		alignSelf: "center", // Center the image
	},
	headerContent: {
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		gap: 2,
	},
	headerContentFactor: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		gap: 2,
		padding: 3,
	},
	owner: {
		fontSize: 8,
		flexDirection: "row-reverse",
		gap: 2,
	},
	address: {
		fontSize: 8,
	},
	col1: {
		width: "5%",
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#bdbdbd",
		padding: 5,
		textAlign: "center",
	},
	col2: {
		width: "39%",
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#bdbdbd",
		padding: 5,
		textAlign: "right",
	},
	col3: {
		width: "12%",
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#bdbdbd",
		padding: 5,
		textAlign: "center",
	},
	col4: {
		width: "20%",
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#bdbdbd",
		padding: 5,
		textAlign: "center",
	},
});

const Header = ({ profile }) => (
	<View style={styles.header}>
		<Image style={styles.image} src="logo.png" />
		<View style={styles.headerContent}>
			<Text style={styles.headerTitle}>{profile?.titleOne}</Text>
			<Text style={styles.headerTitle2}>{profile?.titleTwo}</Text>
			<View style={styles.owner}>
				<Text>{profile?.owners ? profile?.owners[0] : ""}</Text>
				<Text>{profile?.phones ? profile?.phones[0] : ""}</Text>
				<Text>{profile?.phones ? profile?.phones[1] : ""}</Text>
				<Text>{profile?.phones ? profile?.phones[2] : ""}</Text>
			</View>
			<View style={styles.owner}>
				<Text>{profile?.owners ? profile?.owners[1] : ""}</Text>
				<Text>{profile?.phones ? profile?.phones[3] : ""}</Text>
				<Text>{profile?.phones ? profile?.phones[4] : ""}</Text>
				<Text>{profile?.phones ? profile?.phones[5] : ""}</Text>
			</View>
			<Text style={styles.address}>{profile?.address}</Text>
		</View>
		<Image style={styles.image} src="pharmacy.png" />
	</View>
);
const HeaderFactor = ({ factorHeaderData }) => (
	<View style={styles.header}>
		<View style={styles.headerContentFactor}>
			<Text style={styles.headerTitleFactor}>
				{factorHeaderData?.factorNumber}
			</Text>
			<Text style={styles.headerTitleFactor}>شماره:</Text>
		</View>
		<View style={styles.headerContentFactor}>
			<Text style={styles.headerTitleFactor}>
				{factorHeaderData?.factorType === "Buy" ? "خرید" : "فروش"}
			</Text>
			<Text style={styles.headerTitleFactor}>فاکتور:</Text>
		</View>
		<View style={styles.headerContentFactor}>
			<Text style={styles.headerTitleFactor}>
				{factorHeaderData?.factorDate}
			</Text>
			<Text style={styles.headerTitleFactor}>تاریخ:</Text>
		</View>
		<View style={styles.headerContentFactor}>
			<Text style={styles.headerTitleFactor}>{factorHeaderData?.customer}</Text>
			<Text style={styles.headerTitleFactor}>محترم:</Text>
		</View>
	</View>
);
const Footer = ({ factorHeaderData }) => (
	<View style={styles.table}>
		<View style={styles.tableRow}>
			<Text style={styles.fullWidthCell}>
				{factorHeaderData?.factorDescription}
			</Text>
		</View>
		<View style={styles.tableFooter}>
			<View style={styles.tableRow}>
				<Text style={styles.tableColFooter}>جمع کل</Text>
				<Text style={styles.tableColFooter}>
					{factorHeaderData.factorAmount}
				</Text>
			</View>
			<View style={styles.tableRow}>
				<Text style={styles.tableColFooter}>رسید</Text>
				<Text style={styles.tableColFooter}>
					{factorHeaderData?.paymentType === "No_Cash"
						? 0
						: factorHeaderData.factorAmount}
				</Text>
			</View>
			<View style={styles.tableRow}>
				<Text style={styles.tableColFooter}>الباقی</Text>
				<Text style={styles.tableColFooter}>
					{factorHeaderData?.paymentType === "No_Cash"
						? factorHeaderData.factorAmount
						: 0}
				</Text>
			</View>
		</View>
	</View>
);
// Step 4: Create a Table Component
const Table = ({ data, titles }) => {
	if (!Array.isArray(data)) {
		console.error("Invalid data passed to Table:", data);
		return (
			<View>
				<Text>Error: Data is not available or invalid.</Text>
			</View>
		);
	}

	return (
		<View style={styles.table}>
			{/* Render table headers */}
			{/* <View style={styles.tableRow}>
				{titles.map((title, index) => (
					<Text style={[styles.tableCol, styles.tableHeader]} key={index}>
						{title}
					</Text>
				))}
			</View> */}
			<View style={styles.tableRow}>
				<Text style={[styles.col1, styles.tableHeader]} key={titles[0]}>
					{titles[0]}
				</Text>
				<Text style={[styles.col2, styles.tableHeader]} key={titles[1]}>
					{titles[1]}
				</Text>
				<Text style={[styles.col3, styles.tableHeader]} key={titles[2]}>
					{titles[2]}
				</Text>
				<Text style={[styles.col3, styles.tableHeader]} key={titles[3]}>
					{titles[3]}
				</Text>
				<Text style={[styles.col3, styles.tableHeader]} key={titles[4]}>
					{titles[4]}
				</Text>
				<Text style={[styles.col4, styles.tableHeader]} key={titles[5]}>
					{titles[5]}
				</Text>
			</View>
			{/* Render rows for the current page */}
			{/* {data.map((row, rowIndex) => (
				<View style={styles.tableRow} key={rowIndex}>
					{Object.values(row).map((col, colIndex) => (
						<Text style={styles.tableCol} key={colIndex}>
							{col}
						</Text>
					))}
				</View>
			))} */}
			{data.map((row, rowIndex) => (
				<View style={styles.tableRow} key={rowIndex}>
					<Text style={styles.col1}>{row.col1}</Text>
					<Text style={styles.col2}>{row.col2}</Text>
					<Text style={styles.col3}>{row.col3}</Text>
					<Text style={styles.col3}>{row.col4}</Text>
					<Text style={styles.col3}>{row.col5}</Text>
					<Text style={styles.col4}>{row.col6}</Text>
				</View>
			))}
		</View>
	);
};
const Brand = () => (
	<View style={styles.tableFullRow}>
		<View style={styles.headerContentFactor}>
			<Text style={styles.headerTitleFactor}>0790883362</Text>
			<Text style={styles.headerTitleFactor}>تماس با ما:</Text>
		</View>
		<View style={styles.headerContentFactor}>
			<Text style={styles.headerTitleFactor}>سیستم حسابداری اکسیجن</Text>
		</View>
	</View>
);
// Step 5: Create the PDF Document Component
const PdfFactor = ({ data, factorHeaderData, titles }) => {
	const [profile, setProfile] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const [size, setSize] = useState();
	useEffect(() => {
		const activeProfile = window.localStorage.getItem("profile");
		setSize(localStorage.getItem("factorFormat"));
		setProfile(JSON.parse(activeProfile));
		setIsLoading(false); // Set loading to false after fetching the profile
	}, []);

	// Show a loader until the profile is loaded and data is available
	if (isLoading || !data || !Array.isArray(data) || data.length === 0) {
		return (
			<div style={{ textAlign: "center", padding: "20px" }}>Loading PDF...</div>
		);
	}
	return (
		<PDFViewer width="100%" height="600px">
			<Document
				title={
					factorHeaderData?.factorType === "Buy" ? "فاکتور خرید" : "فاکتور فروش"
				}
				author="TechLand">
				{data.map((pageData, pageIndex) => {
					if (!Array.isArray(pageData)) {
						console.error(`Invalid pageData at page ${pageIndex}:`, pageData);
						return null;
					}

					return (
						<Page key={pageIndex} size={size} style={styles.page}>
							<Header profile={profile} />
							<HeaderFactor factorHeaderData={factorHeaderData} />
							<Table data={pageData} titles={titles} />
							<Footer factorHeaderData={factorHeaderData} />
							<Brand />
						</Page>
					);
				})}
			</Document>
		</PDFViewer>
	);
};

export default PdfFactor;
