import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { EMPLOYEES } from "../../GraphQL/Query";
import {
	ADDEMPLOYEE,
	DELETEEMPLOYEE,
	UPDATEEMPLOYEE,
} from "../../GraphQL/Mutation";
import {
	Button,
	Input,
	Space,
	Tag,
	InputNumber,
	Modal,
	Popconfirm,
	notification,
	Spin,
} from "antd";
import {
	PlusOutlined,
	PrinterOutlined,
	EditOutlined,
	DeleteOutlined,
	EllipsisOutlined,
} from "@ant-design/icons";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import { MONTHS } from "../../Constant/const";
import Content from "../StaticComponents/Content";
import HeaderContent from "../StaticComponents/HeaderContent";
import TableComponent from "../StaticComponents/TableComponent";
export function Eployee() {
	const date = new DateObject({
		calendar: persian,
		locale: persian_fa,
		format: "YYYY-MM-DD",
	});
	const today = date.format();
	const navigate = useNavigate();
	const location = useLocation();
	const client = useApolloClient();
	const [filteredEmployee, setFilteredEmployee] = useState();
	const [employees, setEmployees] = useState([]);
	const [searching, setSearching] = useState("");
	const [newEmployee, setNewEmployee] = useState(false);
	const [editemployee, setEditEmployee] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [referesh, setReferesh] = useState(false);
	const [userRole, setUserRole] = useState("");
	const [storing, setStoring] = useState(false);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	if (location.pathname === "/employee") {
		location.pathname = "کارمندان";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadEmployee();
	}, [referesh, page, searching]);
	// function for fetch employees
	const loadEmployee = async () => {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: EMPLOYEES,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: searching,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setEmployees([...res.data.getEmployees.employees]);
				setTotalCount(res.data.getEmployees.totalCount);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	};
	// function for add new employee
	async function addNewEmployee(e) {
		e.preventDefault();
		setStoring(true);
		try {
			const res = await client.mutate({
				mutation: ADDEMPLOYEE,
				variables: {
					fullName: e.target.name.value,
					phoneNumber: e.target.phone.value,
					job: e.target.job.value,
					contractDate: e.target.inputDate.value.replace(
						/[\u0660-\u0669\u06f0-\u06f9]/g,
						function (a) {
							return a.charCodeAt(0) & 0xf;
						}
					),
					workTime: e.target.workTime.value,
					salary: Number(e.target.salary.value),
				},
			});
			if (res) {
				setNewEmployee(!newEmployee);
				setReferesh(!referesh);
				notification.success({
					message: "کارمند با موفقیت اضافه گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در افزودن کارمند",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	// function for delete employee
	async function handleDeleteEmployee(employeeId) {
		try {
			const res = await client.mutate({
				mutation: DELETEEMPLOYEE,
				variables: {
					id: employeeId,
				},
			});
			if (res) {
				setReferesh(!referesh);
				notification.success({
					message: "کارمند با موفقیت حذف گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (err) {
			console.log(err);
			notification.error({
				message: "خطا در حذف کارمند",
				placement: "topRight",
				duration: 3,
			});
		}
	}
	// function for edit employee
	function handleEditEmployee(employeeId) {
		const filteredRows = employees.filter((item) => item._id === employeeId);
		setFilteredEmployee(filteredRows[0]);
		setEditEmployee(true);
	}
	// function for update employee
	async function handleUpdateEmployee(e) {
		e.preventDefault();
		setStoring(true);
		const id = filteredEmployee._id;
		try {
			const res = await client.mutate({
				mutation: UPDATEEMPLOYEE,
				variables: {
					employeeId: id,
					fullName: e.target.name.value,
					phoneNumber: e.target.phone.value,
					job: e.target.job.value,
					contractDate: e.target.inputDate.value.replace(
						/[\u0660-\u0669\u06f0-\u06f9]/g,
						function (a) {
							return a.charCodeAt(0) & 0xf;
						}
					),
					workTime: e.target.workTime.value,
					salary: Number(e.target.salary.value),
				},
			});
			if (res) {
				setEditEmployee(!editemployee);
				setReferesh(!referesh);
				notification.success({
					message: "کارمند با موفقیت ویرایش گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در ویرایش کارمند",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	// columns
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "نام",
			dataIndex: "fullName",
			// filteredValue: [searching],
			// onFilter: (value, record) => {
			// 	return String(record.fullName)
			// 		.toLocaleLowerCase()
			// 		.includes(value.toLocaleLowerCase());
			// },
			// render: (fullName, employee) => employee.fullName,
			key: "fullName",
			align: "center",
		},
		{
			title: "وظیفه",
			dataIndex: "job",
			key: "job",
			align: "center",
		},
		{
			title: "شماره تماس",
			dataIndex: "phoneNumber",
			key: "phoneNumber",
			align: "center",
		},
		{
			title: "قرارداد",
			dataIndex: "contractDate",
			key: "contractDate",
			align: "center",
		},
		{
			title: "زمان کاری",
			dataIndex: "workTime",
			key: "workTime",
			align: "center",
		},
		{
			title: "معاش",
			dataIndex: "salary",
			key: "salary",
			align: "center",
		},
		{
			title: "بلانس",
			dataIndex: "balance",
			key: "balance",
			align: "center",
		},
		{
			title: userRole === "Admin" && "عملیات",
			dataIndex: "action",
			key: "action",
			render: (_, record) => {
				return (
					userRole === "Admin" && (
						<Space className="action-space">
							<EllipsisOutlined
								onClick={() =>
									navigate("/selectedEmployee", {
										state: { employeeId: record.id },
									})
								}
							/>
							<EditOutlined onClick={() => handleEditEmployee(record.id)} />
							<Popconfirm
								title="آیا مطمین هستید میخواهید حذف نمایید؟"
								onConfirm={() => handleDeleteEmployee(record.id)}
								okText="بله"
								cancelText="انصراف"
								placement="topLeft">
								<DeleteOutlined />
							</Popconfirm>
						</Space>
					)
				);
			},
			align: "center",
		},
	];
	// employees data
	const data = employees.map((employee, index) => ({
		key: index,
		num: index + 1,
		id: employee._id,
		fullName: employee.fullName,
		job: employee.job,
		phoneNumber: employee.phoneNumber,
		contractDate: employee.contractDate,
		workTime: employee.workTime,
		salary: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{employee.salary}
			</Tag>
		),
		balance: (
			<Tag
				bordered={false}
				color={
					employee.balance < 0 ? "success" : employee.balance > 0 ? "error" : ""
				}>
				{
					<Space>
						{Math.abs(employee.balance)}
						{""}
						{employee.balance > 0
							? "بستانکار"
							: employee.balance === 0
							? "تسویه"
							: "بدهکار"}
					</Space>
				}
			</Tag>
		),
	}));
	return (
		<div className="container">
			{/* add new employee */}
			{newEmployee && (
				<Modal
					title="ثبت کارمند جدید"
					open={newEmployee}
					onCancel={() => {
						setNewEmployee(!newEmployee);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={addNewEmployee}>
							<Space direction="vertical">
								<Input
									id="name"
									name="name"
									size="large"
									placeholder="نام کارمند"
								/>
								<Input
									id="phone"
									name="phone"
									size="large"
									placeholder="موبایل کارمند"
								/>
								<Input
									id="job"
									name="job"
									size="large"
									placeholder="وظیفه کارمند"
								/>
								<Space className="inputDate-container">
									<DatePicker
										calendar={persian}
										locale={persian_fa}
										months={MONTHS}
										calendarPosition="top-right"
										format="YYYY-MM-DD"
										inputClass="inputDate"
										value={today}
										id="inputDate"
										name="inputDate"
									/>
								</Space>
								<Input
									placeholder="تایم کاری کارمند"
									id="workTime"
									name="workTime"
									size="large"
								/>
								<InputNumber
									min={0}
									placeholder="معاش کارمند"
									id="salary"
									name="salary"
									size="large"
									style={{ width: "100%" }}
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ثبت کارمند
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			{/* edit exist employ */}
			{editemployee && (
				<Modal
					title="ویرایش کارمند"
					open={editemployee}
					onCancel={() => {
						setEditEmployee(!editemployee);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={handleUpdateEmployee}>
							<Space direction="vertical">
								<Input
									id="name"
									name="name"
									size="large"
									placeholder="نام کارمند"
									defaultValue={filteredEmployee?.fullName}
								/>
								<Input
									id="phone"
									name="phone"
									size="large"
									placeholder="موبایل کارمند"
									defaultValue={filteredEmployee?.phoneNumber}
								/>
								<Input
									id="job"
									name="job"
									size="large"
									placeholder="وظیفه کارمند"
									defaultValue={filteredEmployee?.job}
								/>
								<Space className="inputDate-container">
									<DatePicker
										calendar={persian}
										locale={persian_fa}
										months={MONTHS}
										calendarPosition="top-right"
										format="YYYY-MM-DD"
										inputClass="inputDate"
										value={filteredEmployee?.contractDate}
										id="inputDate"
										name="inputDate"
									/>
								</Space>
								<Input
									placeholder="تایم کاری کارمند"
									id="workTime"
									name="workTime"
									size="large"
									defaultValue={filteredEmployee?.workTime}
								/>
								<InputNumber
									min={0}
									placeholder="معاش کارمند"
									id="salary"
									name="salary"
									size="large"
									style={{ width: "100%" }}
									defaultValue={filteredEmployee?.salary}
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ویرایش کارمند
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<HeaderContent>
					<Input.Search
						placeholder="جستجوی کارمند"
						allowClear
						size="large"
						onChange={(e) => {
							setSearching(e.target.value);
						}}
						style={{
							width: 250,
							direction: "ltr",
							textAlign: "right",
						}}
					/>
					{userRole === "Admin" && (
						<Button
							type="primary"
							size="large"
							icon={<PlusOutlined />}
							onClick={() => {
								setNewEmployee(!newEmployee);
							}}
							className="btn">
							ثبت کارمند
						</Button>
					)}
					<Button
						type="primary"
						size="large"
						icon={<PrinterOutlined />}
						onClick={() => navigate("/printEmployee")}
						className="btn">
						چاپ
					</Button>
				</HeaderContent>
				<TableComponent
					data={data}
					columns={columns}
					loading={isLoader}
					rowKey="id"
					pagination={{
						pageSize: 10, // Number of records per page
						total: totalCount,
						onChange: (current) => {
							setPage(current); // Update page state
							loadEmployee(current); // Fetch the new page data
						},
						current: page, // Track the current page
						showLessItems: true, // Optional: Keep pagination UI minimal
					}}
				/>
			</Content>
		</div>
	);
}
