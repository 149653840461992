import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { DRUGDETAILS } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import "../../Styles/StaticComponents/Global.css";
import PdfComponent from "../Pdf/PdfComponent";

export function SelectedDrugPrint() {
	const client = useApolloClient();
	const navigate = useNavigate();
	const location = useLocation();
	const [pages, setPages] = useState([]);
	const [isLoader, setIsLoader] = useState(false);
	const receivedData = location.state?.selectedDrug || {};
	// Check for active user and load drug details
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadDrugDetails();
	}, []);

	console.log("rec", receivedData);

	// Fetch drug details and process data
	async function loadDrugDetails() {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: DRUGDETAILS,
				variables: {
					id: receivedData,
					searchItem: "",
					perPage: -1,
					pageNumber: -1,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				const fetchedDrugs = res.data.getDrugDetails.factors;
				// setDrugs(fetchedDrugs);
				const modifiedData = fetchedDrugs.map((drug, index) => ({
					col1: index + 1,
					col2: drug?.items[0]?.drug?.drugType?.title,
					col3: drug?.items[0]?.drug?.name,
					col4: drug?.items[0]?.drug?.company,
					col5: parseFloat(drug?.items[0]?.quantity),
					col6: parseFloat(drug?.items[0]?.price),
					col7: parseFloat(drug?.items[0]?.total),
					col8: drug?.customer?.fullName,
					col9: drug?.factorType === "Buy" ? "خرید" : "فروش",
					col10: drug?.paymentType === "Cash" ? "نقده" : "نسیه",
					col11: drug?.buyFactorNumber || drug?.sellFactorNumber,
					col12: drug?.date,
				}));
				// Split data into pages
				const rowsPerPage = 15;
				const splitPages = splitDataIntoPages(modifiedData, rowsPerPage);
				setPages(splitPages);
				setIsLoader(false);
			}
		} catch (error) {
			console.error("Error fetching drug details:", error);
			setIsLoader(false);
		}
	}
	const titles = [
		"#",
		"نوعیت",
		"نام",
		"کمپنی",
		"تعداد",
		"قیمت",
		"کل",
		"مشتری",
		"فاکتور",
		"سند",
		"شماره",
		"تاریخ",
	];
	// Helper function to split data into pages
	const splitDataIntoPages = (data, rowsPerPage) => {
		if (!Array.isArray(data)) {
			console.error("Invalid input data for splitDataIntoPages:", data);
			return [];
		}

		const pages = [];
		for (let i = 0; i < data.length; i += rowsPerPage) {
			pages.push(data.slice(i, i + rowsPerPage));
		}

		console.log("Pages generated:", pages); // Debug output
		return pages;
	};
	return (
		<div className="w-full">
			{isLoader && <Loader />}
			{!isLoader && pages.length > 0 && (
				<PdfComponent
					data={pages}
					titles={titles}
					pdfTitle="داروی انتخاب شده"
					orientation="landscape"
				/>
			)}
		</div>
	);
}
