import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { CHECKS, CUSTOMERS, LASTCHEK } from "../../GraphQL/Query";
import { ADDCHECKIN, DELETECHECK, UPDATECHECK } from "../../GraphQL/Mutation";
import {
	Button,
	Dropdown,
	Menu,
	Input,
	Space,
	Tag,
	Select,
	Modal,
	InputNumber,
	Popconfirm,
	notification,
	Spin,
} from "antd";
import {
	PlusOutlined,
	CaretDownOutlined,
	PrinterOutlined,
	EditOutlined,
	DeleteOutlined,
	SelectOutlined,
} from "@ant-design/icons";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import { MONTHS } from "../../Constant/const";
import Content from "../StaticComponents/Content";
import HeaderContent from "../StaticComponents/HeaderContent";
import TableComponent from "../StaticComponents/TableComponent";
const { Option } = Select;
export function Checks() {
	const date = new DateObject({
		calendar: persian,
		locale: persian_fa,
		format: "YYYY-MM-DD",
	});
	const today = date.format();
	const navigate = useNavigate();
	const location = useLocation();
	const client = useApolloClient();
	const [referesh, setReferesh] = useState();
	const [filteredCheck, setFilteredCheck] = useState();
	const [checks, setChecks] = useState([]);
	const [customers, setCustomers] = useState([]);
	const [searching, setSearching] = useState("");
	const [checkType, setCheckType] = useState("");
	const [selectedCustomerId, setSelectedCustomerId] = useState("");
	const [selectedCustomer, setSelectedCustomer] = useState();
	const [checkInNumber, setCheckInNumber] = useState(1);
	const [checkOutNumber, setCheckOutNumber] = useState(1);
	const [newRecivedCheck, setNewRecivedCheck] = useState(false);
	const [newPaymentCheck, setNewPaymentCheck] = useState(false);
	const [editRecivedCheck, setEditRecivedCheck] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [userRole, setUserRole] = useState("");
	const [storing, setStoring] = useState(false);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [searchValue, setSearchValue] = useState("");
	if (location.pathname === "/checks") {
		location.pathname = "اسناد";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadCustomers();
		loadChecks();
		handleLastCheck();
	}, [
		referesh,
		checkInNumber,
		checkOutNumber,
		page,
		searchValue,
		searching,
		checkType,
	]);
	// columns
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "نام",
			dataIndex: "fullName",
			// filteredValue: [searching],
			// onFilter: (value, check) => {
			// 	return (
			// 		String(check.customer.fullName)
			// 			.toLocaleLowerCase()
			// 			.includes(value.toLocaleLowerCase()) ||
			// 		String(check.checkType).startsWith(value)
			// 	);
			// },
			// render: (fullName, check) => check.customer.fullName,
			key: "fullName",
			align: "center",
		},
		{
			title: "شماره سند",
			dataIndex: "checkNumber",
			key: "checkNumber",
			align: "center",
		},
		{
			title: "نوعیت",
			dataIndex: "checkType",
			key: "checkType",
			align: "center",
		},
		{
			title: "مبلغ",
			dataIndex: "amount",
			key: "amount",
			align: "center",
		},
		{
			title: "تاریخ",
			dataIndex: "date",
			key: "date",
			align: "center",
		},
		{
			title: userRole === "Admin" && "عملیات",
			dataIndex: "action",
			key: "action",
			render: (_, record) => {
				return (
					userRole === "Admin" && (
						<Space className="action-space">
							<PrinterOutlined
								onClick={() =>
									navigate("/printCheck", {
										state: { selectedCheck: record?.id },
									})
								}
							/>
							<EditOutlined onClick={() => handleEditCheck(record?.id)} />
							<Popconfirm
								title="آیا مطمین هستید میخواهید حذف نمایید؟"
								onConfirm={() => handleDeletCheck(record?.id)}
								okText="بله"
								cancelText="انصراف"
								placement="topLeft">
								<DeleteOutlined />
							</Popconfirm>
						</Space>
					)
				);
			},
			align: "center",
		},
	];
	// function for fetch customers
	async function loadCustomers() {
		setIsLoader(true);
		try {
			const res = await client.query({
				query: CUSTOMERS,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: searchValue,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setCustomers([...res.data.getCustomers.customers]);
				setTotalCount(res.data.getCustomers.totalCount);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	// function for fetch checks
	const loadChecks = async () => {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: CHECKS,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: Number(searching),
					checkType: checkType === "" ? null : checkType,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setChecks([...res.data.getChecks.checks]);
				setTotalCount(res.data.getChecks.totalCount);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	};
	// function for fetch last check number
	const handleLastCheck = async () => {
		try {
			const res = await client.query({
				query: LASTCHEK,
				variables: {
					checkType: "Check_In",
				},
				fetchPolicy: "no-cache",
			});
			const res2 = await client.query({
				query: LASTCHEK,
				variables: {
					checkType: "Check_Out",
				},
				fetchPolicy: "no-cache",
			});
			setCheckInNumber(res.data.getLastCheck.checkInNumber + 1);
			setCheckOutNumber(res2.data.getLastCheck.checkOutNumber + 1);
		} catch (error) {
			console.log(error);
		}
	};
	// function for add new recieve check
	async function handleRecivedCheck(e) {
		e.preventDefault();
		setStoring(true);
		try {
			const res = await client.mutate({
				mutation: ADDCHECKIN,
				variables: {
					checkType: "Check_In",
					date: e.target.inputDate.value.replace(
						/[\u0660-\u0669\u06f0-\u06f9]/g,
						function (a) {
							return a.charCodeAt(0) & 0xf;
						}
					),
					amount: Number(e.target.checkAmount.value),
					description: e.target.checkDescription.value,
					customer: selectedCustomerId,
				},
			});
			if (res) {
				setNewRecivedCheck(!newRecivedCheck);
				setReferesh(!referesh);
				notification.success({
					message: "سند با موفقیت اضافه گردید",
					placement: "topRight",
					duration: 3,
				});
				setSelectedCustomer("");
				setSelectedCustomerId("");
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در افزودن سند",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	// function for add new payment check
	async function handlePaymentCheck(e) {
		e.preventDefault();
		setStoring(true);
		try {
			const res = await client.mutate({
				mutation: ADDCHECKIN,
				variables: {
					checkType: "Check_Out",
					date: e.target.inputDate.value.replace(
						/[\u0660-\u0669\u06f0-\u06f9]/g,
						function (a) {
							return a.charCodeAt(0) & 0xf;
						}
					),
					amount: Number(e.target.checkAmount.value),
					description: e.target.checkDescription.value,
					customer: selectedCustomerId,
				},
			});
			if (res) {
				setNewPaymentCheck(!newPaymentCheck);
				setReferesh(!referesh);
				notification.success({
					message: "سند با موفقیت اضافه گردید",
					placement: "topRight",
					duration: 3,
				});
				setSelectedCustomer("");
				setSelectedCustomerId("");
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در افزودن سند",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	// function for delete check
	async function handleDeletCheck(checkId) {
		try {
			const res = await client.mutate({
				mutation: DELETECHECK,
				variables: {
					id: checkId,
				},
			});
			if (res) {
				setReferesh(!referesh);
				notification.success({
					message: "سند با موفقیت حذف گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (err) {
			console.log(err);
			notification.error({
				message: "خطا در حذف سند",
				placement: "topRight",
				duration: 3,
			});
		}
	}
	// function for edit check
	function handleEditCheck(checkId) {
		const filteredRows = checks.filter((item) => item._id === checkId);
		setFilteredCheck(filteredRows[0]);
		setSelectedCustomerId(filteredRows[0].customer._id);
		setEditRecivedCheck(!editRecivedCheck);
	}
	// function for update check
	async function handleUpdateCheck(e) {
		e.preventDefault();
		setStoring(true);
		try {
			const res = await client.mutate({
				mutation: UPDATECHECK,
				variables: {
					checkId: filteredCheck._id,
					checkType: filteredCheck.checkType,
					date: e.target.inputDate.value.replace(
						/[\u0660-\u0669\u06f0-\u06f9]/g,
						function (a) {
							return a.charCodeAt(0) & 0xf;
						}
					),
					amount: Number(e.target.checkAmount.value),
					description: e.target.checkDescription.value,
					customer: selectedCustomerId,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setEditRecivedCheck(!editRecivedCheck);
				setReferesh(!referesh);
				notification.success({
					message: "سند با موفقیت ویرایش گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در ویرایش سند",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	const printChecks = [
		{
			key: "1",
			label: (
				<Button
					type="link"
					block="true"
					icon={<PrinterOutlined />}
					paddingBlock="0"
					onClick={() => navigate("/printChecks")}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					تمام اسناد
				</Button>
			),
		},
		{
			key: "2",
			label: (
				<Button
					type="link"
					block="true"
					icon={<PrinterOutlined />}
					onClick={() => {
						navigate("/printCheck", {
							state: { checkType: "Check_In" },
						});
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					دریافتی
				</Button>
			),
		},
		{
			key: "3",
			label: (
				<Button
					type="link"
					block="true"
					icon={<PrinterOutlined />}
					onClick={() => {
						navigate("/printCheck", {
							state: { checkType: "Check_Out" },
						});
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					پرداختی
				</Button>
			),
		},
	];
	const saveChecks = [
		{
			key: "1",
			label: (
				<Button
					type="link"
					block="true"
					icon={<PlusOutlined />}
					paddingBlock="0"
					onClick={() => {
						setNewPaymentCheck(true);
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					پرداختی
				</Button>
			),
		},
		{
			key: "2",
			label: (
				<Button
					type="link"
					block="true"
					icon={<PlusOutlined />}
					onClick={() => {
						setNewRecivedCheck(true);
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					دریافتی
				</Button>
			),
		},
	];
	const menu1 = (
		<Menu>
			{printChecks.map((printCheck) => (
				<Menu.Item key={printCheck.key}>{printCheck.label}</Menu.Item>
			))}
		</Menu>
	);
	const menu2 = (
		<Menu>
			{saveChecks.map((saveCheck) => (
				<Menu.Item key={saveCheck.key}>{saveCheck.label}</Menu.Item>
			))}
		</Menu>
	);
	const filterChecks = [
		{
			key: "1",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					paddingBlock="0"
					onClick={() => {
						setCheckType("");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					همه
				</Button>
			),
		},
		{
			key: "2",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					paddingBlock="0"
					onClick={() => {
						setCheckType("Check_In");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					دریافتی
				</Button>
			),
		},
		{
			key: "3",
			label: (
				<Button
					type="link"
					block="true"
					icon={<SelectOutlined />}
					onClick={() => {
						setCheckType("Check_Out");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					پرداختی
				</Button>
			),
		},
	];
	const checkTypes = (
		<Menu>
			{filterChecks.map((filterCheck) => (
				<Menu.Item key={filterCheck.key}>{filterCheck.label}</Menu.Item>
			))}
		</Menu>
	);
	// checks data
	const data = checks.map((check, index) => ({
		key: index,
		num: index + 1,
		id: check._id,
		fullName: check.customer.fullName,
		checkNumber: (
			<Tag bordered={false}>{check.checkInNumber || check.checkOutNumber}</Tag>
		),
		checkType: (
			<Tag
				bordered={false}
				color={check.checkType == "Check_In" ? "success" : "error"}>
				{check.checkType == "Check_In" ? "دریافتی" : "پرداختی"}
			</Tag>
		),
		amount: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{check.amount}
			</Tag>
		),
		date: check.date,
	}));
	return (
		<div className="container">
			{/* add new Recieve check */}
			{newRecivedCheck && (
				<Modal
					title="ثبت سند دریافتی"
					open={newRecivedCheck}
					onCancel={() => {
						setNewRecivedCheck(!newRecivedCheck);
						setSelectedCustomer("");
						setSelectedCustomerId("");
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={handleRecivedCheck}>
							<Space direction="vertical">
								<Input
									id="checkNumber"
									name="checkNumber"
									size="large"
									value={checkInNumber}
									type="disable"
								/>
								<Space className="inputDate-container">
									<DatePicker
										calendar={persian}
										locale={persian_fa}
										months={MONTHS}
										calendarPosition="top-right"
										format="YYYY-MM-DD"
										inputClass="inputDate"
										value={today}
										id="inputDate"
										name="inputDate"
									/>
								</Space>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="جستجوی مشتری"
									className="w-full"
									name="stack"
									onChange={(value) => {
										setSelectedCustomerId(value);
									}}
									onSearch={(value) => {
										setSearchValue(value);
										loadCustomers(value);
										setSelectedCustomerId(value);
									}}
									notFoundContent={
										isLoader ? (
											<Spin size="small" />
										) : (
											<Tag color="error" className="w-full text-center">
												موردی یافت نشد
											</Tag>
										)
									}
									size="large">
									{customers.map((customer) => (
										<Option
											key={customer._id}
											value={customer._id}
											style={{ direction: "rtl" }}>
											{customer.fullName +
												" - " +
												customer.company +
												" - " +
												customer.city}
										</Option>
									))}
								</Select>
								<InputNumber
									placeholder="مبلغ سند"
									id="checkAmount"
									name="checkAmount"
									size="large"
									min={1}
									style={{
										width: "100%",
									}}
								/>
								<Input
									placeholder="توضیحات سند"
									id="checkDescription"
									name="checkDescription"
									size="large"
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ثبت سند
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			{/* add new Recieve Check */}
			{newPaymentCheck && (
				<Modal
					title="ثبت سند پرداختی"
					open={newPaymentCheck}
					onCancel={() => {
						setNewPaymentCheck(!newPaymentCheck);
						setSelectedCustomer("");
						setSelectedCustomerId("");
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={handlePaymentCheck}>
							<Space direction="vertical">
								<Input
									id="checkNumber"
									name="checkNumber"
									size="large"
									value={checkOutNumber}
									type="disable"
								/>
								<Space className="inputDate-container">
									<DatePicker
										calendar={persian}
										locale={persian_fa}
										months={MONTHS}
										calendarPosition="top-right"
										format="YYYY-MM-DD"
										inputClass="inputDate"
										value={today}
										id="inputDate"
										name="inputDate"
									/>
								</Space>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="جستجوی مشتری"
									className="w-full"
									name="stack"
									onChange={(value) => {
										setSelectedCustomerId(value);
									}}
									onSearch={(value) => {
										setSearchValue(value);
										loadCustomers(value);
										setSelectedCustomerId(value);
									}}
									notFoundContent={
										isLoader ? (
											<Spin size="small" />
										) : (
											<Tag color="error" className="w-full text-center">
												موردی یافت نشد
											</Tag>
										)
									}
									size="large">
									{customers.map((customer) => (
										<Option
											key={customer._id}
											value={customer._id}
											style={{ direction: "rtl" }}>
											{customer.fullName +
												" - " +
												customer.company +
												" - " +
												customer.city}
										</Option>
									))}
								</Select>
								<InputNumber
									placeholder="مبلغ سند"
									id="checkAmount"
									name="checkAmount"
									size="large"
									min={1}
									style={{
										width: "100%",
									}}
								/>
								<Input
									placeholder="توضیحات سند"
									id="checkDescription"
									name="checkDescription"
									size="large"
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ثبت سند
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			{/* edit exist Check */}
			{editRecivedCheck && (
				<Modal
					title="ویرایش سند"
					open={editRecivedCheck}
					onCancel={() => {
						setEditRecivedCheck(!editRecivedCheck);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={handleUpdateCheck}>
							<Space direction="vertical">
								<Input
									id="checkNumber"
									name="checkNumber"
									size="large"
									value={
										filteredCheck?.checkOutNumber ||
										filteredCheck?.checkInNumber
									}
									type="disable"
								/>
								<Space className="inputDate-container">
									<DatePicker
										calendar={persian}
										locale={persian_fa}
										months={MONTHS}
										calendarPosition="top-right"
										format="YYYY-MM-DD"
										inputClass="inputDate"
										value={filteredCheck?.date}
										id="inputDate"
										name="inputDate"
									/>
								</Space>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="جستجوی مشتری"
									style={{ width: "100%", direction: "rtl" }}
									name="stack"
									onChange={(value) => {
										setSelectedCustomerId(value);
									}}
									size="large"
									onSearch={(value) => {
										setSearchValue(value);
										loadCustomers(value);
										setSelectedCustomerId(value);
									}}
									notFoundContent={
										isLoader ? (
											<Spin size="small" />
										) : (
											<Tag color="error" className="w-full text-center">
												موردی یافت نشد
											</Tag>
										)
									}
									defaultValue={
										filteredCheck?.customer?.fullName +
										" - " +
										filteredCheck?.customer?.company +
										" - " +
										filteredCheck?.customer?.city
									}>
									{customers.map((customer) => (
										<Option
											key={customer._id}
											value={customer._id}
											style={{ direction: "rtl" }}
											onClick={() =>
												setSelectedCustomer(
													customer?.fullName +
														" - " +
														customer?.company +
														" - " +
														customer?.city
												)
											}>
											{customer?.fullName +
												" - " +
												customer?.company +
												" - " +
												customer?.city}
										</Option>
									))}
								</Select>
								<InputNumber
									placeholder="مبلغ سند"
									id="checkAmount"
									name="checkAmount"
									size="large"
									min={1}
									style={{
										width: "100%",
									}}
									defaultValue={filteredCheck?.amount}
								/>
								<Input
									placeholder="توضیحات سند"
									id="checkDescription"
									name="checkDescription"
									size="large"
									defaultValue={filteredCheck?.description}
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ویرایش سند
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<HeaderContent>
					<Input.Search
						placeholder="جستجوی مشتری"
						allowClear
						size="large"
						onSearch={(value) => {
							setSearching(value);
						}}
						onChange={(e) => {
							setSearching(e.target.value);
						}}
						style={{
							width: 250,
							direction: "ltr",
							textAlign: "right",
						}}
					/>
					<Dropdown
						overlay={checkTypes}
						placement="bottom"
						arrow={{ pointAtCenter: true }}
						className="btn">
						<Button type="primary" size="large" icon={<CaretDownOutlined />}>
							انتخاب سند
						</Button>
					</Dropdown>
					{userRole === "Admin" && (
						<Dropdown
							overlay={menu2}
							placement="bottom"
							arrow={{ pointAtCenter: true }}
							className="btn">
							<Button type="primary" size="large" icon={<CaretDownOutlined />}>
								ثبت سند
							</Button>
						</Dropdown>
					)}
					<Dropdown
						overlay={menu1}
						placement="bottom"
						arrow={{ pointAtCenter: true }}
						className="btn">
						<Button type="primary" size="large" icon={<CaretDownOutlined />}>
							چاپ اسناد
						</Button>
					</Dropdown>
				</HeaderContent>
				<TableComponent
					data={data}
					columns={columns}
					loading={isLoader}
					rowKey="id"
					pagination={{
						pageSize: 10, // Number of records per page
						total: totalCount,
						onChange: (current) => {
							setPage(current); // Update page state
							loadChecks(current); // Fetch the new page data
						},
						current: page, // Track the current page
						showLessItems: true, // Optional: Keep pagination UI minimal
					}}
				/>
			</Content>
		</div>
	);
}
