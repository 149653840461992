import { useNavigate } from "react-router-dom";
import "../../Styles/StaticComponents/Login.css";
import { useEffect, useState } from "react";
import { Loading } from "./Loading";
import { useApolloClient } from "@apollo/client";
import { LOGIN } from "../../GraphQL/Mutation";
import { PROFILE } from "../../GraphQL/Query";
export function Login() {
	const client = useApolloClient();
	const [isloader, setIsloader] = useState(false);
	const [profile, setProfile] = useState();
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (JSON.parse(activeUser)) {
			navigate("/home");
		}
		loadProfile();
	}, []);
	const navigate = useNavigate();
	const loadProfile = async () => {
		try {
			const res = await client.query({
				query: PROFILE,
				variables: {},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setProfile(res.data.getProfile);
			}
		} catch (error) {
			console.log(error);
		}
	};
	async function handleLogin(e) {
		e.preventDefault();
		const loginData = {
			userName: e.target.username.value,
			password: e.target.password.value,
		};
		if (loginData.userName == "" || loginData.password == "") {
			document.getElementById("username").style.border = "1px solid #FF4155";
			document.getElementById("password").style.border = "1px solid #FF4155";
			setTimeout(() => {
				document.getElementById("username").style.borderColor = "#555";
				document.getElementById("password").style.borderColor = "#555";
			}, 2000);
			return;
		}
		try {
			setIsloader(true);
			const res = await client.mutate({
				mutation: LOGIN,
				variables: {
					userName: e.target.username.value,
					password: e.target.password.value,
				},
			});

			if (res.data) {
				setIsloader(false);
				window.localStorage.setItem("activeUser", JSON.stringify(res.data));
				window.localStorage.setItem("profile", JSON.stringify(profile));
				window.localStorage.setItem("factorFormat", "A4");
				navigate("/home");
			}
		} catch (error) {
			document.getElementById("username").style.border = "1px solid #FF4155";
			document.getElementById("password").style.border = "1px solid #FF4155";
			setTimeout(() => {
				document.getElementById("username").style.borderColor = "#555";
				document.getElementById("password").style.borderColor = "#555";
			}, 2000);
			setIsloader(false);
			console.log(error);
		}
	}
	return (
		<div className="login-container">
			<div className="form-container" id="login-form">
				<img src="profile-user.png" alt="profile-user" class="login-image" />
				<h2 style={{ marginBottom: 20, textAlign: "center" }}>
					ورود به سیستم مدیریتی اکسیجن
				</h2>
				<form onSubmit={handleLogin}>
					<input
						type="text"
						name="username"
						id="username"
						required
						className="login-input"
						placeholder="نام کاربری"
						autoComplete="off"
					/>
					<input
						type="password"
						name="password"
						id="password"
						required
						className="login-input"
						placeholder="پسورد"
						autoComplete="off"
					/>
					<button type="submit" className="login-submit">
						ورود
					</button>
					<div className="login-loader">{isloader && <Loading />}</div>
				</form>
			</div>
		</div>
	);
}
