import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CHECKS } from "../../GraphQL/Query";
import { useApolloClient } from "@apollo/client";
import { Loader } from "../StaticComponents/Loader";
import PdfComponent from "../Pdf/PdfComponent";
export function PrintChecks() {
	const client = useApolloClient();
	const navigate = useNavigate();
	const [pages, setPages] = useState([]);
	const [isLoader, setIsLoader] = useState(false);
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadChecks();
	}, []);
	const loadChecks = async () => {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: CHECKS,
				variables: {
					pageNumber: -1,
					perPage: -1,
					searchItem: 0,
					checkType: null,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setIsLoader(false);
				const fetchedDrugs = res.data.getChecks.checks;
				console.log("fetchedDrugs", fetchedDrugs);
				// setDrugs(fetchedDrugs);
				const modifiedData = fetchedDrugs.map((check, index) => ({
					col1: index + 1,
					col2: check?.customer?.fullName,
					col3: parseFloat(check?.checkInNumber || check?.checkOutNumber),
					col4: check?.checkType === "Check_Out" ? "پرداختی" : "دریافتی",
					col5: parseFloat(check?.amount),
					col6: check?.date,
				}));
				// Split data into pages
				const rowsPerPage = 15;
				const splitPages = splitDataIntoPages(modifiedData, rowsPerPage);
				setPages(splitPages);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	};
	const titles = ["#", "نام", "سند", "نوعیت", "مبلغ", "تاریخ"];
	// Helper function to split data into pages
	const splitDataIntoPages = (data, rowsPerPage) => {
		if (!Array.isArray(data)) {
			console.error("Invalid input data for splitDataIntoPages:", data);
			return [];
		}

		const pages = [];
		for (let i = 0; i < data.length; i += rowsPerPage) {
			pages.push(data.slice(i, i + rowsPerPage));
		}

		console.log("Pages generated:", pages); // Debug output
		return pages;
	};
	return (
		<div className="w-full">
			{isLoader && <Loader />}
			{/* Render PdfComponent only when pages data is available */}
			{!isLoader && pages.length > 0 && (
				<PdfComponent
					data={pages}
					titles={titles}
					pdfTitle="داروی انتخاب شده"
					orientation="landscape"
				/>
			)}
		</div>
	);
}
