import { useLocation, useNavigate } from "react-router-dom";
import { PrintHeader } from "../StaticComponents/PrintHeader";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { DRUGDETAILS, SALARIES } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import "../../Styles/StaticComponents/Global.css";
import PdfComponent from "../Pdf/PdfComponent";
export function SelectedEmployeePrint() {
	const client = useApolloClient();
	const navigate = useNavigate();
	const location = useLocation();
	const [isLoader, setIsLoader] = useState(false);
	const [referesh, setReferesh] = useState(false);
	const [pages, setPages] = useState([]);

	const receivedData = location.state?.employeeId || {};
	if (location.pathname == "/selectedEmployee") {
		location.pathname = "لیست معاشات";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadEmployeeSalaries();
	}, [referesh]);
	async function loadEmployeeSalaries() {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: SALARIES,
				variables: {
					employeeId: receivedData,
					pageNumber: 1,
					perPage: 10,
					searchItem: 0,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setIsLoader(false);
				const fetchedSalaries = res.data.getSalaries.salaries;
				console.log("fetchedSalaries", fetchedSalaries);
				// setSalaries(fetchedSalaries);
				const modifiedData = fetchedSalaries.map((salary, index) => ({
					col1: index + 1,
					col2: salary?.salaryNumber,
					col3: salary?.employeeId?.fullName,
					col4: salary?.amount,
					col5: salary?.salaryType === "Deposit" ? "معاش" : "برداشتی",
					col6: salary?.date,
					col7: salary?.description,
				}));
				// Split data into pages
				const rowsPerPage = 15;
				const splitPages = splitDataIntoPages(modifiedData, rowsPerPage);
				setPages(splitPages);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	const titles = ["#", "شماره", "نام", "مبلغ", "نوعیت", "تاریخ", "توضیحات"];
	// Helper function to split data into pages
	const splitDataIntoPages = (data, rowsPerPage) => {
		if (!Array.isArray(data)) {
			console.error("Invalid input data for splitDataIntoPages:", data);
			return [];
		}

		const pages = [];
		for (let i = 0; i < data.length; i += rowsPerPage) {
			pages.push(data.slice(i, i + rowsPerPage));
		}

		console.log("Pages generated:", pages); // Debug output
		return pages;
	};
	return (
		<div className="conatiner">
			{isLoader && <Loader />}
			{/* Render PdfComponent only when pages data is available */}
			{!isLoader && pages.length > 0 && (
				<PdfComponent
					data={pages}
					titles={titles}
					pdfTitle="لیست معاشات"
					orientation="landscape"
				/>
			)}
			{/* <div className="file-container">
        <PrintHeader type="لیست معاشات" />
        <div className="table-container">
        <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th>ردیف</th>
                <th>شماره</th>
                <th>نام</th>
                <th>مبلغ</th>
                <th>تاریخ</th>
                <th>توضیحات</th>
              </tr>
            </thead>
            <tbody>
              {salaries.map((salary, index) => {
                return (
                  <tr key={index} className="table-row">
                    <td>{index + 1}</td>
                    <td>{salary.salaryNumber}</td>
                    <td>{salary.employeeId.fullName}</td>
                    <td>{salary.amount}</td>
                    <td>{salary.date}</td>
                    <td>{salary.description}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="printFooter">
            سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
          </div>
        </div>
      </div> */}
		</div>
	);
}
