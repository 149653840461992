import React, { useEffect, useState } from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	PDFViewer,
	Font,
	Image,
} from "@react-pdf/renderer";

import font from "../../assets/IranSans.ttf";
// Create styles
const persianText = "متن فارسی";
const encodedText = encodeURIComponent(persianText);
<Text>{decodeURIComponent(encodedText)}</Text>;
Font.register({
	family: "IRANSans",
	format: "truetype",
	src: font,
});
// Step 3: Define Styles
const styles = StyleSheet.create({
	page: {
		padding: 20,
		fontSize: 8,
		fontFamily: "IRANSans",
	},
	header: {
		width: "100%",
		// backgroundColor: "red",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 10,
		border: "1px solid #bdbdbd",
		borderRadius: 8,
		padding: 5,
	},
	tableFullRow: {
		width: "100%",
		// backgroundColor: "red",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		marginTop: 10,
		border: "1px solid #bdbdbd",
		padding: 2,
		gap: 20,
	},
	headerTitle: {
		fontSize: 14,
		textAlign: "center",
		fontWeight: "bold",
		marginBottom: 2,
		direction: "rtl",
	},
	headerTitle2: {
		fontSize: 8,
		textAlign: "center",
		fontWeight: "bold",
	},
	headerTitleFactor: {
		fontSize: 10,
		textAlign: "center",
		fontWeight: "bold",
		direction: "rtl",
	},
	table: {
		display: "table",
		width: "auto",
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#bdbdbd",
	},
	tableRow: {
		flexDirection: "row-reverse",
	},
	tableCol: {
		width: "25%",
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#bdbdbd",
		padding: 5,
		textAlign: "center",
	},
	tableFooter: {
		display: "table",
		width: "33.3%",
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#bdbdbd",
		marginTop: 5,
	},
	fullWidthCell: {
		width: "66.6%",
		height: "auto",
		textAlign: "right",
		padding: 5,
		borderStyle: "solid",
		borderColor: "none",
		borderTop: 2,
		borderTopColor: "#bdbdbd",
		fontSize: 10,
		position: "absolute",
		right: 0,
		top: 0,
		marginTop: 5,
	},
	tableColFooter: {
		width: "50%",
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#bdbdbd",
		padding: 5,
		textAlign: "center",
	},
	tableHeader: {
		fontWeight: "bold",
		backgroundColor: "#f3f3f3",
	},
	image: {
		width: 80, // Width of the image
		height: 80, // Height of the image
		alignSelf: "center", // Center the image
	},
	headerContent: {
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		gap: 2,
	},
	headerContentFactor: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		gap: 2,
		padding: 3,
	},
	owner: {
		fontSize: 8,
		flexDirection: "row-reverse",
		gap: 2,
	},
	address: {
		fontSize: 8,
	},
	row: {
		width: "100%",
		// backgroundColor: "red",
		flexDirection: "row-reverse",
		alignItems: "center",
		justifyContent: "space-between",
		border: "1px solid #bdbdbd",
		padding: 2,
		marginBottom: 1,
	},
	signature: {
		width: "100%",
		// backgroundColor: "red",
		flexDirection: "row-reverse",
		alignItems: "center",
		justifyContent: "space-around",
		padding: 20,
		marginBottom: 1,
	},
});

const Header = ({ profile }) => (
	<View style={styles.header}>
		<Image style={styles.image} src="logo.png" />
		<View style={styles.headerContent}>
			<Text style={styles.headerTitle}>{profile?.titleOne}</Text>
			<Text style={styles.headerTitle2}>{profile?.titleTwo}</Text>
			<View style={styles.owner}>
				<Text>{profile?.owners ? profile?.owners[0] : ""}</Text>
				<Text>{profile?.phones ? profile?.phones[0] : ""}</Text>
				<Text>{profile?.phones ? profile?.phones[1] : ""}</Text>
				<Text>{profile?.phones ? profile?.phones[2] : ""}</Text>
			</View>
			<View style={styles.owner}>
				<Text>{profile?.owners ? profile?.owners[1] : ""}</Text>
				<Text>{profile?.phones ? profile?.phones[3] : ""}</Text>
				<Text>{profile?.phones ? profile?.phones[4] : ""}</Text>
				<Text>{profile?.phones ? profile?.phones[5] : ""}</Text>
			</View>
			<Text style={styles.address}>{profile?.address}</Text>
		</View>
		<Image style={styles.image} src="pharmacy.png" />
	</View>
);
const CheckRow = ({ title, type }) => (
	<View style={styles.row}>
		<View style={styles.headerContentFactor}>
			<Text style={styles.headerTitleFactor}>{title}</Text>
			<Text style={styles.headerTitleFactor}>{type}: </Text>
		</View>
	</View>
);
const Footer = () => (
	<View style={styles.row}>
		<View style={styles.signature}>
			<Text style={styles.headerTitleFactor}>امضاء مدیریت </Text>
			<Text style={styles.headerTitleFactor}>امضاء خزانه دار </Text>
			<Text style={styles.headerTitleFactor}>امضاء گیرنده </Text>
		</View>
	</View>
);
const Brand = () => (
	<View style={styles.tableFullRow}>
		<View style={styles.headerContentFactor}>
			<Text style={styles.headerTitleFactor}>0790883362</Text>
			<Text style={styles.headerTitleFactor}>تماس با ما:</Text>
		</View>
		<View style={styles.headerContentFactor}>
			<Text style={styles.headerTitleFactor}>سیستم حسابداری اکسیجن</Text>
		</View>
	</View>
);
// Step 5: Create the PDF Document Component
const PdfCheck = ({ checkData }) => {
	const [profile, setProfile] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		const activeProfile = window.localStorage.getItem("profile");
		setProfile(JSON.parse(activeProfile));
		setIsLoading(false); // Set loading to false after fetching the profile
	}, []);

	// Show a loader until the profile is loaded and data is available
	if (isLoading || !checkData) {
		return (
			<div style={{ textAlign: "center", padding: "20px" }}>Loading PDF...</div>
		);
	}
	return (
		<PDFViewer width="100%" height="600px">
			<Document
				title={
					checkData?.checkType === "Check_In" ? "سند دریافتی" : "سند پرداختی"
				}
				author="TechLand">
				<Page size="A4" style={styles.page}>
					<Header profile={profile} />
					<CheckRow
						title={checkData?.checkInNumber || checkData?.checkOutNumber}
						type="شماره"
					/>
					<CheckRow
						title={checkData?.checkType === "Check_In" ? "دریافتی" : "پرداختی"}
						type="چک"
					/>
					<CheckRow title={checkData?.customer?.fullName} type="طرف حساب" />
					<CheckRow title={checkData?.date} type="تاریخ" />
					<CheckRow title={checkData?.amount} type="مبلغ" />
					<CheckRow title={checkData?.amount / 2} type="مناصفه" />
					<CheckRow title={checkData?.description} type="توضیحات" />
					<Footer />
					<Brand />
				</Page>
			</Document>
		</PDFViewer>
	);
};

export default PdfCheck;
