import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { CUSTOMERS, DRUGS, REPORTFACTOR } from "../../GraphQL/Query";
import { Loader } from "../StaticComponents/Loader";
import {
	Button,
	Input,
	Space,
	Tag,
	Select,
	InputNumber,
	Modal,
	Spin,
} from "antd";
import { PlusOutlined, PrinterOutlined } from "@ant-design/icons";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import { MONTHS } from "../../Constant/const";
import Content from "../StaticComponents/Content";
import HeaderContent from "../StaticComponents/HeaderContent";
import TableComponent from "../StaticComponents/TableComponent";
const { Option } = Select;
export function FactorReports() {
	const date = new DateObject({
		calendar: persian,
		locale: persian_fa,
		format: "YYYY-MM-DD",
	});
	const today = date.format();
	const client = useApolloClient();
	const navigate = useNavigate();
	const location = useLocation();
	const [factorReport, setFactorReport] = useState(false);
	const [selectedDrugId, setSelectedDrugId] = useState("");
	const [selectedCustomerId, setSelectedCustomerId] = useState("");
	const [searching, setSearching] = useState("");
	const [isLoader, setIsLoader] = useState(false);
	const [factorReports, setFactorReports] = useState([]);
	const [drugs, setDrugs] = useState([]);
	const [customers, setCustomers] = useState([]);
	const [userRole, setUserRole] = useState("");
	const [selectedFactorType, setSelectedFactorType] = useState("");
	const [selectedPaymentType, setSelectedPaymentType] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const [page, setPage] = useState(1);
	if (location.pathname === "/factorReports") {
		location.pathname = "گزارش فاکتورها";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadDrugs();
		loadCustomers();
	}, [searchValue]);
	// columns
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "نام",
			dataIndex: "fullName",
			// dataIndex: ["factor", "fullName"],
			// render: (fullName, factor) => factor.customer?.fullName,
			// key: "fullName",
			// filteredValue: [searching],
			// onFilter: (value, factor) => {
			// 	return String(factor.customer?.fullName)
			// 		.toLocaleLowerCase()
			// 		.includes(value.toLocaleLowerCase());
			// },
			align: "center",
		},
		{
			title: "شماره",
			dataIndex: "factorNumber",
			key: "factorNumber",
			align: "center",
		},
		{
			title: "نوعیت بل",
			dataIndex: "factorType",
			key: "factorType",
			align: "center",
		},
		{
			title: "نوعیت پرداخت",
			dataIndex: "paymentType",
			key: "paymentType",
			align: "center",
		},
		{
			title: "مبلغ",
			dataIndex: "amount",
			key: "amount",
			align: "center",
		},
		{
			title: "تخفیف",
			dataIndex: "discount",
			key: "discount",
			align: "center",
		},
		{
			title: "تاریخ",
			dataIndex: "date",
			key: "date",
			align: "center",
		},
		{
			title: "عملیات",
			dataIndex: "action",
			key: "action",
			render: (record, factor) => {
				return (
					<Space>
						<PrinterOutlined
							onClick={() =>
								navigate("/printFactor", {
									state: { selectedFactor: factor._id },
								})
							}
						/>
					</Space>
				);
			},
			align: "center",
		},
	];
	// function for fetch drugs
	async function loadDrugs() {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: DRUGS,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: searchValue,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setDrugs([...res.data.getDrugs.drugs]);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	// function for fetch customers
	async function loadCustomers() {
		setIsLoader(true);
		try {
			const res = await client.query({
				query: CUSTOMERS,
				variables: {
					pageNumber: page,
					perPage: 10,
					searchItem: searchValue,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setCustomers([...res.data.getCustomers.customers]);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	// function for take report of factors
	const handleReportFactor = async (e) => {
		e.preventDefault();
		try {
			const res = await client.query({
				query: REPORTFACTOR,
				variables: {
					factorType:
						selectedFactorType !== "" ? selectedFactorType : undefined,
					paymentType:
						selectedPaymentType !== "" ? selectedPaymentType : undefined,
					factorNumber:
						Number(e.target.factorNumber.value) !== ""
							? Number(e.target.factorNumber.value)
							: undefined,
					customer: selectedCustomerId !== "" ? selectedCustomerId : undefined,
					drug: selectedDrugId !== "" ? selectedDrugId : undefined,
					startAmount:
						Number(e.target.startAmount.value) !== ""
							? Number(e.target.startAmount.value)
							: undefined,
					endAmount:
						Number(e.target.endAmount.value) !== ""
							? Number(e.target.endAmount.value)
							: undefined,
					startDate:
						e.target.startDate.value !== "undefined-undefined-undefined"
							? e.target.startDate.value.replace(
									/[\u0660-\u0669\u06f0-\u06f9]/g,
									function (a) {
										return a.charCodeAt(0) & 0xf;
									}
							  )
							: undefined,
					endDate:
						e.target.endDate.value !== "undefined-undefined-undefined"
							? e.target.endDate.value.replace(
									/[\u0660-\u0669\u06f0-\u06f9]/g,
									function (a) {
										return a.charCodeAt(0) & 0xf;
									}
							  )
							: undefined,
				},
				fetchPolicy: "no-cache",
			});
			setFactorReports([...res.data.reportFactors]);
		} catch (error) {
			console.log(error);
		}
		setFactorReport(!factorReport);
		setSelectedFactorType();
		setSelectedPaymentType();
		setSelectedCustomerId();
		setSelectedDrugId();
		setSearching("");
	};
	// factorsReport data
	const data = factorReports.map((factor, index) => ({
		key: index,
		num: index + 1,
		id: factor._id,
		fullName: factor.customer?.fullName,
		factorNumber: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{factor.buyFactorNumber || factor.sellFactorNumber}
			</Tag>
		),
		factorType: (
			<Tag
				bordered={false}
				color={factor.factorType === "Sell" ? "success" : "error"}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{factor.factorType === "Sell" ? "فروش" : "خرید"}
			</Tag>
		),
		paymentType: (
			<Tag
				bordered={false}
				color={factor.paymentType === "Cash" ? "success" : "error"}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{factor.paymentType === "Cash" ? "نقده" : "نسیه"}
			</Tag>
		),
		amount: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{factor.amount}
			</Tag>
		),
		discount: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{factor.discount}
			</Tag>
		),
		date: factor.date,
	}));
	return (
		<div className="container">
			{/* factor report modal */}
			{factorReport && (
				<Modal
					title="گزارش فاکتور"
					open={factorReport}
					onCancel={() => {
						setFactorReport(!factorReport);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<form onSubmit={handleReportFactor}>
						<Space direction="vertical">
							{/* select box for search factor type */}
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="نوعیت فاکتور"
								className="w-full"
								name="factorType"
								onChange={(value) => {
									setSelectedFactorType(value);
								}}
								size="large">
								<Option value={"Buy"} key={"Buy"} style={{ direction: "rtl" }}>
									خرید
								</Option>
								<Option
									value={"Sell"}
									key={"Sell"}
									style={{ direction: "rtl" }}>
									فروش
								</Option>
							</Select>
							{/* selectbox for search payment type */}
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="نوعیت دریافت"
								className="w-full"
								name="paymentType"
								onChange={(value) => {
									setSelectedPaymentType(value);
								}}
								size="large">
								<Option
									value={"Cash"}
									key={"Cash"}
									style={{ direction: "rtl" }}>
									نقده
								</Option>
								<Option
									value={"No_Cash"}
									key={"No_Cash"}
									style={{ direction: "rtl" }}>
									نسیه
								</Option>
							</Select>
							<InputNumber
								placeholder="شماره فاکتور"
								id="factorNumber"
								name="factorNumber"
								size="large"
								min={1}
								className="w-full"
							/>
							{/* selectbox for search customers */}
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="نام مشتری"
								className="w-full"
								name="customerName"
								onChange={(value) => {
									setSelectedCustomerId(value);
								}}
								onSearch={(value) => {
									setSearchValue(value);
									loadCustomers(value);
									setSelectedCustomerId(value);
								}}
								notFoundContent={
									isLoader ? (
										<Spin size="small" />
									) : (
										<Tag color="error" className="w-full text-center">
											موردی یافت نشد
										</Tag>
									)
								}
								size="large">
								{customers.map((customer) => (
									<Option
										key={customer._id}
										value={customer._id}
										style={{ direction: "rtl" }}>
										{customer.fullName +
											" " +
											customer.company +
											" " +
											customer.city}
									</Option>
								))}
							</Select>
							{/* selectbox for search drugs */}
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="نام دارو"
								className="w-full"
								name="drugName"
								onChange={(value) => {
									setSelectedDrugId(value);
								}}
								onSearch={(value) => {
									setSearchValue(value);
									loadDrugs(value);
									setSelectedDrugId(value);
								}}
								notFoundContent={
									isLoader ? (
										<Spin size="small" />
									) : (
										<Tag color="error" className="w-full text-center">
											موردی یافت نشد
										</Tag>
									)
								}
								size="large">
								{drugs.map((drug, index) => (
									<Option
										key={drug._id}
										value={drug._id}
										style={{ direction: "rtl" }}>
										{drug?.drugType?.title +
											" " +
											drug.name +
											" " +
											drug.company}
									</Option>
								))}
							</Select>
							<Space direction="horizontal">
								<InputNumber
									id="startAmount"
									name="startAmount"
									size="large"
									placeholder="مبلغ فاکتور"
									min={1}
									className="inputRep"
								/>
								<span>تا</span>
								<InputNumber
									id="endAmount"
									name="endAmount"
									size="large"
									placeholder="مبلغ فاکتور"
									min={1}
									className="inputRep"
								/>
							</Space>
							<Space direction="horizontal">
								<DatePicker
									calendar={persian}
									locale={persian_fa}
									months={MONTHS}
									calendarPosition="top-right"
									format="YYYY-MM-DD"
									inputClass="inputDateRep"
									id="startDate"
									name="startDate"
									value={today}
								/>
								<span>تا</span>
								<DatePicker
									calendar={persian}
									locale={persian_fa}
									months={MONTHS}
									calendarPosition="top-right"
									format="YYYY-MM-DD"
									inputClass="inputDateRep"
									id="endDate"
									name="endDate"
									value={today}
								/>
							</Space>
							<Space
								style={{
									width: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Button
									type="primary"
									htmlType="submit"
									size="large"
									icon={<PlusOutlined />}
									className="btn">
									گزارش فاکتور
								</Button>
							</Space>
						</Space>
					</form>
				</Modal>
			)}
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<HeaderContent>
					<Input.Search
						placeholder="جستجوی مشتری"
						allowClear
						size="large"
						onChange={(e) => {
							setSearching(e.target.value);
						}}
						style={{
							width: 250,
							direction: "ltr",
							textAlign: "right",
						}}
					/>
					<Button
						type="primary"
						size="large"
						icon={<PlusOutlined />}
						onClick={() => {
							setFactorReport(!factorReport);
						}}
						className="btn">
						گزارش فاکتور
					</Button>
					<Button
						type="primary"
						size="large"
						icon={<PrinterOutlined />}
						onClick={() =>
							navigate("/factorReportPrint", {
								state: { selectedFactorReport: factorReports },
							})
						}
						className="btn">
						چاپ
					</Button>
				</HeaderContent>
				<TableComponent
					data={data}
					columns={columns}
					loading={isLoader}
					rowKey="id"
					pagination={{
						pageSize: 10, // Number of records per page
						// total: totalCount,
						onChange: (current) => {
							setPage(current); // Update page state
							handleReportFactor(current); // Fetch the new page data
						},
						current: page, // Track the current page
						showLessItems: true, // Optional: Keep pagination UI minimal
					}}
				/>
			</Content>
		</div>
	);
}
