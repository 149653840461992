import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { SALARIES } from "../../GraphQL/Query";
import { ADDNEWSALARY, DELETESALARY } from "../../GraphQL/Mutation";
import {
	Button,
	Input,
	Space,
	Tag,
	InputNumber,
	Modal,
	Popconfirm,
	notification,
	Spin,
} from "antd";
import {
	PlusOutlined,
	PrinterOutlined,
	DeleteOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightLong } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import { MONTHS } from "../../Constant/const";
import Content from "../StaticComponents/Content";
import HeaderContent from "../StaticComponents/HeaderContent";
import TableComponent from "../StaticComponents/TableComponent";
export function SelectedEmployee() {
	const date = new DateObject({
		calendar: persian,
		locale: persian_fa,
		format: "YYYY-MM-DD",
	});
	const today = date.format();
	const navigate = useNavigate();
	const location = useLocation();
	const client = useApolloClient();
	const [salaries, setSalaries] = useState([]);
	const [referesh, setReferesh] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [deposit, setDeposit] = useState(false);
	const [withdraw, setWithdraw] = useState(false);
	const [userRole, setUserRole] = useState("");
	const [storing, setStoring] = useState(false);
	const [searching, setSearching] = useState("");
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);

	// to take employee id from previous page
	const receivedData = location.state?.employeeId || {};
	if (location.pathname === "/selectedEmployee") {
		location.pathname = "لیست معاشات";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadEmployeeSalaries();
	}, [referesh, page, searching]);

	// function for load salaries
	async function loadEmployeeSalaries() {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: SALARIES,
				variables: {
					employeeId: receivedData,
					pageNumber: page,
					perPage: 10,
					searchItem: Number(searching),
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setSalaries([...res.data.getSalaries.salaries]);
				setTotalCount(res.data.getSalaries.totalCount);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	// function for add new withdraw
	async function handleWithdraw(e) {
		e.preventDefault();
		setStoring(true);
		try {
			const res = await client.mutate({
				mutation: ADDNEWSALARY,
				variables: {
					employeeId: receivedData,
					amount: Number(e.target.amount.value),
					date: e.target.inputDate.value,
					description: e.target.description.value,
					salaryType: "Withdraw",
				},
			});
			if (res) {
				setWithdraw(!withdraw);
				setReferesh(!referesh);
				notification.success({
					message: "برداشتی با موفقیت اضافه گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در افزودن برداشتی",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	// function for newSallary
	async function handleDeposit(e) {
		e.preventDefault();
		setStoring(true);
		try {
			const res = await client.mutate({
				mutation: ADDNEWSALARY,
				variables: {
					employeeId: receivedData,
					amount: Number(e.target.amount.value),
					date: e.target.inputDate.value,
					description: e.target.description.value,
					salaryType: "Deposit",
				},
			});
			if (res) {
				setDeposit(!deposit);
				notification.success({
					message: "معاش با موفقیت اضافه گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "خطا در افزودن معاش",
				placement: "topRight",
				duration: 3,
			});
		} finally {
			setStoring(false);
		}
	}
	// function for delete salary | withdraw
	async function handleDeleteTokenMoney(employeeId) {
		try {
			const res = await client.mutate({
				mutation: DELETESALARY,
				variables: {
					id: employeeId,
				},
			});
			if (res) {
				setReferesh(!referesh);
				notification.success({
					message: "معاش با موفقیت حذف گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (err) {
			console.log(err);
			notification.success({
				message: "خطا در حذف معاش",
				placement: "topRight",
				duration: 3,
			});
		}
	}
	// columns
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "شماره",
			dataIndex: "salaryNumber",
			key: "salaryNumber",
			align: "center",
		},
		{
			title: "نام",
			dataIndex: "fullName",
			// dataIndex: ["fullName", "employeeId"],
			key: "fullName",
			align: "center",
		},
		{
			title: "مبلغ",
			dataIndex: "amount",
			key: "amount",
			align: "center",
		},
		{
			title: "تاریخ",
			dataIndex: "date",
			key: "date",
			align: "center",
		},
		{
			title: "توضیحات",
			dataIndex: "description",
			key: "description",
			align: "center",
		},
		{
			title: "نوعیت",
			dataIndex: "salaryType",
			key: "salaryType",
			align: "center",
		},
		{
			title: userRole === "Admin" && "عملیات",
			dataIndex: "action",
			key: "action",
			render: (_, record) => {
				return (
					userRole === "Admin" && (
						<Space>
							<Popconfirm
								title="آیا مطمین هستید میخواهید حذف نمایید؟"
								onConfirm={() => handleDeleteTokenMoney(record.id)}
								okText="بله"
								cancelText="انصراف"
								placement="topLeft">
								<DeleteOutlined />
							</Popconfirm>
						</Space>
					)
				);
			},
			align: "center",
		},
	];
	// salaries data
	const data = salaries.map((salary, index) => ({
		key: index,
		num: index + 1,
		id: salary._id,
		salaryNumber: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{salary.salaryNumber}
			</Tag>
		),
		fullName: salary.employeeId.fullName,
		amount: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{salary.amount}
			</Tag>
		),
		date: salary.date,
		description: salary.description,
		salaryType: (
			<Tag
				bordered={false}
				color={salary.salaryType === "Deposit" ? "success" : "error"}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{salary.salaryType === "Deposit" ? "معاش" : "برداشتی"}
			</Tag>
		),
	}));
	return (
		<div className="container">
			{/* add new withdraw */}
			{withdraw && (
				<Modal
					title="ثبت برداشتی کارمند"
					open={withdraw}
					onCancel={() => {
						setWithdraw(!withdraw);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={handleWithdraw}>
							<Space direction="vertical">
								<InputNumber
									id="amount"
									name="amount"
									size="large"
									placeholder="برداشتی کارمند"
									min={0}
									style={{ width: "100%" }}
								/>
								<Space className="inputDate-container">
									<DatePicker
										calendar={persian}
										locale={persian_fa}
										months={MONTHS}
										calendarPosition="top-right"
										format="YYYY-MM-DD"
										inputClass="inputDate"
										value={today}
										id="inputDate"
										name="inputDate"
									/>
								</Space>
								<Input
									id="description"
									name="description"
									size="large"
									placeholder="توضیحات"
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}>
										ثبت برداشتی
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			{/* add new deposit */}
			{deposit && (
				<Modal
					title="ثبت معاش کارمند"
					open={deposit}
					onCancel={() => {
						setDeposit(!deposit);
					}}
					footer={null}
					style={{ direction: "rtl" }}
					centered
					width={400}>
					<Spin spinning={storing} tip="در حال ذخیره سازی ...">
						<form onSubmit={handleDeposit}>
							<Space direction="vertical">
								<InputNumber
									id="amount"
									name="amount"
									size="large"
									placeholder="معاش کارمند"
									min={0}
									style={{ width: "100%" }}
								/>
								<Space className="inputDate-container">
									<DatePicker
										calendar={persian}
										locale={persian_fa}
										months={MONTHS}
										calendarPosition="top-right"
										format="YYYY-MM-DD"
										inputClass="inputDate"
										value={today}
										id="inputDate"
										name="inputDate"
									/>
								</Space>
								<Input
									id="description"
									name="description"
									size="large"
									placeholder="توضیحات"
								/>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}>
										ثبت معاش
									</Button>
								</Space>
							</Space>
						</form>
					</Spin>
				</Modal>
			)}
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<HeaderContent>
					<FontAwesomeIcon
						icon={faRightLong}
						style={{ fontSize: 17, cursor: "pointer" }}
						onClick={() => navigate(-1)}
					/>
					<Input.Search
						placeholder="جستجوی مصرف"
						allowClear
						size="large"
						onChange={(e) => {
							setSearching(e.target.value);
						}}
						style={{
							width: 250,
							direction: "ltr",
							textAlign: "right",
						}}
					/>
					{userRole === "Admin" && (
						<>
							<Button
								type="primary"
								size="large"
								icon={<PlusOutlined />}
								onClick={() => {
									setWithdraw(!withdraw);
								}}
								className="btn">
								ثبت برداشتی
							</Button>
							<Button
								type="primary"
								size="large"
								icon={<PlusOutlined />}
								onClick={() => {
									setDeposit(!deposit);
								}}
								className="btn">
								ثبت معاش
							</Button>
						</>
					)}
					<Button
						type="primary"
						size="large"
						icon={<PrinterOutlined />}
						onClick={() =>
							navigate("/selectedEmployeePrint", {
								state: { employeeId: receivedData },
							})
						}
						className="btn">
						چاپ
					</Button>
				</HeaderContent>
				<TableComponent
					data={data}
					columns={columns}
					loading={isLoader}
					rowKey="id"
					pagination={{
						pageSize: 10, // Number of records per page
						total: totalCount,
						onChange: (current) => {
							setPage(current); // Update page state
							loadEmployeeSalaries(current); // Fetch the new page data
						},
						current: page, // Track the current page
						showLessItems: true, // Optional: Keep pagination UI minimal
					}}
				/>
			</Content>
		</div>
	);
}
