import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import "../../Styles/Home/Home.css";
import { Card } from "../StaticComponents/Card";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { STATISTIC } from "../../GraphQL/Query";
import { useApolloClient } from "@apollo/client";
import {
	ContactsOutlined,
	ContainerOutlined,
	ExperimentOutlined,
	FileOutlined,
	FileTextOutlined,
	SolutionOutlined,
	TeamOutlined,
} from "@ant-design/icons";
import Content from "../StaticComponents/Content";
export function Home() {
	const client = useApolloClient();
	const navigate = useNavigate();
	const location = useLocation();
	const [isloader, setIsloader] = useState(false);
	const [statistic, setStatistic] = useState({});
	if (location.pathname == "/home" || location.pathname == "/") {
		location.pathname = "خانه";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadStatistic();
	}, []);
	const loadStatistic = async () => {
		try {
			setIsloader(true);
			const res = await client.query({
				query: STATISTIC,
				variables: {},
				fetchPolicy: "no-cache",
			});
			if (res.data.getStatistic) {
				setStatistic(res.data.getStatistic);
				setIsloader(false);
			}
		} catch (error) {
			console.log(error);
			setIsloader(false);
		}
	};
	return (
		<div>
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<div className="w-full h-full flex flex-row flex-wrap justify-center lg:gap-10 xl:gap-12 items-center gap-5">
					<Card
						cardTitle="دارو ها"
						cardLoader={isloader}
						cardCount={statistic.drugCount}
						cardIcon={
							<ExperimentOutlined className="w-16 h-16 rounded-full text-[#00AC4F] bg-[#E5FFF0] text-xl flex flex-col justify-center items-center cursor-pointer" />
						}
						onClick={() => navigate("/drugs")}
					/>
					<Card
						cardTitle="فاکتور ها"
						cardLoader={isloader}
						cardCount={statistic.factorCount}
						cardIcon={
							<FileTextOutlined className="w-16 h-16 rounded-full text-[#00AC4F] bg-[#E5FFF0] text-xl flex flex-col justify-center items-center cursor-pointer" />
						}
						onClick={() => navigate("/factorList")}
					/>
					<Card
						cardTitle="مشتریان"
						cardLoader={isloader}
						cardCount={statistic.customerCount}
						cardIcon={
							<SolutionOutlined className="w-16 h-16 rounded-full text-[#00AC4F] bg-[#E5FFF0] text-xl flex flex-col justify-center items-center cursor-pointer" />
						}
						onClick={() => navigate("/customers")}
					/>
					<Card
						cardTitle="اسناد"
						cardLoader={isloader}
						cardCount={statistic.checkCount}
						cardIcon={
							<FileOutlined className="w-16 h-16 rounded-full text-[#00AC4F] bg-[#E5FFF0] text-xl flex flex-col justify-center items-center cursor-pointer" />
						}
						onClick={() => navigate("/checks")}
					/>
					<Card
						cardTitle="گدام ها"
						cardLoader={isloader}
						cardCount={statistic.stackCount}
						cardIcon={
							<ContainerOutlined className="w-16 h-16 rounded-full text-[#00AC4F] bg-[#E5FFF0] text-xl flex flex-col justify-center items-center cursor-pointer" />
						}
						onClick={() => navigate("/stacks")}
					/>
					<Card
						cardTitle="کاربران"
						cardLoader={isloader}
						cardCount={statistic.userCount}
						cardIcon={
							<TeamOutlined className="w-16 h-16 rounded-full text-[#00AC4F] bg-[#E5FFF0] text-xl flex flex-col justify-center items-center cursor-pointer" />
						}
						onClick={() => navigate("/users")}
					/>
				</div>
			</Content>
		</div>
	);
}
