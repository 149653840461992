import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Loader } from "../StaticComponents/Loader";
import PdfComponent from "../Pdf/PdfComponent";
export function PrintRoznamcha() {
	const location = useLocation();
	const navigate = useNavigate();
	const [isLoader, setIsLoader] = useState(false);
	const [pages, setPages] = useState([]);
	const receivedData = location.state?.journals || {};
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadReports();
	}, []);
	const loadReports = () => {
		setIsLoader(true);
		if (receivedData) {
			const modifiedData = receivedData.map((journal, index) => ({
				col1: index + 1,
				col2: journal?.bellNumber,
				col3:
					journal?.bellType === "Check_In"
						? "سند دریافتی"
						: journal?.bellType === "Check_Out"
						? "سند پرداختی"
						: journal?.bellType === "Buy"
						? "فاکتور خرید"
						: journal?.bellType === "Sell"
						? "فاکتور فروش"
						: journal?.bellType === "salary"
						? "معاش"
						: journal?.bellType === "consume"
						? "مصارف"
						: "",
				col4:
					journal?.customer?.fullName ||
					journal?.employee?.fullName ||
					journal.user?.userName,
				col5: parseFloat(journal?.amount),
				col6: journal?.date,
			}));
			// Split data into pages
			const rowsPerPage = 15;
			const splitPages = splitDataIntoPages(modifiedData, rowsPerPage);
			setPages(splitPages);
			setIsLoader(false);
		}
	};
	const titles = ["#", "شماره", "نوعیت", "طرف حساب", "مبلغ", "تاریخ"];
	// Helper function to split data into pages
	const splitDataIntoPages = (data, rowsPerPage) => {
		if (!Array.isArray(data)) {
			console.error("Invalid input data for splitDataIntoPages:", data);
			return [];
		}

		const pages = [];
		for (let i = 0; i < data.length; i += rowsPerPage) {
			pages.push(data.slice(i, i + rowsPerPage));
		}

		console.log("Pages generated:", pages); // Debug output
		return pages;
	};
	return (
		<div className="conatiner">
			{isLoader && <Loader />}
			{/* Render PdfComponent only when pages data is available */}
			{!isLoader && pages.length > 0 && (
				<PdfComponent
					data={pages}
					titles={titles}
					pdfTitle="داروی انتخاب شده"
					orientation="landscape"
				/>
			)}
			{/* <div className="file-container">
        <PrintHeader type="روزنامچه" />
        <div className="table-container">
          <table className="table">
            <thead className="tableHead-row" >
              <tr className="print-header">
                <th>ردیف</th>
                <th>شماره سند / فاکتور</th>
                <th>نوعیت سند / فاکتور</th>
                <th>طرف حساب</th>
                <th>مبلغ</th>
                <th>تاریخ</th>
              </tr>
            </thead>
            <tbody>
              {roznamchas.map((roz, index) => {
                return (
                  <tr id={roz.id} key={roz.id} className="table-row">
                    <td>{index + 1}</td>
                    <td>{roz.bellNumber}</td>
                    <td>
                    <Tag
          color={
            roz.bellType === "Sell" || roz.bellType === "Check_In"
              ? "success"
              : "error"
          }
          bordered={false}
          style={{
          fontSize:15,
          fontFamily:"B-NAZANIN",
          }}
        >
          {roz.bellType === "Check_In"
            ? "سند دریافتی"
            : roz.bellType === "Check_Out"
            ? "سند پرداختی"
            : roz.bellType === "Buy"
            ? "فاکتور خرید"
            : roz.bellType === "Sell"
            ? "فاکتور فروش"
            : roz.bellType === "salary"
            ? "معاش"
            : roz.bellType === "consume"
            ? "مصارف"
            : ""}
        </Tag>
                    </td>
                    <td>{roz.customer?.fullName || roz.employee?.fullName || roz.user?.userName}</td>
                    <td>{roz.amount}</td>
                    <td>{roz.date}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="printFooter">
          سیستم مدیریتی اکسیجن - شماره تماس ۰۷۹۰۸۸۳۳۶۲
        </div>
      </div> */}
		</div>
	);
}
