import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { REPOSITORY, ROZNAMCHA } from "../../GraphQL/Query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarOfLife, faWallet } from "@fortawesome/free-solid-svg-icons";
import { Button, Dropdown, Input, Table, Space, Tag } from "antd";
import {
	AlignRightOutlined,
	CaretDownOutlined,
	PrinterOutlined,
} from "@ant-design/icons";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import { MONTHS } from "../../Constant/const";
import Content from "../StaticComponents/Content";
import HeaderContent from "../StaticComponents/HeaderContent";
import TableComponent from "../StaticComponents/TableComponent";
export function Roznamcha() {
	const date = new DateObject({
		calendar: persian,
		locale: persian_fa,
		format: "YYYY-MM-DD",
	});
	const today = date.format();
	// const convert = (date, format = "YYYY-MM-DD") => {
	// 	let object = { date, format };

	// 	setSelectedDate({
	// 		persian: new DateObject(object).convert(persian, persian_fa).format(),
	// 		jsDate: date.toDate(),
	// 		...object,
	// 	});
	// };
	const client = useApolloClient();
	const navigate = useNavigate();
	const location = useLocation();
	const [searching, setSearching] = useState("");
	const [bellType, setBellType] = useState("");
	const [roznamchas, setRoznamchas] = useState([]);
	const [repository, setRepository] = useState();
	const [belance, setBelance] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [selectedDate, setSelectedDate] = useState(today);
	const [userRole, setUserRole] = useState("");
	const [totalCount, setTotalCount] = useState(1);
	const [page, setPage] = useState(1);
	if (location.pathname === "/roznamcha") {
		location.pathname = "روزنامچه";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		allRoznamcha();
		handleBelance();
	}, [today, selectedDate, searching, page, bellType]);
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "شماره بل",
			dataIndex: "bellNumber",
			key: "bellNumber",
			// render: (bellNumber, roz) => (
			// 	<Tag
			// 		bordered={false}
			// 		style={{
			// 			fontSize: 15,
			// 			fontFamily: "B-NAZANIN",
			// 		}}>
			// 		{roz.bellNumber}
			// 	</Tag>
			// ),
			// filteredValue: [searching],
			// onFilter: (value, roz) => {
			// 	return (
			// 		String(roz.bellNumber).includes(value) ||
			// 		String(roz.bellType).includes(value)
			// 	);
			// },
			align: "center",
		},
		{
			title: "نوعیت بل",
			dataIndex: "bellType",
			key: "bellType",
			// render: (bellType, roz) => (
			// 	<Tag
			// 		color={
			// 			roz.bellType === "Sell" || roz.bellType === "Check_In"
			// 				? "success"
			// 				: "error"
			// 		}
			// 		bordered={false}
			// 		style={{
			// 			fontSize: 15,
			// 			fontFamily: "B-NAZANIN",
			// 		}}>
			// 		{roz.bellType === "Check_In"
			// 			? "سند دریافتی"
			// 			: roz.bellType === "Check_Out"
			// 			? "سند پرداختی"
			// 			: roz.bellType === "Buy"
			// 			? "فاکتور خرید"
			// 			: roz.bellType === "Sell"
			// 			? "فاکتور فروش"
			// 			: roz.bellType === "salary"
			// 			? "معاش"
			// 			: roz.bellType === "consume"
			// 			? "مصارف"
			// 			: ""}
			// 	</Tag>
			// ),
			align: "center",
		},
		{
			title: "طرف حساب",
			// dataIndex: ["customer", "employee", "user"],
			dataIndex: "person",
			key: "person",
			// render: (person, roz) =>
			// 	roz.customer?.fullName || roz.employee?.fullName || roz.user?.userName,
			align: "center",
		},
		{
			title: "مبلغ",
			dataIndex: "amount",
			key: "amount",
			// render: (amount, roz) => (
			// 	<Tag
			// 		bordered={false}
			// 		style={{
			// 			fontSize: 15,
			// 			fontFamily: "B-NAZANIN",
			// 		}}>
			// 		{roz.amount}
			// 	</Tag>
			// ),
			align: "center",
		},
		{
			title: "تاریخ",
			dataIndex: "date",
			key: "date",
			// render: (date, roz) => roz.date,
			align: "center",
		},
	];
	// function for fetch journals
	const allRoznamcha = async () => {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: ROZNAMCHA,
				variables: {
					date: !selectedDate ? today : selectedDate,
					pageNumber: page,
					perPage: 10,
					searchItem: Number(searching),
					bellType: bellType === "" ? null : bellType,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setRoznamchas([...res.data.getRoznamcha.roznamchas]);
				setTotalCount(res.data.getRoznamcha?.totalCount);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	};
	// function for handle Balance
	const handleBelance = async () => {
		try {
			const res = await client.query({
				query: REPOSITORY,
				variables: {},
				fetchPolicy: "no-cache",
			});
			setRepository(res.data.getRepository);
		} catch (error) {
			console.log(error);
		}
	};
	const items = [
		{
			key: "1",
			label: (
				<Button
					type="link"
					block="true"
					icon={<AlignRightOutlined />}
					paddingBlock="0"
					onClick={() => {
						setBellType("");
					}}
					style={{
						color: "rgba(0, 0, 0, 0.88)",
						direction: "rtl",
						textAlign: "right",
					}}>
					همه
				</Button>
			),
		},
		{
			key: "4",
			label: (
				<Button
					type="link"
					block="true"
					icon={<AlignRightOutlined />}
					onClick={() => {
						setBellType("Check_In");
					}}
					style={{
						color: "rgba(0, 0, 0, 0.88)",
						direction: "rtl",
						textAlign: "right",
					}}>
					سند دریافتی
				</Button>
			),
		},
		{
			key: "5",
			label: (
				<Button
					type="link"
					block="true"
					icon={<AlignRightOutlined />}
					onClick={() => {
						setBellType("Check_Out");
					}}
					style={{
						color: "rgba(0, 0, 0, 0.88)",
						direction: "rtl",
						textAlign: "right",
					}}>
					سند پرداختی
				</Button>
			),
		},
		{
			key: "2",
			label: (
				<Button
					type="link"
					block="true"
					icon={<AlignRightOutlined />}
					onClick={() => {
						setBellType("Sell");
					}}
					style={{
						color: "rgba(0, 0, 0, 0.88)",
						direction: "rtl",
						textAlign: "right",
					}}>
					فروش
				</Button>
			),
		},
		{
			key: "3",
			label: (
				<Button
					type="link"
					block="true"
					icon={<AlignRightOutlined />}
					onClick={() => {
						setBellType("Buy");
					}}
					style={{
						color: "rgba(0, 0, 0, 0.88)",
						direction: "rtl",
						textAlign: "right",
					}}>
					خرید
				</Button>
			),
		},
		{
			key: "6",
			label: (
				<Button
					type="link"
					block="true"
					icon={<AlignRightOutlined />}
					onClick={() => {
						setBellType("salary");
					}}
					style={{
						color: "rgba(0, 0, 0, 0.88)",
						direction: "rtl",
						textAlign: "right",
					}}>
					معاش
				</Button>
			),
		},
		{
			key: "7",
			label: (
				<Button
					type="link"
					block="true"
					icon={<AlignRightOutlined />}
					onClick={() => {
						setBellType("consume");
					}}
					style={{
						color: "rgba(0, 0, 0, 0.88)",
						direction: "rtl",
						textAlign: "right",
					}}>
					مصارف
				</Button>
			),
		},
	];
	// roznamchas data
	const data = roznamchas.map((roznamcha, index) => ({
		key: index,
		num: index + 1,
		id: roznamcha._id,
		bellNumber: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{roznamcha.bellNumber}
			</Tag>
		),
		bellType: (
			<Tag
				color={
					roznamcha.bellType === "Sell" || roznamcha.bellType === "Check_In"
						? "success"
						: "error"
				}
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{roznamcha.bellType === "Check_In"
					? "سند دریافتی"
					: roznamcha.bellType === "Check_Out"
					? "سند پرداختی"
					: roznamcha.bellType === "Buy"
					? "فاکتور خرید"
					: roznamcha.bellType === "Sell"
					? "فاکتور فروش"
					: roznamcha.bellType === "salary"
					? "معاش"
					: roznamcha.bellType === "consume"
					? "مصارف"
					: ""}
			</Tag>
		),
		person:
			roznamcha.customer?.fullName ||
			roznamcha.employee?.fullName ||
			roznamcha.user?.userName,
		amount: (
			<Tag
				bordered={false}
				style={{
					fontSize: 15,
					fontFamily: "B-NAZANIN",
				}}>
				{roznamcha.amount}
			</Tag>
		),
		date: roznamcha.date,
	}));
	return (
		<div className="container">
			<Header address={location.pathname} />
			<SideBar />
			<Content>
				<HeaderContent>
					<Input.Search
						placeholder="جستجوی شماره (سند ، فاکتور)"
						allowClear
						size="large"
						onSearch={(value) => {
							setSearching(value);
						}}
						onChange={(e) => {
							setSearching(e.target.value);
						}}
						style={{
							width: 250,
							direction: "ltr",
							textAlign: "right",
						}}
					/>
					<Dropdown
						menu={{ items }}
						placement="bottom"
						arrow={{ pointAtCenter: true }}
						className="btn">
						<Button type="primary" size="large" icon={<CaretDownOutlined />}>
							انتخاب سند
						</Button>
					</Dropdown>
					<Space className="inputDate-container">
						<DatePicker
							calendar={persian}
							locale={persian_fa}
							months={MONTHS}
							calendarPosition="top-right"
							format="YYYY-MM-DD"
							inputClass="inputDateRoz"
							id="inputDate"
							name="inputDate"
							value={selectedDate.date}
							onChange={(value) =>
								setSelectedDate(
									`${value.year}-${
										value.month.toString().length === 1
											? `0${value.month}`
											: value.month
									}-${
										value.day.toString().length === 1
											? `0${value.day}`
											: value.day
									}`
								)
							}
							placeholder="تاریخ را انتخاب کنید"
						/>
					</Space>
					<Button
						type="primary"
						size="large"
						icon={<PrinterOutlined />}
						onClick={() =>
							navigate("/printRoznamcha", {
								state: { journals: roznamchas },
							})
						}
						className="btn">
						چاپ
					</Button>
					{userRole === "Admin" && (
						<FontAwesomeIcon
							icon={faWallet}
							style={{ fontSize: 40, color: " #1677ff", cursor: "pointer" }}
							onClick={() => {
								setBelance(!belance);
							}}
						/>
					)}
					{userRole === "Admin" &&
						(belance ? (
							<Tag
								bordered={false}
								color="success"
								style={{
									fontSize: 20,
									fontFamily: "B-NAZANIN",
									width: "200px",
									height: "40px",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									textAlign: "center",
									color: "#777",
								}}>
								{
									<Space>
										{repository === undefined
											? "در حال بررسی ..."
											: repository + " " + "افغانی"}
									</Space>
								}
							</Tag>
						) : (
							<Tag
								bordered={false}
								color="success"
								style={{
									fontSize: 20,
									fontFamily: "B-NAZANIN",
									width: "200px",
									height: "40px",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									textAlign: "center",
								}}>
								{
									<Space>
										<FontAwesomeIcon
											icon={faStarOfLife}
											style={{ fontSize: 15, color: "#777" }}
										/>
										<FontAwesomeIcon
											icon={faStarOfLife}
											style={{ fontSize: 15, color: "#777" }}
										/>
										<FontAwesomeIcon
											icon={faStarOfLife}
											style={{ fontSize: 15, color: "#777" }}
										/>
										<FontAwesomeIcon
											icon={faStarOfLife}
											style={{ fontSize: 15, color: "#777" }}
										/>
										<FontAwesomeIcon
											icon={faStarOfLife}
											style={{ fontSize: 15, color: "#777" }}
										/>
									</Space>
								}
							</Tag>
						))}
				</HeaderContent>
				<TableComponent
					data={data}
					columns={columns}
					loading={isLoader}
					rowKey="id"
					pagination={{
						pageSize: 10, // Number of records per page
						total: totalCount,
						onChange: (current) => {
							setPage(current); // Update page state
							allRoznamcha(current); // Fetch the new page data
						},
						current: page, // Track the current page
						showLessItems: true, // Optional: Keep pagination UI minimal
					}}
				/>
			</Content>
		</div>
	);
}
